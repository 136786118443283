import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import { ReactComponent as Point } from "../../../../assets/icons/point.svg";
import { ReactComponent as Star } from "../../../../assets/icons/star.svg";
import { useNavigate } from "react-router";
import clsx from "clsx";
import { numberWithCommas } from "../../../../utils/helpers";
import RemoveTeamModal from "./RemoveTeam";
import { LoadingSkeleton } from "../../../../components/LoadingSkeleton/LoadingSkeleton";
import card4 from "../../../../assets/images/guestHome/card4.png";

export const TeamCard = ({
  name,
  status,
  position,
  logo,
  id,
  atRisk,
  eliminated,
  isHome,
  faab,
  isMe,
  eliminatedWeek,
  leagueStatus,
  latestScore,
  user,
  isAdmin,
  leagueId,
  atOrangeZone,
  prjScore,
  isFetchingSecondaryScore,
  isPotentialFirst,
  isPotentialSecond,
}: {
  name?: string;
  status?: string;
  position?: string;
  score?: string;
  logo?: string;
  id: string;
  atRisk?: boolean;
  eliminated?: boolean;
  isHome?: boolean;
  faab?: number;
  isMe?: boolean;
  eliminatedWeek?: number;
  leagueStatus?: string;
  week?: number;
  teamScore?: any;
  latestScore?: any;
  user?: any;
  isAdmin?: boolean;
  leagueId?: string;
  atOrangeZone?: boolean;
  prjScore?: number;
  isFetchingSecondaryScore?: boolean;
  isPotentialFirst?: boolean;
  isPotentialSecond?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/leagues/team/${id}`)}
      className={clsx(
        "bg-modal-inputBg h-[80px] py-[19px] px-3 rounded-[8px] border-[1px] border-stroke w-[100%] flex justify-between items-center cursor-pointer lg:w-[49%] lg:px-6",
        atRisk && "border-text-red bg-red-bgDark",
        eliminated && "border-none bg-red-bgDark opacity-40",
        atOrangeZone && "!border-orange-1 bg-orange-6",
        isMe && "!border-green-1 !bg-green-4",
        isPotentialFirst && "!border-gold-1",
        isPotentialSecond && "!border-silver-1",
        isPotentialFirst &&
          status === "First Place" &&
          "!border-gold-1 !bg-gold-2",
        isPotentialSecond &&
          status === "Second Place" &&
          "!border-silver-1 !bg-silver-2"
      )}
    >
      <div className="flex items-center gap-3">
        <img
          src={logo || demo_team_logo}
          alt="CutThroat"
          className="w-[33px] h-[33px] rounded-[50px] md:w-[40px] md:h-[40px]"
        />
        <div className="flex flex-col gap-2 w-fit ">
          <div className="flex items-center">
            <p className="font-gilroyBold text-base mr-1">
              {name || "-"}{" "}
              <span className="text-text-subGrey">@{user?.username}</span>
            </p>
            {isMe && <Star className="mb-1" />}
          </div>
          <div className="flex items-center">
            <Point />
            <p className="font-gilroyMedium text-xs text-text-grey ml-[6px]">
              {(status === "Confirmed" ? "Active" : status) || "-"}{" "}
              {eliminatedWeek && status === "Eliminated"
                ? `Week ${eliminatedWeek}`
                : ""}
            </p>
            {status === "First Place" && (
              <img
                width={24}
                height={24}
                src={card4}
                alt="card img"
                className="ml-2"
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4  md:gap-8">
        <div className="flex flex-col gap-3 w-fit">
          <p className="font-gilroyBold text-base text-center">
            {isHome || leagueStatus !== "registration"
              ? `$${numberWithCommas(faab?.toFixed(2)!)}`
              : position !== null
              ? Number(position) + 1
              : "-"}
          </p>
          <p className="font-gilroyMedium text-text-grey text-center">
            {isHome || leagueStatus !== "registration"
              ? "FAAB"
              : "Draft Position"}
          </p>
        </div>
        <div className="flex flex-col gap-[2px] w-fit">
          <p className="font-gilroyBold text-base text-center">
            {latestScore ?? "-"}
          </p>
          {isFetchingSecondaryScore ? (
            <LoadingSkeleton
              baseColor="#202529"
              lineCount={1}
              lineHeight={15}
            />
          ) : (
            <p className="font-gilroyItalic text-sm text-center text-text-grey italic whitespace-nowrap">
              / {typeof prjScore === "number" ? prjScore?.toFixed(2) : "0"}
            </p>
          )}
          <p className="font-gilroyMedium text-text-grey text-center">Score</p>
        </div>
        {isAdmin && leagueStatus === "registration" && (
          <RemoveTeamModal leagueId={leagueId} userId={user?.id} />
        )}
      </div>
    </div>
  );
};

export const OpenSlot = () => {
  return (
    <div className="h-[80px] rounded-[8px] border-dashed border-[1px] border-stroke w-[49%] flex justify-center items-center">
      <p className="font-gilroyBold text-base text-center text-text-subGrey">
        Open Slot
      </p>
    </div>
  );
};
