import { create } from "zustand";
import { persist } from "zustand/middleware";

import { constructStore } from "../utils";

export type LoginData = {
  access_token: string;
};

type AuthState = {
  loginData: LoginData | null;
  userData: any;
  setLoginData: (loginData: LoginData) => void;
  setUserData: (userData: any) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  isLoggedIn: boolean;
  draftsTimers: any;
  setDraftTimers: (newDraftsTimers: any) => void;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (isMobileMenuOpen: boolean) => void;
  savedPlayers: any;
  setSavedPlayers: (savedPlayers: any) => void;
  allPlayers: any;
  setAllPlayers: (allPlayers: any) => void;
  allDraftPlayers: any;
  setAllDraftPlayers: (allPlayers: any) => void;
};

const authStore = create<AuthState>()(
  persist(
    (set) => ({
      loginData: null,
      userData: null,
      setLoginData: (loginData: LoginData) => {
        set({ loginData });
      },
      setUserData: (userData: any) => {
        set({ userData });
      },
      isLoggedIn: false,
      setIsLoggedIn: (isLoggedIn: boolean) => {
        set({ isLoggedIn });
      },
      isMobileMenuOpen: false,
      setIsMobileMenuOpen: (isMobileMenuOpen: boolean) => {
        set({ isMobileMenuOpen });
      },
      draftsTimers: {},
      setDraftTimers: (draftsTimers: any) => {
        set({ draftsTimers });
      },
      savedPlayers: [],
      setSavedPlayers: (savedPlayers: any) => {
        set({ savedPlayers });
      },
      allPlayers: { offset: 0, players: [] },
      setAllPlayers: (allPlayers: any) => {
        set({ allPlayers });
      },
      allDraftPlayers: { offset: 0, players: [] },
      setAllDraftPlayers: (allDraftPlayers: any) => {
        set({ allDraftPlayers });
      },
    }),
    {
      name: "auth-storage",
      partialize: (state) => ({
        loginData: state.loginData,
      }),
    }
  )
);

const useAuthStore = constructStore<AuthState>(authStore);

export default useAuthStore;
