import TableCardsListing from "../../../components/TableCardsListing/TableCardsListing";
import { AddNewLeagueModal } from "../Leagues/components/AddNewLeague";
import useLeaguesHook from "./hooks/useLeagues";
import Filters from "../../../components/Filters/Filters";
import MobileFilters from "../../../components/MobileFilters/MobileFilters.tsx";
import MobileLeagues from "../../../components/MobileLeagues/MobileLeagues.tsx";
import { Pagination } from "../../../components/Pagination/Pagination.tsx";

const Leagues = () => {
  const { columns, isFetching, filters, leagues, filter, onChangeFilter } =
    useLeaguesHook();

  return (
    <div className="pb-6 px-4 pb-6 md:px-10">
      <div className="w-full flex justify-end gap-4 hidden lg:flex">
        <AddNewLeagueModal />
      </div>
      <div className="mt-6 hidden lg:block">
        <div className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
          <p className="font-gilroyBold mr-5">Active Leagues</p>
          <div className="flex flex-wrap gap-[30px] items-center lg:gap-6">
            {filters?.map((item) => (
              <Filters
                key={item?.value}
                name={item?.name}
                value={item?.value}
                filters={item?.filters}
              />
            ))}
          </div>
        </div>
        <TableCardsListing
          columns={columns}
          dataSource={leagues}
          loading={isFetching}
          paginationData={{
            page: filter?.page as number,
            limit: filter?.limit as number,
            count: leagues?.length ?? 0,
            onChange: (params) => {
              onChangeFilter(params);
            },
          }}
        />
      </div>
      <div className="flex gap-4 flex-col lg:hidden">
        <div className="w-full flex flex-col gap-4">
          <div className="w-full flex justify-end gap-4">
            <AddNewLeagueModal />
          </div>
        </div>
        <p className="font-gilroyBold mt-8 mb-3">All Leagues</p>

        <MobileFilters page="leagues" filters={filters} />
        <MobileLeagues leagues={leagues} />
        <Pagination
          onChange={(params) => {
            onChangeFilter(params);
          }}
          page={filter?.page as number}
          limit={filter?.limit as number}
          count={leagues?.length ?? 0}
        />
      </div>
    </div>
  );
};

export default Leagues;
