import { Dispatch, SetStateAction, createContext } from "react";
interface AppContextType {
  timers: any;
  completedDrafts: any;
  activeDrafts: any;
  activePicks: any;
  setActivePicks: Dispatch<SetStateAction<any>>;
}

const appContext = createContext<AppContextType>({
  timers: {},
  completedDrafts: {},
  activeDrafts: {},
  activePicks: {},
  setActivePicks: () => {},
});

export default appContext;
