import { WheelEvent, forwardRef } from "react";

import clsx from "clsx";

import { InputPropsType } from "./type";

export const Input = forwardRef<HTMLInputElement, InputPropsType>(
  (props, ref) => {
    const {
      placeholder = "Type value...",
      inputClassName = "",
      containerClassName = "",
      error,
      disabled,
      leftIcon,
      rightIcon,
      ...rest
    } = props;

    const disableWheelBlur = (e: WheelEvent<HTMLInputElement>) => {
      if (e.currentTarget.type === "number") {
        e.currentTarget.blur();
      }
    };

    return (
      <div
        className={clsx(
          "flex items-center justify-between gap-2 h-[52px]",
          "bg-card-bgGreyMedium text-text-white text-base text-left w-full block py-[11px] px-[20px]",
          "rounded-[25px] border-2 border-card-strokeGradient-1",
          "focus-within:bg-card-bgGreyLight",
          error &&
            "!border-red-bgGradient2-1 bg-red-bgDark focus-within:bg-red-bgDark",
          containerClassName
        )}
      >
        {leftIcon ? (
          typeof leftIcon === "string" ? (
            <img src={leftIcon} alt="icon" className={clsx("w-6 h-6")} />
          ) : (
            leftIcon
          )
        ) : null}
        <input
          ref={ref}
          placeholder={placeholder}
          onWheel={disableWheelBlur}
          className={clsx(
            "bg-inherit w-full h-full grow",
            "appearance-none focus:outline-none focus:shadow-outline",
            "disabled:cursor-not-allowed",
            "placeholder-text-subGrey placeholder:text-left flex-1 bg-transparent",
            inputClassName
          )}
          disabled={disabled}
          {...rest}
        />
        {rightIcon ? (
          typeof rightIcon === "string" ? (
            <img src={rightIcon} alt="icon" className={clsx("w-6 h-6")} />
          ) : (
            rightIcon
          )
        ) : null}
      </div>
    );
  }
);
