import useAuthStore from "../../../stores/auth/auth";
import axios from "axios";

export const NotificationsServices = {
  getUserNotifications: async (userId: string, params: any) => {
    const endPoint = `/api/notification/${userId}`;
    try {
      const notifications = await axios.get(endPoint, { params });
      return notifications?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  getAdminNotifications: async (params: any) => {
    const endPoint = `/api/notification/all/admin`;
    try {
      const notifications = await axios.get(endPoint, { params });
      return notifications?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  readNotification: async (payload: any) => {
    const endPoint = `/api/notification`;
    try {
      const notification = await axios.patch(endPoint, payload);
      return notification?.data;
    } catch (error) {
      throw error;
    }
  },
  readAllNotification: async (userId: string) => {
    const endPoint = `/api/notification/read/${userId}`;
    try {
      const notifications = await axios.patch(endPoint);
      return notifications?.data;
    } catch (error) {
      throw error;
    }
  },
  removeWithdrawalNotification: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `api/notification`;
    try {
      const notification = await axios.delete(endPoint, {
        data: payload,
        headers: config.headers,
      });
      return notification?.data;
    } catch (error) {
      throw error;
    }
  },
};
