import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { ReactComponent as Back } from "../../../assets/icons/back.svg";
import forgotPassword_bg from "../../../assets/images/forgotPassword_bg.png";
import { Controller } from "react-hook-form";
import { Button } from "../../../components/Button/Button";
import { containsNumber, containsUppercase } from "../../../utils/helpers";
import useResetPassword from "./hooks/useResetPassword";
import LabeledPasswordInput from "../../../components/LabeledPasswordInput/LabeledPasswordInput";
import { useLocation } from "react-router-dom";

const ResetPassword = () => {
  const token = new URLSearchParams(useLocation()?.search)?.get("token");
  const { newPassword, control, errors, onSubmit, navigate } = useResetPassword(
    token!
  );

  return (
    <div className="flex flex-row p-0 h-svh md:p-2">
      <div className="p-10 w-full flex flex-col bg-auth-mobile bg-cover bg-center md:bg-none">
        <div className="p-10 w-full flex flex-col justify-center items-center">
          <div
            onClick={() => navigate("/login")}
            className="absolute left-0 flex flex-row justify-center p-10 items-center cursor-pointer md:p-0"
          >
            <Back />
            <p className="text-text-white ml-2 text-base hidden font-gilroyMedium md:block">
              Back to Login
            </p>
          </div>
          <Logo />
        </div>
        <div className="mt-[64px] h-full flex flex-col items-center">
          <h1 className="text-text-white font-absoluteEmpire text-[40px] text-center">
            CREATE NEW PASSWORD
          </h1>
          <div className="w-full max-w-[397px]">
            <div>
              <Controller
                control={control}
                name="newPassword"
                render={({ field }) => (
                  <LabeledPasswordInput
                    {...field}
                    placeholder="Enter your password"
                    error={errors?.newPassword?.message}
                    errorClassName="text-text-red"
                    label="Your password"
                    containerClassName="mt-6"
                    style={{ caretColor: "red" }}
                  />
                )}
                rules={{
                  validate: {
                    value: (value) => {
                      if (
                        value?.length < 8 ||
                        !containsUppercase(value) ||
                        !containsNumber(value)
                      ) {
                        return `<div><p>The password must meet following requirements:</p>
                            ${
                              value?.length < 8
                                ? `<div className="flex flex-row items-center mt-2">
                            <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                         <p>Minimum 8 characters</p></div>`
                                : ""
                            }
                         ${
                           !containsNumber(value)
                             ? `<div className="flex flex-row items-center mt-2">
                            <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                         <p>At least one number</p></div>`
                             : ""
                         }
                         ${
                           !containsUppercase(value)
                             ? `<div className="flex flex-row items-center mt-2">
                            <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                         <p>One uppercase letter</p></div>`
                             : ""
                         }
                         </div>`;
                      }
                      return true;
                    },
                  },
                }}
              />
              <Controller
                control={control}
                name="confirmPassword"
                render={({ field }) => (
                  <LabeledPasswordInput
                    {...field}
                    placeholder="Confirm your password"
                    error={errors?.confirmPassword?.message}
                    errorClassName="text-text-red"
                    label="Confirm your password"
                    containerClassName="mt-6"
                    style={{ caretColor: "red" }}
                  />
                )}
                rules={{
                  validate: {
                    value: (value) => {
                      if (newPassword !== value) {
                        return "<p>Passwords do not match</p>";
                      }
                      return true;
                    },
                  },
                }}
              />
              <Button onClick={onSubmit} className="w-full text-base mt-[32px]">
                Change Password
              </Button>
            </div>
          </div>
        </div>
      </div>
      <img
        src={forgotPassword_bg}
        alt="CutThroat"
        className="w-[50%] hidden sm:block"
      />
    </div>
  );
};

export default ResetPassword;
