import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { ADMIN_QUERY_KEY } from "./adminQueriesKeys";
import { AdminServices } from "../../services/apis/Admin/AdminServices";
import { TEAMS_QUERY_KEY } from "../../queries/teamsQueries/teamsQueriesKeys";

export const useFetchAllUsers = (isTransactions?: boolean, params?: any) => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_ALL_USERS, params],
    queryFn: () => AdminServices.getAllUsers(isTransactions, params),
    refetchOnWindowFocus: false,
    initialData: {
      message: [],
    },
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useUserById = (id: string) => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_USER, id],
    queryFn: () => AdminServices.getUserByID(id),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useAddCredits = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.addCredits,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_USER],
      });
    },
  });
};

export const useBanUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.banUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_USER],
      });
    },
  });
};

export const useUnbanUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.unbanUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_USER],
      });
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_USER],
      });
    },
  });
};

export const useAddBalance = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.addBalance,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_USER],
      });
    },
  });
};

export const useFetchUsersCount = () => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_USERS_COUNT],
    queryFn: () => AdminServices.getUsersCount(),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useFetchStats = () => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_STATS],
    queryFn: () => AdminServices.getStats(),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useFetchLeaguesCount = () => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_LEAGUES_COUNT],
    queryFn: () => AdminServices.getLeaguesCount(),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useFetchLatestTransactions = (params: any) => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_TRANSACTIONS, params],
    queryFn: () => AdminServices.getLatestTransactions(params),
    refetchOnWindowFocus: false,
    initialData: [],
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useFetchTransactionsCount = () => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_TRANSACTIONS_COUNT],
    queryFn: () => AdminServices.getTransactionsCount(),
    refetchOnWindowFocus: false,
    initialData: {},
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_ALL_USERS],
      });
    },
  });
};

export const useInviteUsers = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.inviteUsers,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [],
      });
    },
  });
};
export const useArchiveLeague = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.archiveLeague,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [],
      });
    },
  });
};

export const useFetchFAQ = () => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_FAQ],
    queryFn: () => AdminServices.getFAQ(),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useAddFAQSection = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.addFAQSection,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_FAQ],
      });
    },
  });
};
export const useAddFAQQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.addFAQQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_FAQ],
      });
    },
  });
};
export const useChangeFAQPriority = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.changeFAQPriority,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_FAQ],
      });
    },
  });
};
export const useEditFAQ = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.editFAQ,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_FAQ],
      });
    },
  });
};
export const useRemoveFAQSection = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.deleteFAQSection,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_FAQ],
      });
    },
  });
};
export const useRemoveFAQQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.deleteFAQQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_FAQ],
      });
    },
  });
};
export const useChangeFAQQuestionPriority = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.changeFAQQuestionPriority,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_FAQ],
      });
    },
  });
};
export const useFetchPrivacyPolicy = () => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_PRIVACY_POLICY],
    queryFn: () => AdminServices.getPrivacyPolicy(),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useAddPrivacyPolicy = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.addPrivacyPolicy,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_PRIVACY_POLICY],
      });
    },
  });
};
export const useChangePrivacyPriority = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.changePrivacyPriority,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_PRIVACY_POLICY],
      });
    },
  });
};
export const useEditPrivacyPolicy = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.editPrivacyPolicy,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_PRIVACY_POLICY],
      });
    },
  });
};
export const useRemovePrivacyPolicy = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.deletePrivacyPolicy,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_PRIVACY_POLICY],
      });
    },
  });
};
export const useFetchTerms = () => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_TERMS],
    queryFn: () => AdminServices.getTermsAndConditions(),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useAddTerms = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.addTermsAndConditions,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_TERMS],
      });
    },
  });
};
export const useAddTermsTitle = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.addTermsAndConditionsTitle,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_TERMS],
      });
    },
  });
};
export const useChangeTerms = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.changeTermsAndConditions,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_TERMS],
      });
    },
  });
};
export const useEditTerms = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.editTermsAndConditions,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_TERMS],
      });
    },
  });
};
export const useRemoveTerms = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.deleteTermsAndConditions,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_TERMS],
      });
    },
  });
};
export const useRemoveTermsTitle = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.deleteTermsAndConditionsTitle,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_TERMS],
      });
    },
  });
};
export const useRemoveUserFromLeague = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.deleteUserFromLeague,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [],
      });
    },
  });
};
export const useUpdateUserPassword = () => {
  return useMutation({
    mutationFn: AdminServices.updateUserPassword,
  });
};
export const useRemovePlayerFromTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.removePLayerFromTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TEAMS_QUERY_KEY.FETCH_TEAM_DETAILS],
      });
    },
  });
};
export const useAddPlayerByAdmin = () => {
  return useMutation({
    mutationFn: AdminServices.addPlayerToTeam,
  });
};
export const useUpdateTeamBalance = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.updateTeamBalance,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TEAMS_QUERY_KEY.FETCH_TEAM_DETAILS],
      });
    },
  });
};
