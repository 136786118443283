import { useSearchParams } from "react-router-dom";

import useDebounce from "./useDebounce";

type Props = {
  filterKeys?: {
    key: string;
    type: "array" | "string" | "number";
    initialValue: any;
  }[];
  initialFilter?: any;
};

const getValue = (value: string | null, type: "string" | "number") => {
  return type === "number" ? (value ? +value : value ?? 0) : value;
};

const getAllFiltersFromParams = (
  filterKeys: Props["filterKeys"] = [],
  searchParams: URLSearchParams
) => {
  const arr = [
    ...filterKeys,
    { key: "keywords", type: "string" },
    ,
    { key: "status", type: "string", initialValue: "All" },
    { key: "numberOfTeams", type: "string", initialValue: "All" },
    { key: "receivingOption", type: "string", initialValue: "All" },
    { key: "draftDateTime", type: "string", initialValue: "All" },
    { key: "entryFee", type: "string", initialValue: "All" },
    { key: "entryFeeUsers", type: "string", initialValue: "All" },
    { key: "registeredTeams", type: "string", initialValue: "All" },
    { key: "limit", type: "number", initialValue: 10 },
    { key: "page", type: "number", initialValue: 1 },
    { key: "createdAtSort", type: "string", initialValue: "All" },
    { key: "type", type: "string", initialValue: "All" },
  ] as Props["filterKeys"];

  return arr?.reduce((acc: any, val) => {
    if (val?.key) {
      acc[val?.key] =
        val?.type === "array"
          ? searchParams?.getAll(val?.key)
          : getValue(
              searchParams?.get(val?.key) ?? val?.initialValue ?? "",
              val?.type
            );
    }
    return acc;
  }, {});
};

const useFilter = ({
  filterKeys,
  initialFilter = {
    status: "All",
    numberOfTeams: "All",
    receivingOption: "All",
    draftDateTime: "All",
    entryFee: "All",
    entryFeeUsers: "All",
    registeredTeams: "All",
    createdAtSort: "All",
    type: "All",
    page: 1,
    limit: 10,
  },
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams(initialFilter);
  const debouncedSearchValue = useDebounce(
    searchParams.get("keywords") as string,
    500
  );
  const statusValue = searchParams.get("status");
  const numberOfTeamsValue = searchParams.get("numberOfTeams");
  const receivingOptionValue = searchParams.get("receivingOption");
  const draftDateTimeValue = searchParams.get("draftDateTime");
  const entryFeeValue = searchParams.get("entryFee");
  const entryFeeUsersValue = searchParams.get("entryFeeUsers");
  const registeredTeamsValue = searchParams.get("registeredTeams");
  const createdAtSortValue = searchParams.get("createdAtSort");
  const typeValue = searchParams.get("type");
  const onChangeFilter = (newFilter: any = {}) => {
    const finalFilter = newFilter.hasOwnProperty("limit")
      ? newFilter
      : {
          ...newFilter,
        };
    const finalObj = {
      ...Object.fromEntries([...searchParams.entries()]),
      ...finalFilter,
    };
    setSearchParams(finalObj);
  };

  return {
    stringSearchFilter: searchParams?.toString(),
    filter: {
      ...initialFilter,
      ...getAllFiltersFromParams(filterKeys, searchParams),
      keywords: debouncedSearchValue,
      status: statusValue,
      numberOfTeams: numberOfTeamsValue,
      receivingOption: receivingOptionValue,
      draftDateTime: draftDateTimeValue,
      entryFee: entryFeeValue,
      entryFeeUsers: entryFeeUsersValue,
      registeredTeams: registeredTeamsValue,
      createdAtSort: createdAtSortValue,
      type: typeValue,
    } as { [key: string]: number | string | string[] },
    optimisticFilter: getAllFiltersFromParams(filterKeys, searchParams),
    onChangeFilter,
  };
};

export default useFilter;
