import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { PAYMENTS_QUERY_KEY } from "./paymentsQueriesKeys";
import { PaymentsServices } from "../../services/apis/Payments/PaymentsServices";

export const useFetchUserTransactions = (id: string, params?: any) => {
  const queryResult = useQuery<any>({
    queryKey: [PAYMENTS_QUERY_KEY.FETCH_TRANSACTIONS, params],
    queryFn: () => PaymentsServices.getUserTransactions(id, params),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useSendWithdrawalRequest = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: PaymentsServices.sendWithdrawalRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PAYMENTS_QUERY_KEY.FETCH_TRANSACTIONS],
      });
    },
  });
};
