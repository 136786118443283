import { useFetchCurrentWeek } from "../../../../queries/leaguesQueries/leaguesQueries";
import {
  useFetchPlayer,
  useFetchPlayerWeeklyStats,
} from "../../../../queries/playerQueries/playerQueries";
import clsx from "clsx";

import { useEffect, useState } from "react";

const WKRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.week ?? "-"}</p>
    </div>
  );
};
const PROJRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>
        {data?.projectedFantasyPointsThisWeek?.toFixed(2) ?? "-"}
        {/*{!data?.isRealStats && !data?.isPastWeek && !data?.isCurrentWeek*/}
        {/*  ? data?.isPPR*/}
        {/*    ? updatePlayerScore(data?.projectedStats)?.toFixed(2)*/}
        {/*    : updatePlayerScoreNonPpr(data?.projectedStats)?.toFixed(2)*/}
        {/*  : data?.playerData?.projectedPoints ?? "-"}*/}
      </p>
    </div>
  );
};
const FPTSRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>
        {data?.fantasyPointsThisWeek?.toFixed(2) ?? "-"}
        {/*{data?.isRealStats && data?.isPastWeek*/}
        {/*  ? data?.isPPR*/}
        {/*    ? updatePlayerScore(data?.projectedStats)?.toFixed(2)*/}
        {/*    : updatePlayerScoreNonPpr(data?.projectedStats)?.toFixed(2)*/}
        {/*  : data?.playerData?.score ?? "-"}*/}
      </p>
    </div>
  );
};
const OPPRender = ({ ...data }) => {
  const opponent =
    data?.game?.awayTeamAbbreviation === data?.team?.abbreviation
      ? data?.game?.homeTeamAbbreviation
      : data?.game?.awayTeamAbbreviation;
  const isHomeGame =
    data?.game?.homeTeamAbbreviation &&
    data?.game?.homeTeamAbbreviation !== data?.team?.abbreviation
      ? "@"
      : "";
  return (
    <div className="flex justify-between">
      <p>
        {isHomeGame}
        {opponent || data?.playerData?.opponentTeam || "-"}
      </p>
    </div>
  );
};
const PassATTRender = ({ ...data }) => {
  return (
    <div
      className={clsx(
        "flex justify-between",
        !data?.stats && "!text-text-greyDark"
      )}
    >
      <p>
        {data?.stats
          ? data?.stats?.passing?.passCompletions
          : data?.projectedStats
          ? data?.projectedStats?.passing?.passCompletions
          : "-"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.playerData?.passingCompletions ?? "-"*/}
        {/*  : data?.projectedStats?.passing?.passCompletions ?? "-"}*/}/
        {data?.stats
          ? data?.stats?.passing?.passAttempts
          : data?.projectedStats
          ? data?.projectedStats?.passing?.passAttempts
          : "-"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.playerData?.passingAttempts ?? "-"*/}
        {/*  : data?.projectedStats?.passing?.passAttempts ?? "-"}*/}
      </p>
    </div>
  );
};
const PassYDsRender = ({ ...data }) => {
  return (
    <div
      className={clsx(
        "flex justify-between",
        !data?.stats && "!text-text-greyDark"
      )}
    >
      <p>
        {data?.stats
          ? data?.stats?.passing?.passYards
          : data?.projectedStats
          ? data?.projectedStats?.passing?.passYards
          : "-"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.playerData?.passingYards ?? "-"*/}
        {/*  : data?.projectedStats?.passing?.passYards ?? "-"}*/}
      </p>
    </div>
  );
};
const PassTDsRender = ({ ...data }) => {
  return (
    <div
      className={clsx(
        "flex justify-between",
        !data?.stats && "!text-text-greyDark"
      )}
    >
      <p>
        {data?.stats
          ? data?.stats?.passing?.passTD
          : data?.projectedStats
          ? data?.projectedStats?.passing?.passTD
          : "-"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.playerData?.passingTds ?? "-"*/}
        {/*  : data?.projectedStats?.passing?.passTD ?? "-"}*/}
      </p>
    </div>
  );
};
const INTRender = ({ ...data }) => {
  return (
    <div
      className={clsx(
        "flex justify-between",
        !data?.stats && "!text-text-greyDark"
      )}
    >
      <p>
        {data?.stats
          ? data?.stats?.passing?.passInt
          : data?.projectedStats
          ? data?.projectedStats?.passing?.passInt
          : "-"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.playerData?.passingInterceptions ?? "-"*/}
        {/*  : data?.projectedStats?.passing?.passInt ?? "-"}*/}
      </p>
    </div>
  );
};

const RushATTRender = ({ ...data }) => {
  return (
    <div
      className={clsx(
        "flex justify-between",
        !data?.stats && "!text-text-greyDark"
      )}
    >
      <p>
        {data?.stats
          ? data?.stats?.rushing?.rushAttempts
          : data?.projectedStats
          ? data?.projectedStats?.rushing?.rushAttempts
          : "-"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.playerData?.rushingAttempts ?? "-"*/}
        {/*  : data?.projectedStats?.rushing?.rushAttempts ?? "-"}*/}
      </p>
    </div>
  );
};
const RushYDRender = ({ ...data }) => {
  return (
    <div
      className={clsx(
        "flex justify-between",
        !data?.stats && "!text-text-greyDark"
      )}
    >
      <p>
        {data?.stats
          ? data?.stats?.rushing?.rushYards
          : data?.projectedStats
          ? data?.projectedStats?.rushing?.rushYards
          : "-"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.playerData?.rushing?.rushYards ?? "-"*/}
        {/*  : data?.projectedStats?.rushing?.rushYards ?? "-"}*/}
      </p>
    </div>
  );
};
const RushTDRender = ({ ...data }) => {
  return (
    <div
      className={clsx(
        "flex justify-between",
        !data?.stats && "!text-text-greyDark"
      )}
    >
      <p>
        {data?.stats
          ? data?.stats?.rushing?.rushTD
          : data?.projectedStats
          ? data?.projectedStats?.rushing?.rushTD
          : "-"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.playerData?.rushingTds ?? "-"*/}
        {/*  : data?.projectedStats?.rushing?.rushTD ?? "-"}*/}
      </p>
    </div>
  );
};
const RecRender = ({ ...data }) => {
  return (
    <div
      className={clsx(
        "flex justify-between",
        !data?.stats && "!text-text-greyDark"
      )}
    >
      <p>
        {data?.stats
          ? data?.stats?.receiving?.receptions
          : data?.projectedStats
          ? data?.projectedStats?.receiving?.receptions
          : "-"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.playerData?.receivingReceptions ?? "-"*/}
        {/*  : data?.projectedStats?.receiving?.receptions ?? "-"}*/}
      </p>
    </div>
  );
};
const RecYDSRender = ({ ...data }) => {
  return (
    <div
      className={clsx(
        "flex justify-between",
        !data?.stats && "!text-text-greyDark"
      )}
    >
      <p>
        {data?.stats
          ? data?.stats?.receiving?.recYards
          : data?.projectedStats
          ? data?.projectedStats?.receiving?.recYards
          : "-"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.playerData?.receivingYards ?? "-"*/}
        {/*  : data?.projectedStats?.receiving?.recYards ?? "-"}*/}
      </p>
    </div>
  );
};
const RecTDRender = ({ ...data }) => {
  return (
    <div
      className={clsx(
        "flex justify-between",
        !data?.stats && "!text-text-greyDark"
      )}
    >
      <p>
        {data?.stats
          ? data?.stats?.receiving?.recTD
          : data?.projectedStats
          ? data?.projectedStats?.receiving?.recTD
          : "-"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.playerData?.receivingTds ?? "-"*/}
        {/*  : data?.projectedStats?.receiving?.recTD ?? "-"}*/}
      </p>
    </div>
  );
};

const usePlayerDetailsHook = ({
  id,
  leagueId,
}: {
  id: string;
  leagueId: string;
}) => {
  const { data, isFetching } = useFetchPlayer(id, leagueId);
  const [activeTab, setActiveTab] = useState(1);
  const { data: currentWeek } = useFetchCurrentWeek();
  const [week, setWeek] = useState<number>(1);
  const [allData, setAllData] = useState<any>([]);

  const isCurrentWeek = !week || week === currentWeek;
  const isPastWeek = week < currentWeek;

  const {
    data: weeklyStats,
    isFetching: isFetchingStats,
    isError,
    isSuccess,
  } = useFetchPlayerWeeklyStats(
    {
      firstName: data?.player?.firstName,
      lastName: data?.player?.lastName,
      week: week ? week : 1,
    },
    week > currentWeek
  );

  useEffect(() => {
    if (isSuccess && !isFetchingStats) {
      const stats = weeklyStats ? weeklyStats : {};
      setAllData([...allData, { ...stats, week: week }]);
      if (week < 18) {
        setWeek(week + 1);
      }
    }
  }, [weeklyStats, week, isSuccess, isFetchingStats]);

  const columnsFantasy = [
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">WK</p>,
      dataIndex: "wk",
      render: (_: string, record: any) => {
        return <WKRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">PROJ</p>,
      dataIndex: "proj",
      render: (_: string, record: any) => {
        return (
          <PROJRender
            {...record}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
          />
        );
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">FPTS</p>,
      dataIndex: "fpts",
      render: (_: string, record: any) => {
        return (
          <FPTSRender
            {...record}
            isRealStats={week <= currentWeek}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
          />
        );
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">OPP</p>,
      dataIndex: "opp",
      render: (_: string, record: any) => {
        return <OPPRender {...record} />;
      },
    },
  ];

  const columnsPass = [
    {
      title: (
        <p className="text-xs font-gilroyMedium text-text-grey">CMP/ATT</p>
      ),
      dataIndex: "att",
      render: (_: string, record: any) => {
        return (
          <PassATTRender
            {...record}
            isCurrentWeek={isCurrentWeek}
            isProjected={!isPastWeek && !isCurrentWeek}
          />
        );
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "yd",
      render: (_: string, record: any) => {
        return (
          <PassYDsRender
            {...record}
            isCurrentWeek={isCurrentWeek}
            isProjected={!isPastWeek && !isCurrentWeek}
          />
        );
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">TDs</p>,
      dataIndex: "td",
      render: (_: string, record: any) => {
        return (
          <PassTDsRender
            {...record}
            isCurrentWeek={isCurrentWeek}
            isProjected={!isPastWeek && !isCurrentWeek}
          />
        );
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">INT</p>,
      dataIndex: "int",
      render: (_: string, record: any) => {
        return (
          <INTRender
            {...record}
            isCurrentWeek={isCurrentWeek}
            isProjected={!isPastWeek && !isCurrentWeek}
          />
        );
      },
    },
  ];

  const columnsRushing = [
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">CAR</p>,
      dataIndex: "attR",
      render: (_: string, record: any) => {
        return (
          <RushATTRender
            {...record}
            isCurrentWeek={isCurrentWeek}
            isProjected={!isPastWeek && !isCurrentWeek}
          />
        );
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "ydR",
      render: (_: string, record: any) => {
        return (
          <RushYDRender
            {...record}
            isCurrentWeek={isCurrentWeek}
            isProjected={!isPastWeek && !isCurrentWeek}
          />
        );
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">TDs</p>,
      dataIndex: "tdR",
      render: (_: string, record: any) => {
        return (
          <RushTDRender
            {...record}
            isCurrentWeek={isCurrentWeek}
            isProjected={!isPastWeek && !isCurrentWeek}
          />
        );
      },
    },
  ];

  const columnsReceiving = [
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">Rec</p>,
      dataIndex: "rec",
      render: (_: string, record: any) => {
        return (
          <RecRender
            {...record}
            isCurrentWeek={isCurrentWeek}
            isProjected={!isPastWeek && !isCurrentWeek}
          />
        );
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "recYDS",
      render: (_: string, record: any) => {
        return (
          <RecYDSRender
            {...record}
            isCurrentWeek={isCurrentWeek}
            isProjected={!isPastWeek && !isCurrentWeek}
          />
        );
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">TDs</p>,
      dataIndex: "recTD",
      render: (_: string, record: any) => {
        return (
          <RecTDRender
            {...record}
            isCurrentWeek={isCurrentWeek}
            isProjected={!isPastWeek && !isCurrentWeek}
          />
        );
      },
    },
  ];

  return {
    data,
    columnsFantasy,
    isFetching,
    columnsPass,
    columnsRushing,
    activeTab,
    setActiveTab,
    columnsReceiving,
    week,
    isFetchingStats,
    isError,
    allData,
  };
};

export default usePlayerDetailsHook;
