import { useEffect, useState } from "react";
import { AppRouter } from "./AppRouter";
import { AuthSessionServices } from "./services/AuthSession";

const AuthLoading = () => {
  const [authLoading, setAuthLoading] = useState(true);
  useEffect(() => {
    setAuthLoading(true);
    const loadAuthData = async () => {
      await AuthSessionServices.createSession();
    };

    Promise.allSettled([loadAuthData()])
      .then(() => setAuthLoading(false))
      .catch(() => {})
      .finally(() => setAuthLoading(false));
  }, []);
  return <>{!authLoading && <AppRouter />}</>;
};

export default AuthLoading;
