export const LEAGUES_QUERY_KEY = {
  FETCH_LEAGUES_LIST: "FETCH_LEAGUES_LIST",
  FETCH_MY_LEAGUES_LIST: "FETCH_MY_LEAGUES_LIST",
  FETCH_LEAGUE: "FETCH_LEAGUE",
  FETCH_LEAGUE_CURRENT_WEEK: "FETCH_LEAGUE_CURRENT_WEEK",
  UPDATE_LEAGUE: "UPDATE_LEAGUE",
  FETCH_ELIMINATION_DATE: "FETCH_ELIMINATION_DATE",
  FETCH_CURRENT_WEEK: "FETCH_CURRENT_WEEK",
  FETCH_ALL_GAMES: "FETCH_ALL_GAMES",
  FETCH_ALL_SECONDARY_SCORES: "FETCH_ALL_SECONDARY_SCORES",
};
