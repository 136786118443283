import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import clsx from "clsx";

import { LoadingSkeletonProps } from "./type";

export const LoadingSkeleton = (props: LoadingSkeletonProps) => {
  const {
    lineCount = 4,
    lineHeight = 30,
    baseColor = "#13181b",
    highlightColor = "#444",
    className = "",
  } = props;

  return (
    <SkeletonTheme
      height={lineHeight}
      baseColor={baseColor}
      highlightColor={highlightColor}
    >
      <p className={clsx("w-full h-full", className)}>
        <Skeleton count={lineCount} />
      </p>
    </SkeletonTheme>
  );
};
