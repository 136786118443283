import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { NOTIFICATIONS_QUERY_KEY } from "./notificationsQueriesKeys";
import { NotificationsServices } from "../../services/apis/Notifications/NotificationsServices";

export const useFetchUserNotifications = (
  userId: string,
  params: any,
  isAdmin: boolean
) => {
  const queryResult = useQuery<any>({
    queryKey: [NOTIFICATIONS_QUERY_KEY.FETCH_USER_NOTIFICATIONS],
    queryFn: () =>
      isAdmin
        ? NotificationsServices.getAdminNotifications(params)
        : NotificationsServices.getUserNotifications(userId, params),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!userId,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useCheckIndicator = (
  userId: string,
  params: any,
  isAdmin: boolean
) => {
  const queryResult = useQuery<any>({
    queryKey: [NOTIFICATIONS_QUERY_KEY.CHECK_INDICATOR],
    queryFn: () =>
      isAdmin
        ? NotificationsServices.getAdminNotifications(params)
        : NotificationsServices.getUserNotifications(userId, params),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!userId,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useRemoveNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: NotificationsServices.removeWithdrawalNotification,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTIFICATIONS_QUERY_KEY.FETCH_USER_NOTIFICATIONS],
      });
    },
  });
};
