import useAuthStore from "../../../stores/auth/auth";
import axios from "axios";

export const UserServices = {
  fetchUser: async () => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/user`;
    try {
      const config = {
        headers: { Authorization: `Bearer ${loginData?.access_token}` },
      };
      const user = await axios.get(endPoint, config);
      return user?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  getUserDetails: async (userId: string) => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/user/${userId}`;
    try {
      const config = {
        headers: { Authorization: `Bearer ${loginData?.access_token}` },
      };
      const user = await axios.get(endPoint, config);
      return user?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  updateUser: async (payload: any) => {
    const { loginData, setUserData } = useAuthStore.getState();
    const endPoint = `/api/user/update/${payload?.userId}`;
    try {
      const config = {
        headers: { Authorization: `Bearer ${loginData?.access_token}` },
      };
      delete payload?.userId;
      const updateUser = await axios.post(endPoint, payload, config);
      setUserData(updateUser?.data?.data);
      return updateUser?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  updateNotifications: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/user/notification`;
    try {
      const config = {
        headers: { Authorization: `Bearer ${loginData?.access_token}` },
      };
      const updateNotifications = await axios.post(endPoint, payload, config);
      return updateNotifications?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  updateUserPhoto: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/user/photo`;
    try {
      const config = {
        headers: { Authorization: `Bearer ${loginData?.access_token}` },
      };
      const userPhoto = await axios.post(endPoint, payload, config);
      return userPhoto?.data;
    } catch (error) {
      throw error;
    }
  },
};
