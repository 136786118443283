import React from "react";
import { PaginationPropsType } from "./type";

const arrowsClassName =
  "text-base font-gilroyMedium disabled:opacity-50 disabled:cursor-not-allowed";

export const Pagination: React.FC<PaginationPropsType> = React.memo(
  ({ onChange, count, limit, page, className = "", loading }) => {
    return (
      <div className={className}>
        <div className="flex items-center justify-end">
          <div className="flex gap-4 items-center">
            <div>
              <button
                disabled={loading || (page as number) < 2}
                type="button"
                onClick={() => {
                  onChange({
                    limit: limit as number,
                    page: (page as number) - 1,
                  });
                }}
                className={arrowsClassName}
              >
                Previous
              </button>
            </div>
            <div>
              <div className="flex justify-center items-center text-text-white text-[20px] text-gilroyBold w-[40px] h-[38px] bg-gradient-to-br from-red-accent-1 to-red-accent-2 rounded-[4px]">{`${page}`}</div>
            </div>
            <div>
              <button
                disabled={loading || count < limit}
                type="button"
                onClick={() => {
                  onChange({
                    limit: limit as number,
                    page: (page as number) + 1,
                  });
                }}
                className={arrowsClassName}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
