import clsx from "clsx";

import { LabelPropsType } from "./type";

export const Label = (props: LabelPropsType) => {
  const { children, className = "", htmlFor = "", capitalize = true } = props;
  return (
    <label
      htmlFor={htmlFor}
      className={clsx(
        `text-xs text-left text-text-white font-gilroyMedium ${
          capitalize ? "capitalize" : ""
        }`,
        className
      )}
    >
      {children ?? ""}
    </label>
  );
};
