import { Button } from "../../../components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "../../../assets/icons/pencil-icon.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus-white-icon.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import usePrivacyPolicyHook from "./hooks/usePrivacyPolicy";
import EditComponent from "./components/EditComponent";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const {
    isEdit,
    setIsEdit,
    isAdmin,
    privacyPolicy,
    isNewBlock,
    setIsNewBlock,
  } = usePrivacyPolicyHook();

  return (
    <div className="px-5 py-5 md:px-20 md:py-10">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h1
          className="text-4xl font-gilriyBold text-text-red cursor-pointer"
          onClick={() => navigate("/")}
        >
          CutThroat Football
        </h1>
        <div className="flex justify-between items-center gap-4">
          {isEdit && (
            <Button
              leftIcon={<Plus />}
              onClick={() => setIsNewBlock(!isNewBlock)}
              mode={"secondary"}
              className="!w-[160px] rounded-[8px]"
            >
              {isNewBlock ? "Hide" : "Add"} new Block
            </Button>
          )}
          {isAdmin && (
            <Button
              leftIcon={isEdit ? <Check /> : <Edit />}
              onClick={() => setIsEdit(!isEdit)}
              mode={isEdit ? "primary" : "secondary"}
              className="w-[90px] rounded-[8px]"
            >
              {isEdit ? "Save" : "Edit"}
            </Button>
          )}
        </div>
      </div>

      <p className="text-3xl font-gilriyBold mb-4">Privacy Policy</p>
      {isEdit && isNewBlock && <EditComponent />}
      {isEdit
        ? privacyPolicy
            ?.sort((a: any, b: any) => a?.priority - b?.priority)
            ?.map((item: any, index: number) => (
              <EditComponent key={index} item={item} />
            ))
        : privacyPolicy
            ?.sort((a: any, b: any) => a?.priority - b?.priority)
            ?.map((item: any, index: number) => (
              <div key={index} className="mb-6">
                <h2 className="text-2xl font-bold">{item?.title}</h2>
                <p className="mt-4">{item?.content}</p>
              </div>
            ))}

      <p className="text-right">All rights reserved</p>
    </div>
  );
};

export default PrivacyPolicy;
