import { useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "../../../assets/icons/pencil-icon.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus-white-icon.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { Button } from "../../../components";
import useTermsHook from "./hooks/useTerms";
import EditComponent from "./components/EditComponent";
import EditName from "./components/EditName";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const {
    isEdit,
    setIsEdit,
    isAdmin,
    terms,
    isNewBlock,
    setIsNewBlock,
    setIsNewTitle,
    isNewTitle,
  } = useTermsHook();

  return (
    <div className="px-5 py-5 md:px-20 md:py-10">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h1
          className="text-4xl font-gilriyBold text-text-red cursor-pointer"
          onClick={() => navigate("/")}
        >
          CutThroat Football
        </h1>
        <div className="flex justify-between items-center gap-4">
          {isEdit && (
            <Button
              leftIcon={<Plus />}
              onClick={() => setIsNewTitle(!isNewTitle)}
              mode={"secondary"}
              className="!w-[160px] rounded-[8px]"
            >
              {isNewTitle ? "Hide" : "Add"} new Title
            </Button>
          )}
          {isAdmin && (
            <Button
              leftIcon={isEdit ? <Check /> : <Edit />}
              onClick={() => setIsEdit(!isEdit)}
              mode={isEdit ? "primary" : "secondary"}
              className="w-[90px] rounded-[8px]"
            >
              {isEdit ? "Save" : "Edit"}
            </Button>
          )}
        </div>
      </div>
      <p className="text-3xl font-gilriyBold mb-4">Terms of Use</p>
      <p className="text-1xl mb-4">
        <span className="font-gilroyBold">IMPORTANT NOTICE:</span> PLEASE
        CAREFULLY READ THESE TERMS OF USE BEFORE USING THE SERVICE (defined
        below), AS THEY AFFECT YOUR LEGAL RIGHTS AND OBLIGATIONS. THIS AGREEMENT
        IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS AS
        DETAILED IN SECTION 15.
      </p>
      {isEdit && isNewTitle && <EditName />}
      {isEdit
        ? terms
            ?.sort((a: any, b: any) => a?.priority - b?.priority)
            ?.map((item: any, index: number) => (
              <div key={index}>
                <EditName key={index} item={item} />
                {isEdit && isNewBlock !== item?.id ? (
                  <Button
                    leftIcon={<Plus />}
                    onClick={() => setIsNewBlock(item?.id)}
                    mode={"secondary"}
                    className="!w-[160px] rounded-[8px] mb-6"
                  >
                    Add new Block
                  </Button>
                ) : (
                  <EditComponent titleId={item?.id} />
                )}
                {item?.content?.map((item: any, index: number) => (
                  <EditComponent key={index} item={item} />
                ))}
              </div>
            ))
        : terms
            ?.sort((a: any, b: any) => a?.priority - b?.priority)
            ?.map((item: any, index: number) => (
              <div key={index} className="mb-6">
                <h2 className="text-2xl font-bold mb-4">{item?.name}</h2>
                {item?.content?.map((item: any, index: number) => (
                  <div key={index}>
                    <h2 className="text-2xl font-bold">{item?.subTitle}</h2>
                    <p className="mt-4 mb-4">{item?.content}</p>
                  </div>
                ))}
              </div>
            ))}
      <p>Thank you for using CutThroat!</p>
      <p className="text-right">All rights reserved</p>
    </div>
  );
};

export default TermsAndConditions;
