import { Button, Modal, useModal } from "../../../../../components";
import { ReactComponent as DepositIcon } from "../../../../../assets/icons/deposit-icon.svg";

export const PaymentsInfoModal = () => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <PaymentsInfo closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        leftIcon={<DepositIcon />}
        mode="secondary"
        onClick={openModal}
        className="rounded-[8px] w-full px-4 text-nowrap md:w-[120px]"
      >
        Deposit
      </Button>
    </>
  );
};

const PaymentsInfo = ({ closeModal }: { closeModal?: () => void }) => {
  return (
    <>
      <div className="w-full flex flex-col justify-center items-center mt-[32px]">
        <h2 className="font-absoluteEmpire text-[32px] leading-none">
          Payments Info
        </h2>
        <p className="text-text-subGrey font-gilroyMedium mt-4">
          We currently do not accept payments, please contact admin for funds to
          be added to your account at{" "}
          <a href="mailto:cutthroatfantasysports@gmail.com">
            <span className=" underline cursor-pointer">
              cutthroatfantasysports@gmail.com
            </span>
          </a>
        </p>
      </div>

      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
        <Button
          disabled={true}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Deposit
        </Button>
      </div>
    </>
  );
};

export default PaymentsInfo;
