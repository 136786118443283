import { Suspense } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import GuestHeader from "../components/GuestHeader/GuestHeader";

const GuestLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="flex h-full w-full">
        <div className="h-full w-full">
          {(location.pathname === "/" ||
            location.pathname === "/help" ||
            location.pathname?.includes("/allleagues")) && <GuestHeader />}
          <div className="h-full w-full">
            <Outlet />
          </div>
        </div>
      </div>
      {location.pathname !== "/login" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/forgot-password" &&
        location.pathname !== "/reset-password" && (
          <div className="flex justify-center py-6">
            <p className="text-xs md:text-sm text-text-grey text-center">
              <span
                onClick={() => navigate("/privacy-policy")}
                className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer"
              >
                Privacy Policy,
              </span>{" "}
              <span
                onClick={() => navigate("/terms-conditions")}
                className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer"
              >
                Terms and Conditions,
              </span>{" "}
              <span
                onClick={() => navigate("/help")}
                className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer"
              >
                FAQ,
              </span>{" "}
              <a href="mailto:cutthroatfantasysports@gmail.com">
                Contact us:{" "}
                <span className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer">
                  cutthroatfantasysports@gmail.com
                </span>
              </a>
            </p>
          </div>
        )}
    </Suspense>
  );
};

export default GuestLayout;
