import { Button, Modal, useModal } from "../../../../components";

export const DropInfoModal = () => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <DropInfo closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        className="h-[41px] w-[65px] rounded-[8px]"
        onClick={openModal}
        mode="secondary"
      >
        Drop
      </Button>
    </>
  );
};

const DropInfo = ({ closeModal }: { closeModal?: () => void }) => {
  return (
    <>
      <p className="text-[24px] font-gilroySemibold mb-2">
        This position is required to have at least 1 player
      </p>
      <p className="font-gilroyMedium text-text-subGrey">
        You must have at least 1 RB, 1 WR, and 1 TE. You have 2 flex spots that
        can be any position of RB/WR/TE. For replace any of the player you have
        to choose the player from the available player list.
      </p>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Close
        </Button>
      </div>
    </>
  );
};

export default DropInfoModal;
