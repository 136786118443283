import { useFetchLeague } from "../../../../../queries/leaguesQueries/leaguesQueries";
import useAuthStore from "../../../../../stores/auth/auth";
import { useNavigate } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useRef, useState } from "react";
import { useFetchUserDetails } from "../../../../../queries/userQueries/userQueries";

const useHomeHook = () => {
  const { userData } = useAuthStore.getState();
  const { data: user, refetch: refetchDetails } = useFetchUserDetails(
    userData?.id!
  );
  const [activeLeagueId, setActiveLeagueId] = useState<any>(
    user?.league?.[0]?.id
  );
  const { data, refetch } = useFetchLeague(
    activeLeagueId || user?.league?.[0]?.id
  );
  const navigate = useNavigate();

  const prevButtonFunc = useRef<any>(null);
  const nextButtonFunc = useRef<any>(null);
  const myTeamId = useRef<any>(null);

  useEffect(() => {
    activeLeagueId && refetch();
  }, [activeLeagueId]);

  const balance =
    Number(user?.balance?.amount) + Number(user?.balance?.credit_amount);

  const isJoined = data?.league?.team?.filter(
    (item: any) => item?.userId === userData?.id
  )?.length;

  const inProgress = data?.league?.status === "inProgress";
  const registration = data?.league?.status === "registration";

  const sortedTeams = data?.league?.team
    ?.sort((a: any, b: any) => Number(a?.latestScore) - Number(b?.latestScore))
    ?.filter((item: any) => item?.status !== "Eliminated");

  const eliminatedTeams = data?.league?.team
    ?.filter((item: any) => item?.status === "Eliminated")
    ?.sort((a: any, b: any) => Number(b?.latestScore) - Number(a?.latestScore));

  const teamsEliminatedPerWeek = data?.league?.teamsEliminatedPerWeek;

  const { regularTeams, orangeZoneTeams, teamsAtRisk } = sortedTeams?.length
    ? sortedTeams?.reduce(
        (acc: any, item: any) => {
          if (
            sortedTeams?.length > 2 &&
            sortedTeams?.length - acc.teamsAtRisk?.length > 2 &&
            acc.teamsAtRisk?.length < teamsEliminatedPerWeek &&
            item?.status === "Confirmed"
          ) {
            acc.teamsAtRisk.push(item);
          } else if (
            sortedTeams?.length > 2 &&
            acc.teamsAtRisk?.length === teamsEliminatedPerWeek &&
            sortedTeams.length -
              acc.teamsAtRisk?.length -
              acc.orangeZoneTeams?.length >
              0 &&
            acc.orangeZoneTeams?.length < teamsEliminatedPerWeek &&
            item?.status === "Confirmed"
          ) {
            acc.orangeZoneTeams.push(item);
          } else acc.regularTeams.push(item);
          return acc;
        },
        {
          regularTeams: [],
          orangeZoneTeams: [],
          teamsAtRisk: [],
        }
      )
    : { regularTeams: [], orangeZoneTeams: [], teamsAtRisk: [] };

  const currentPosition =
    sortedTeams
      ?.sort(
        (a: any, b: any) => Number(b?.latestScore) - Number(a?.latestScore)
      )
      ?.findIndex((item: any) => item?.id === myTeamId.current) + 1;
  const teamsWithPoints = sortedTeams?.filter(
    (item: any) => Number(item?.latestScore) > 0
  );
  const lastTeamPosition = teamsWithPoints?.length;

  const CustomButtonGroup = ({ next, previous }: any) => {
    prevButtonFunc.current = previous;
    nextButtonFunc.current = next;
    return <></>;
  };

  const inviteLink = `${window.location.href?.slice(0, -10)}/leagues/league/${
    activeLeagueId || user?.league?.[0]?.id
  }`;

  const getTeamOwner = (userId: string) => {
    return data?.league?.user?.find((user: any) => user?.id === userId);
  };

  return {
    userData,
    user,
    activeLeagueId,
    setActiveLeagueId,
    data,
    navigate,
    prevButtonFunc,
    nextButtonFunc,
    balance,
    isJoined,
    inProgress,
    regularTeams,
    teamsAtRisk,
    eliminatedTeams,
    CustomButtonGroup,
    inviteLink,
    myTeamId,
    refetchDetails,
    getTeamOwner,
    orangeZoneTeams,
    sortedTeams,
    currentPosition,
    lastTeamPosition,
    registration,
  };
};
export default useHomeHook;
