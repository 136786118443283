import { Button, Modal, useModal } from "../../../../../components";
import { ReactComponent as Plus } from "../../../../../assets/icons/plus-white-icon.svg";
import { Oval } from "react-loader-spinner";
import useHelp from "../hooks/useHelp";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "../../../../../components/LabeledInput/LabeledInput";
import { toast } from "react-toastify";

export const AddSectionModal = () => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <AddSection closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "New Section",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />

      <Button
        className="w-[48px] min-h-[48px] rounded-[8px]"
        leftIcon={<Plus />}
        onClick={openModal}
        mode="secondary"
      >
        {""}
      </Button>
    </>
  );
};

const AddSection = ({ closeModal }: { closeModal?: () => void }) => {
  const { addSection, isPendingSection } = useHelp();
  const formOptions = useForm({
    defaultValues: {
      name: "",
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formOptions;

  const onAddSection = handleSubmit(async (data) => {
    try {
      await addSection({
        name: data?.name,
      });
      toast.success("Section created successfully");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      closeModal?.();
    }
  });
  return (
    <>
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <LabeledInput
            {...field}
            placeholder="Enter Section Name"
            error={errors?.name?.message}
            errorClassName="text-text-red"
            label="Name of the New Section"
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-[28px]"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
      />
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
        <Button
          disabled={isPendingSection}
          leftIcon={
            <Oval
              visible={isPendingSection}
              height="20"
              width="20"
              color="#000000"
              secondaryColor="#ffffff"
            />
          }
          onClick={onAddSection}
          size="md"
          mode="primary"
          className="!text-base w-full !rounded-[8px]"
        >
          Create
        </Button>
      </div>
    </>
  );
};

export default AddSection;
