import { ReactComponent as Arrow } from "../../../../assets/icons/draft-arrow-right.svg";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import clsx from "clsx";
import { isOdd, isValidImg } from "../../../../utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchDraft } from "../../../../queries/draftQueries/draftQueries";
import { useContext, useEffect, useState } from "react";
import useAuthStore from "../../../../stores/auth/auth";
import appContext from "../../../../context/appContext";
import { useFetchLeague } from "../../../../queries/leaguesQueries/leaguesQueries";
import { getSocket } from "../../../../socket";
import useLoadDraftPlayers from "./useLoadDraftPlayers";

const PositionRender = ({
  ...data
}: {
  index: number;
  idx: number;
  player: any;
  canMakePick: boolean;
  currentTurn: boolean;
  isViewPlayers: boolean;
  setIsViewPlayers: (data: boolean) => void;
  teams: any;
}) => {
  return (
    <>
      {data?.player ? (
        <div className="flex flex-col justify-between bg-card-stroke border-[1px] border-card-stroke h-[80px] w-[215px] rounded-[8px] px-4 py-2">
          <div className="flex justify-between">
            <p className="font-gilroyMedium">{data?.player?.position}</p>
            <p className="font-gilroyMedium">
              {data?.index}.{data?.idx}
            </p>
          </div>

          <div className="flex justify-between items-center">
            <p className="font-gilroyBold">{data?.player?.name}</p>
            <img
              src={isValidImg(data?.player?.avatar) || demo_team_logo}
              alt="CutThroat"
              className="w-[32px] h-[32px] rounded-[50px]"
            />
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            data?.canMakePick &&
              data?.currentTurn &&
              data?.setIsViewPlayers(!data?.isViewPlayers);
          }}
          className={clsx(
            "flex justify-between border-[1px] border-card-stroke h-[80px] w-[215px] rounded-[8px] px-4 py-2",
            data?.currentTurn &&
              "bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2 border-0"
          )}
        >
          <Arrow
            className={clsx(
              !isOdd(data?.index) &&
                data?.idx !== data?.teams?.length &&
                "rotate-180",
              data?.idx === data?.teams?.length && "rotate-90"
            )}
          />
          <p
            className={clsx(
              "text-text-grey font-gilroyMedium",
              data?.currentTurn && "text-text-white"
            )}
          >
            {data?.index}.{data?.idx}
          </p>
        </div>
      )}
    </>
  );
};

const useDraftHook = () => {
  const { id } = useParams();
  const socket = getSocket();
  const [draft, setDraft] = useState<any>(null);
  const { data, isFetching, refetch } = useFetchDraft(id!);
  const [isViewPlayers, setIsViewPlayers] = useState(false);
  const {} = useContext(appContext);
  const { userData } = useAuthStore.getState();
  const navigate = useNavigate();
  const isAdmin = userData?.role === "admin";
  const isDraftFull =
    data?.currentDraft?.selectedPlayer?.length ===
    6 * data?.teamsInDraft?.length;
  const { data: league } = useFetchLeague(data?.currentDraft?.leagueId);
  const { isFetching: isFetchingAllPlayers } = useLoadDraftPlayers(
    data?.currentDraft?.leagueId!
  );

  const onFocus = () => {
    refetch();
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", onFocus);
    return () => {
      window.removeEventListener("visibilitychange", onFocus);
    };
  }, []);

  useEffect(() => {
    if (
      data?.currentDraft?.selectedPlayer?.length !==
      draft?.selectedPlayer?.length
    ) {
      setDraft(data?.currentDraft);
    }
  }, [data?.currentDraft?.status, data?.currentDraft]);

  useEffect(() => {
    const handleStartDraft = (data: any) => {
      if (data?.draftId === id) {
        refetch();
      }
    };
    socket?.on("draft-started", handleStartDraft);
    return () => {
      socket?.off("draft-started", handleStartDraft);
    };
  }, []);

  const slotsWithIndexs = [...Array(6).keys()]?.map((_item, index) => {
    return { index: `${index + 1}` };
  });

  const myTeam = data?.teamsInDraft?.find(
    (item: any) => item?.userId === userData?.id
  );

  const columns = data?.teamsInDraft?.map((item: any, index: number) => {
    return {
      title: <></>,
      dataIndex: item?.id,
      activeTeam: false,
      render: (_: string, record: any) => {
        const idx =
          Number(record?.index) !== 1
            ? Number(record?.index) * data?.teamsInDraft?.length -
              data?.teamsInDraft?.length +
              index
            : index;

        const players = data?.currentDraft?.selectedPlayer?.sort(
          (a: any, b: any) =>
            new Date(a?.updatedAt)?.valueOf() -
            new Date(b?.updatedAt)?.valueOf()
        );
        const player = players?.[idx];
        const previousPlayer = players?.[idx - 1];
        const currentTurn =
          (!player && previousPlayer) || (!players?.length && idx === 0);
        const canMakePick =
          userData?.id === data?.currentDraft?.pool?.current_userId;

        return (
          <PositionRender
            {...record}
            idx={index + 1}
            teams={data?.teamsInDraft}
            player={player || null}
            currentTurn={currentTurn}
            setIsViewPlayers={setIsViewPlayers}
            isViewPlayers={isViewPlayers}
            canMakePick={canMakePick}
          />
        );
      },
    };
  });

  return {
    columns,
    data,
    isFetching,
    isViewPlayers,
    setIsViewPlayers,
    slotsWithIndexs,
    myTeam,
    userData,
    navigate,
    isAdmin,
    league,
    isFetchingAllPlayers,
    refetch,
    isDraftFull,
  };
};

export default useDraftHook;
