import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as NavIcon } from "../../assets/icons/nav-icon.svg";
import clsx from "clsx";

type Route = {
  name: string;
  path: string;
  isEnabled: boolean;
  selectedIcon?: JSX.Element;
};

const routes: Route[] = [
  {
    name: "DASHBOARD",
    path: "/dashboard",
    isEnabled: true,
    selectedIcon: NavIcon,
  },
  {
    name: "LEAGUES",
    path: "/leagues",
    isEnabled: true,
    selectedIcon: NavIcon,
  },
  {
    name: "USERS",
    path: "/users",
    isEnabled: true,
    selectedIcon: NavIcon,
  },
  {
    name: "TRANSACTIONS",
    path: "/transactions",
    isEnabled: true,
    selectedIcon: NavIcon,
  },
];

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="p-6 max-w-[240px] hidden md:block">
      <div className="w-full flex justify-center items-center mb-[32px]">
        <Logo
          className="cursor-pointer"
          onClick={() => navigate("/dashboard")}
        />
      </div>
      <div className="flex flex-col">
        {routes.map((route, _index) => {
          const isActive =
            location.pathname === route.path ||
            location.pathname.includes(route.path);
          return (
            <Link
              key={route.name}
              to={route.path}
              className={clsx(
                "px-4 py-2 font-absoluteEmpire flex flex-row justify-start items-center",
                route.isEnabled
                  ? "cursor-pointer"
                  : "cursor-not-allowed text-text-grey pointer-events-none"
              )}
              aria-disabled={!route.isEnabled}
            >
              {isActive && route.selectedIcon ? (
                <NavIcon />
              ) : (
                <div className="w-2" />
              )}
              <span
                className={clsx(
                  "px-4 py-2 font-absoluteEmpire",
                  isActive && "text-text-red"
                )}
              >
                {route.name}
              </span>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SideBar;
