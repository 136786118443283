import useAuthStore from "../../../../stores/auth/auth";
import { useEffect, useState } from "react";
import { useFetchDraftPlayers } from "../../../../queries/draftQueries/draftQueries";

const useLoadDraftPlayers = (leagueId: string) => {
  const { setAllDraftPlayers } = useAuthStore.getState();
  const { allDraftPlayers } = useAuthStore(["allDraftPlayers"]);
  const [limit, _setLimit] = useState(1000);
  const [offset, _setOffset] = useState(allDraftPlayers?.offset ?? 0);

  const {
    data: avbPlayers,
    refetch,
    isFetching,
  } = useFetchDraftPlayers(leagueId!, {
    page: 1,
    limit: limit,
    offset: offset,
  });

  useEffect(() => {
    if (!allDraftPlayers?.players?.length && avbPlayers?.length) {
      setAllDraftPlayers({
        offset: offset + 1000,
        players: avbPlayers,
      });
    }
  }, [avbPlayers]);

  const getNextPlayers = async () => {
    await refetch();

    if (avbPlayers?.length) {
      setAllDraftPlayers({
        offset: offset + 1000,
        players: [...allDraftPlayers?.players, ...avbPlayers],
      });
    }
  };

  return { avbPlayers, getNextPlayers, isFetching };
};

export default useLoadDraftPlayers;
