import { useState } from "react";
import DatePicker from "react-datepicker";
import { ReactComponent as Calendar } from "../../../../assets/icons/calendar-icon.svg";
import { ReactComponent as Down } from "../../../../assets/icons/arrow-down.svg";
import moment from "moment";
import { Button } from "../../../../components";

const DateFilter = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}: {
  fromDate: any;
  setFromDate: (data: any) => void;
  toDate: any;
  setToDate: (data: any) => void;
}) => {
  const [dateFilterOpen, setDateFilterOpen] = useState(false);

  const onReset = () => {
    setFromDate(null);
    setToDate(null);
    setDateFilterOpen(false);
  };

  const onConfirm = () => {
    setDateFilterOpen(false);
  };

  return (
    <div className="relative">
      <div
        onClick={() => setDateFilterOpen(!dateFilterOpen)}
        className="flex items-center gap-2 cursor-pointer"
      >
        <p className="font-gilroyMedium">Time Period</p>
        <p className="text-sm font-gilroyMedium text-text-white">
          {fromDate && toDate
            ? `${moment(fromDate).format("LL")} - ${moment(toDate).format(
                "LL"
              )}`
            : "All"}
        </p>
        <Down />
      </div>

      {dateFilterOpen && (
        <div className="absolute top-10 right-0 z-[10] w-[262px] p-6 bg-card-bgGreyDark border-[1px] border-modal-modalStroke rounded-[12px] md:left-0 md:w-[350px]">
          <div className="flex justify-between items-center">
            <p className="text-xs font-gilroyMedium">
              From <span className="text-text-grey">(Date)</span>
            </p>
            <p
              onClick={onReset}
              className="text-xs font-gilroyMedium text-text-red underline cursor-pointer"
            >
              Reset
            </p>
          </div>
          <DatePicker
            showPopperArrow={false}
            selected={fromDate}
            dateFormat="yyyy-mm-dd"
            onChange={(date: any) => setFromDate(date)}
            customInput={
              <div className="mt-2 py-4 px-5 bg-card-bgGreyMedium rounded-[100px] h-[52px] w-full cursor-pointer flex flex-row justify-between items-center">
                <div className="flex flex-col justify-center">
                  <p className="text-text-grey text-base font-gilroyMedium text-left mt-[2px] sm:text-nowrap">
                    {fromDate
                      ? moment(fromDate).format("LL")
                      : "Select Date..."}
                  </p>
                </div>
                <Calendar />
              </div>
            }
          />
          <p className="text-xs font-gilroyMedium mt-6">
            To <span className="text-text-grey">(Date)</span>
          </p>
          <DatePicker
            showPopperArrow={false}
            selected={toDate}
            dateFormat="yyyy-mm-dd"
            onChange={(date: any) => setToDate(date)}
            customInput={
              <div className="mt-2 py-4 px-5 bg-card-bgGreyMedium rounded-[100px] h-[52px] w-full cursor-pointer flex flex-row justify-between items-center">
                <div className="flex flex-col justify-center">
                  <p className="text-text-grey text-base font-gilroyMedium text-left mt-[2px] sm:text-nowrap">
                    {toDate ? moment(toDate).format("LL") : "Select Date..."}
                  </p>
                </div>
                <Calendar />
              </div>
            }
          />
          <Button
            onClick={onConfirm}
            disabled={!fromDate || !toDate}
            className="mt-6 w-full !h-[52px]"
          >
            Confirm
          </Button>
        </div>
      )}
    </div>
  );
};
export default DateFilter;
