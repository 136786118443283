import { Input } from "../../../../components";
import TableCardsListing from "../../../../components/TableCardsListing/TableCardsListing";
import useUserDraftsHook from "./hooks/useUsersDrafts";
import { ReactComponent as Search } from "../../../../assets/icons/search-icon.svg";
import MobileDrafts from "./components/MobileDrafts";

const UsersDrafts = () => {
  const { columns, isFetching, search, setSearch, drafts } =
    useUserDraftsHook();

  return (
    <>
      <div className="hidden px-10 pb-6 mt-6 md:block">
        <div className="w-full flex justify-between">
          <div className="w-[40%] flex flex-row justify-start items-center">
            <div>
              <h2 className="font-absoluteEmpire text-[30px] leading-none">
                Your Active Drafts
              </h2>
            </div>
          </div>
        </div>
        <div className="mt-6 flex gap-6">
          <div className="w-full">
            <div className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-start items-center">
              <div className="relative flex flex-row justify-between items-center gap-[32px]">
                <p className="font-gilroyBold">Drafts</p>
                <Input
                  value={search}
                  containerClassName="!h-[20px] border-none !py-0 !px-1 bg-transparent focus-within:bg-transparent !w-[70px]"
                  inputClassName="placeholder-text-white text-sm"
                  placeholder="Search"
                  leftIcon={<Search />}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
            <TableCardsListing
              columns={columns}
              onClickRow={() => {}}
              dataSource={drafts}
              overrideTableContainerClass=""
              loading={isFetching}
            />
          </div>
        </div>
      </div>

      <div className="block px-[16px] pb-6 mt-6 md:hidden">
        <div className="w-[100%] flex flex-col gap-6 justify-between">
          <div className="w-[100%] flex flex-col gap-2 justify-start items-start">
            <h2 className="font-absoluteEmpire text-[30px] leading-none">
              Your active Drafts
            </h2>
          </div>
        </div>

        <div className="mt-6 flex gap-6">
          <div className="w-full">
            <div className="flex gap-4 flex-col lg:hidden">
              <p className="font-gilroyBold mt-8 mb-3">Drafts</p>
              <MobileDrafts leagues={drafts} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersDrafts;
