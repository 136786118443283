import { RouterProvider } from "react-router-dom";
import useRouter from "./router/privateRouter";
import { publicRouter } from "./router/publicRouter";
import useAuthStore from "./stores/auth/auth";

export const AppRouter = () => {
  const { privateRouter } = useRouter();
  const { isLoggedIn } = useAuthStore(["isLoggedIn"]);
  return <RouterProvider router={isLoggedIn ? privateRouter : publicRouter} />;
};
