import { Oval } from "react-loader-spinner";
import useDraftCountdown from "../hooks/useDraftCountdown";

const RoundTimer = ({
  timeout,
  refetch,
  isSelectedPlayers,
}: {
  timeout: number;
  refetch?: () => void;
  isSelectedPlayers?: boolean;
}) => {
  const { roundSeconds, loading } = useDraftCountdown(
    timeout,
    refetch,
    isSelectedPlayers
  );
  return (
    <>
      {loading ? (
        <Oval
          visible={loading}
          height="32"
          width="32"
          color="#000000"
          secondaryColor="#ffffff"
        />
      ) : (
        <p className="text-[32px] font-absoluteEmpire">
          00:
          {roundSeconds < 10
            ? `0${roundSeconds === 1 ? "1" : roundSeconds}`
            : roundSeconds}
        </p>
      )}
    </>
  );
};
export default RoundTimer;
