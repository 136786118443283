import { useEffect, useState } from "react";
import moment from "moment-timezone";
import clsx from "clsx";
import league_logo from "../../../../assets/images/ball-logo.png";
import useFilter from "../../../../hooks/useFilter";
import { useFetchLeaguesList } from "../../../../queries/leaguesQueries/leaguesQueries";
import { AddNewLeagueModal } from "../components/AddNewLeague";
import { useNavigate } from "react-router-dom";
import { DeleteLeagueModal } from "../../../../pages/private/League/components/DeleteLeague";
import {
  formatDateTimeInTimeZone,
} from "../../../../utils/helpers";

const NameRender = ({ ...data }) => {
  const [imageSrc, setImageSrc] = useState(league_logo);

  useEffect(() => {
    if (data.logoUrl) {
      const img = new Image();
      img.src = data.logoUrl;
      img.onload = () => setImageSrc(data.logoUrl);
    }
  }, [data.logoUrl]);
  return (
    <div
      onClick={() => data?.navigate(`/leagues/league/${data?.id}`)}
      className={`flex flex-row items-center cursor-pointer`}
    >
      <img
        className="w-[40px] h-[40px] rounded-full"
        src={imageSrc}
        alt=""
      />
      <div className="flex flex-col ml-6 text-base">{data?.name || ""}</div>
    </div>
  );
};

const StatusRender = ({ ...data }) => {
  return (
    <div
      onClick={() => data?.navigate(`/leagues/league/${data?.id}`)}
      className={`flex items-center justify-between cursor-pointer`}
    >
      <div
        className={clsx(
          "text-base px-4 py-1 rounded-[16px] bg-gradient-to-br capitalize text-nowrap",
          data?.status === "registration" &&
            "from-elements-chipGradient-1 to-elements-chipGradient-2",
          data?.status === "inProgress" &&
            "from-red-bgGradient2-1 to-red-bgGradient2-2",
          data?.status === "completed" && "bg-white text-screenBgGrey",
          data?.status === "archived" && "bg-white text-screenBgGrey"
        )}
      >
        {data?.status || ""}
      </div>
    </div>
  );
};

const TeamsRender = ({ ...data }) => {
  return (
    <div
      onClick={() => data?.navigate(`/leagues/league/${data?.id}`)}
      className={`flex flex-col cursor-pointer`}
    >
      <p>
        {data?.team
          ? data?.team?.length + "/" + data?.numberOfTeams
          : data?.numberOfTeams + " Teams"}
      </p>
      <p className="text-sm text-text-subGrey">
        {data?.teamsEliminatedPerWeek + " Eliminated Weekly"}
      </p>
    </div>
  );
};

const PPRRender = ({ ...data }) => {
  return (
    <div
      onClick={() => data?.navigate(`/leagues/league/${data?.id}`)}
      className={`flex flex-col cursor-pointer`}
    >
      <p>{data?.receivingOption}</p>
      <p className="text-sm text-text-subGrey">Scoring Type</p>
    </div>
  );
};

const DateRender = ({ ...data }) => {
  return (
    <div
      onClick={() => data?.navigate(`/leagues/league/${data?.id}`)}
      className={`flex flex-col cursor-pointer`}
    >
      <p>{formatDateTimeInTimeZone(data?.draftDateTime, "LL")}</p>
      <p className="text-sm text-text-subGrey">Draft Date</p>
    </div>
  );
};

const ActionsRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row gap-4`}>
      <AddNewLeagueModal isEdit leagueId={data?.id} isListView />
      <DeleteLeagueModal leagueId={data?.id} isListView />
    </div>
  );
};

const EntryFeeRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p>${data?.entryFee}</p>
      <p className="text-sm text-text-subGrey">Entry Fee</p>
    </div>
  );
};

const useLeaguesHook = () => {
  const navigate = useNavigate();
  const { filter, onChangeFilter } = useFilter({});
  const { data, isFetching } = useFetchLeaguesList({
    page: filter?.page,
    limit: filter?.limit,
    status: filter?.status !== "All" ? filter?.status?.toLocaleString() : null,
    numberOfTeams:
      filter?.numberOfTeams !== "All" ? filter?.numberOfTeams : null,
    isPPR:
      filter?.receivingOption !== "All"
        ? filter?.receivingOption === "PPR"
          ? true
          : filter?.receivingOption === "Non-PPR"
          ? false
          : null
        : null,
    entryFeeSort:
      filter?.entryFee !== "All"
        ? filter?.entryFee === "Low"
          ? "asc"
          : filter?.entryFee === "High"
          ? "desc"
          : null
        : null,
    minTeams:
      filter?.registeredTeams !== "All"
        ? // @ts-ignore
          filter?.registeredTeams?.split("-")?.[0]
        : null,
    maxTeams:
      filter?.registeredTeams !== "All"
        ? // @ts-ignore
          filter?.registeredTeams?.split("-")?.[1]
        : null,
    name: filter?.keywords,
  });
  const [leagues, setLeagues] = useState(data?.message ?? []);

  const onFilterValues = () => {
    const filteredLeagues = data?.message?.filter((item: any) => {
      if (filter?.draftDateTime !== "All") {
        // @ts-ignore
        return (
          moment(item.draftDateTime).format("LL") ===
          moment(filter?.draftDateTime).format("LL")
        );
      } else return item.draftDateTime;
    });

    setLeagues(filteredLeagues);
  };

  useEffect(() => {
    onFilterValues();
  }, [
    filter?.status,
    filter?.numberOfTeams,
    filter?.receivingOption,
    filter?.draftDateTime,
    filter?.keywords,
    filter?.entryFee,
    filter?.registeredTeams,
    data,
  ]);

  const filters = [
    {
      name: "Search",
      value: "keywords",
    },
    {
      name: "Status",
      value: "status",
      filters: ["All", "Registration", "inProgress", "Completed", "Archived"],
    },
    {
      name: "Number of Teams",
      value: "numberOfTeams",
      filters: ["All", "2-4", "4-10", "10-16", "17-32"],
    },
    {
      name: "Scoring Type",
      value: "receivingOption",
      filters: ["All", "PPR", "Non-PPR"],
    },
    {
      name: "Draft Date",
      value: "draftDateTime",
    },
    {
      name: "Entry Fee",
      value: "entryFee",
      filters: ["All", "Low", "High"],
    },
    {
      name: "Number of Registered Teams ",
      value: "registeredTeams",
      filters: ["All", "0-4", "4-10", "10-16", "16-32"],
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: string, record: any) => {
        return <NameRender navigate={navigate} {...record} />;
      },
      sort: true,
    },
    {
      title: "Entry Fee",
      dataIndex: "entryFee",
      render: (_: string, record: any) => {
        return <EntryFeeRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: string, record: any) => {
        return <StatusRender navigate={navigate} {...record} />;
      },
      sort: true,
    },
    {
      title: "Teams",
      dataIndex: "teams",
      render: (_: string, record: any) => {
        return <TeamsRender navigate={navigate} {...record} />;
      },
      sort: true,
    },
    {
      title: "PPR",
      dataIndex: "ppr",
      render: (_: string, record: any) => {
        return <PPRRender navigate={navigate} {...record} />;
      },
      sort: true,
    },
    {
      title: "date",
      dataIndex: "date",
      render: (_: string, record: any) => {
        return <DateRender navigate={navigate} {...record} />;
      },
      sort: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: string, record: any) => {
        return <ActionsRender {...record} />;
      },
      sort: true,
    },
  ];

  return { columns, isFetching, filters, leagues, filter, onChangeFilter };
};

export default useLeaguesHook;
