import { useState } from "react";
import {
  useDeleteLeague,
  useFetchCurrentWeek,
  useFetchEliminationDate,
  useFetchLeague,
  useFetchLeagueCurrentWeek,
  useFetchSecodaryScores,
  useJoinLeague,
} from "../../../../queries/leaguesQueries/leaguesQueries";
import { useNavigate, useParams } from "react-router-dom";
import { isInteger, numberWithCommas } from "../../../../utils/helpers";
import { toast } from "react-toastify";
import { DraftServices } from "../../../../services/apis/Draft/DraftServices";
import useAuthStore from "../../../../stores/auth/auth";

const useLeagueHook = (leagueId?: string, closeModal?: () => void) => {
  const { id } = useParams();
  const { data: currentLeagueWeek } = useFetchCurrentWeek();
  const [week, setWeek] = useState<any>(currentLeagueWeek);
  const { data: leagueCurrentWeek } = useFetchLeagueCurrentWeek(
    leagueId || id!
  );

  const { data, isFetching } = useFetchLeague(id!, {
    week: week ? week : currentLeagueWeek,
  });
  const { data: eliminationDate, isFetching: isFetchingEliminationDate } =
    useFetchEliminationDate();
  const { mutateAsync, isPending: isPendingJoin } = useJoinLeague();
  const { mutateAsync: deleteLeague, isPending: isPendingDelete } =
    useDeleteLeague();
  const { data: secodaryScores, isFetching: isFetchingSecondaryScore } =
    useFetchSecodaryScores(
      {
        leagueId: leagueCurrentWeek?.league?.id,
        week: week ? week : currentLeagueWeek,
      },
      leagueCurrentWeek?.league?.status === "registration" ? false : true
    );
  const { userData } = useAuthStore.getState();
  const navigate = useNavigate();

  const isAdmin = userData?.role === "admin";
  const isJoined = data?.league?.team?.filter(
    (item: any) => item?.userId === userData?.id
  )?.length;
  const inProgress = data?.league?.status === "inProgress";
  const completed = data?.league?.status === "completed";
  const registration = data?.league?.status === "registration";

  const sortedTeams = data?.league?.team
    ?.sort((a: any, b: any) => Number(a?.latestScore) - Number(b?.latestScore))
    ?.filter((item: any) => !item?.eliminatedAt || item?.eliminatedAt > week);

  const eliminatedTeams = data?.league?.team
    ?.filter(
      (item: any) =>
        item?.status === "Eliminated" && item?.eliminatedAt === week
    )
    ?.sort((a: any, b: any) => Number(b?.latestScore) - Number(a?.latestScore));

  const teamsEliminatedPerWeek =
    leagueCurrentWeek?.league?.teamsEliminatedPerWeek;

  const { regularTeams, orangeZoneTeams, teamsAtRisk } = sortedTeams?.length
    ? sortedTeams?.reduce(
        (acc: any, item: any) => {
          const secondaryScore = secodaryScores?.find(
            (team: any) => team?.teamId === item?.id
          )?.secondaryScore;
          item.secondaryScore = secondaryScore;
          if (
            sortedTeams?.length > 2 &&
            sortedTeams?.length - acc.teamsAtRisk?.length > 2 &&
            acc.teamsAtRisk?.length < teamsEliminatedPerWeek
          ) {
            acc.teamsAtRisk.push(item);
          } else if (
            sortedTeams?.length > 2 &&
            acc.teamsAtRisk?.length === teamsEliminatedPerWeek &&
            sortedTeams.length -
              acc.teamsAtRisk?.length -
              acc.orangeZoneTeams?.length >
              0 &&
            acc.orangeZoneTeams?.length < teamsEliminatedPerWeek
          ) {
            acc.orangeZoneTeams.push(item);
          } else acc.regularTeams.push(item);
          return acc;
        },
        {
          regularTeams: [],
          orangeZoneTeams: [],
          teamsAtRisk: [],
        }
      )
    : { regularTeams: [], orangeZoneTeams: [], teamsAtRisk: [] };

  const weekSlots = currentLeagueWeek
    ? [...Array(Math.ceil(currentLeagueWeek)).keys()]
    : [];
  const prjSlots = currentLeagueWeek
    ? [...Array(18 - Math.ceil(currentLeagueWeek)).keys()]
    : [];

  const handleNumber = (value: any) => {
    if (isInteger(Number(value))) {
      return numberWithCommas(Number(value)?.toFixed(2));
    } else return value;
  };

  const handleDeleteLeague = async () => {
    try {
      await deleteLeague(leagueId || id!);
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      closeModal?.();
    }
  };

  const launchDraft = async () => {
    try {
      const draft = await DraftServices.launchDraft(id!);
      navigate(`/draft/${draft?.data?.draft?.id}`);
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  const joinLeague = async () => {
    try {
      await mutateAsync({ leagueId: id || leagueId!, userId: userData?.id });
      window.location.reload();
      toast.success("You have successfully joined the league!");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  const closeRegistration = async () => {
    //TODO: add api for close registration
    try {
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  const getTeamOwner = (userId: string) => {
    return data?.league?.user?.find((user: any) => user?.id === userId);
  };

  return {
    isFetching,
    handleNumber,
    data,
    handleDeleteLeague,
    launchDraft,
    closeRegistration,
    joinLeague,
    isJoined,
    inProgress,
    regularTeams,
    teamsAtRisk,
    eliminatedTeams,
    id,
    isAdmin,
    userData,
    navigate,
    completed,
    isPendingDelete,
    isPendingJoin,
    eliminationDate,
    week,
    setWeek,
    weekSlots,
    getTeamOwner,
    prjSlots,
    currentLeagueWeek,
    leagueCurrentWeek,
    isFetchingEliminationDate,
    orangeZoneTeams,
    registration,
    isFetchingSecondaryScore,
  };
};

export default useLeagueHook;
