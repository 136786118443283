import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import clsx from "clsx";
import { Button, Input, TextArea } from "../../../../components";
import { useEffect, useState } from "react";
import {
  useAddTerms,
  useEditTerms,
} from "../../../../queries/adminQueries/adminQueries";
import { toast } from "react-toastify";
import useTermsHook from "../hooks/useTerms";
import { DeleteContentModal } from "./DeleteContent";

const EditComponent = ({ item, titleId }: { item?: any; titleId?: number }) => {
  const { setIsNewBlock } = useTermsHook();
  const { mutateAsync } = useAddTerms();
  const { mutateAsync: editTerms } = useEditTerms();
  const [subTitle, setSubTitle] = useState(item?.subTitle || "");
  const [content, setContent] = useState(item?.content || "");

  useEffect(() => {
    setSubTitle(item?.subTitle);
    setContent(item?.content);
  }, [item?.subTitle, item?.content]);

  const onAddTerms = async () => {
    try {
      if (content) {
        await mutateAsync({
          titleId: titleId,
          contentData: {
            subTitle: subTitle,
            content: content,
          },
        });
        setIsNewBlock(false);
        setSubTitle("");
        setContent("");
        toast.success("Block created successfully");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  const onUpdateTerms = async () => {
    try {
      await editTerms({
        contentId: item?.id,
        newContentData: {
          subTitle: subTitle,
          content: content,
        },
      });
      toast.success("Block updated successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="w-full">
      <div className="flex items-center">
        <Input
          value={subTitle}
          placeholder="Enter the SubTitle..."
          onChange={(e) => {
            setSubTitle(e.target.value);
          }}
          containerClassName={clsx("w-[85%] border-none rounded-[8px]")}
        />
        <div className="flex gap-2 ml-2">
          {item && <DeleteContentModal contentId={item?.id} />}
        </div>
      </div>
      <TextArea
        value={content}
        placeholder="Enter the description..."
        onChange={(e) => {
          setContent(e.target.value);
        }}
        containerClassName="w-[85%] border-none rounded-[8px] mt-6"
      />
      {((item && item?.subTitle !== subTitle) ||
        (item && item?.content !== content) ||
        (!item && content)) && (
        <Button
          className="rounded-[8px] mt-6"
          leftIcon={<Check />}
          onClick={!item ? onAddTerms : onUpdateTerms}
        >
          Save this Block Changes
        </Button>
      )}
      <div className="w-full h-[1px] bg-modal-modalStroke my-6" />
    </div>
  );
};

export default EditComponent;
