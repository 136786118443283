import useAuthStore from "../../../../../stores/auth/auth";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useFetchUserDetails } from "../../../../../queries/userQueries/userQueries";
import league_logo from "../../../../../assets/images/ball-logo.png";
import { isValidImg } from "../../../../../utils/helpers";
import { Button } from "../../../../../components";
import { useNavigate } from "react-router-dom";

const NameRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row items-center`}>
      <img
        className="w-[40px] h-[40px] rounded-[20px]"
        src={isValidImg(data?.logoUrl) || league_logo}
        alt="League logo"
      />
      <div className="flex flex-col ml-6 text-base">{data?.name || ""}</div>
    </div>
  );
};

const StatusRender = ({ ...data }) => {
  return (
    <div className={`flex items-center justify-between`}>
      <div
        className={clsx(
          "text-base px-4 py-1 rounded-[16px] bg-gradient-to-br capitalize text-nowrap",
          data?.status === "registration" &&
            "from-elements-chipGradient-1 to-elements-chipGradient-2",
          data?.status === "inProgress" &&
            "from-red-bgGradient2-1 to-red-bgGradient2-2",
          data?.status === "completed" && "bg-white text-screenBgGrey",
          data?.status === "archived" && "bg-white text-screenBgGrey"
        )}
      >
        {data?.status || ""}
      </div>
    </div>
  );
};

const ActionRender = ({ ...data }) => {
  return (
    <>
      <Button
        className="rounded-[8px] !w-[100px] !max-h-[40px]"
        mode="primary"
        onClick={() => data?.navigate(`/draft/${data?.draft?.[0]?.id}`)}
      >
        Go To Draft
      </Button>
    </>
  );
};

const useUserDraftsHook = () => {
  const navigate = useNavigate();
  const { userData } = useAuthStore.getState();
  const { data: user, isFetching } = useFetchUserDetails(userData?.id);
  const [search, setSearch] = useState("");

  const statusOrder = {
    active: 1,
    scheduled: 2,
    completed: 4,
    finished: 3,
  };

  const sortByStatus = (a: any, b: any) => {
    const statusA = a?.draft?.[0]?.status;
    const statusB = b?.draft?.[0]?.status;
    //@ts-ignore
    if (statusOrder[statusA] < statusOrder[statusB]) {
      return -1;
    }
    //@ts-ignore
    if (statusOrder[statusA] > statusOrder[statusB]) {
      return 1;
    }
    return 0;
  };

  const [drafts, setDrafts] = useState(
    user?.league
      ?.filter((item: any) => item?.status !== "archived")
      ?.sort(sortByStatus) ?? []
  );

  const onFilterValues = () => {
    const filteredDrafts = user?.league
      ?.filter((item: any) => item?.status !== "archived")
      ?.sort(sortByStatus)
      ?.filter((item: any) => {
        if (
          item?.name?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())
        ) {
          return item;
        }
      });

    setDrafts(filteredDrafts);
  };

  useEffect(() => {
    onFilterValues();
  }, [search, user]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: string, record: any) => {
        return <NameRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: string, record: any) => {
        return <StatusRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: string, record: any) => {
        return <ActionRender {...record} navigate={navigate} />;
      },
      sort: true,
    },
  ];

  return {
    columns,
    isFetching,
    search,
    setSearch,
    drafts,
  };
};

export default useUserDraftsHook;
