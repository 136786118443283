import { Button } from "../../../components";
import { ReactComponent as Share } from "../../../assets/icons/share.svg";
import { ReactComponent as RedRec } from "../../../assets/icons/red-rectangle.svg";
import { ReactComponent as GreyRec } from "../../../assets/icons/grey-rectangle.svg";
import { AddNewLeagueModal } from "../Leagues/components/AddNewLeague";
import {
  formatAsFriendlyPastDate,
  groupMessagesByDay,
  numberWithCommas,
} from "../../../utils/helpers";
import TransactionCard from "./components/TransactionCard";
import LeagueCard from "./components/LeagueCard";
import UserCard from "./components/UserCard";
import useDashboard from "./hooks/useDashboard";
import { InviteUsersModal } from "../League/components/InviteUsers";

const Dashboard = () => {
  const {
    data,
    usersCount,
    leaguesCount,
    users,
    latestTransactions,
    leagues,
    navigate,
    onShare,
  } = useDashboard();

  return (
    <div className="px-4 pb-6 md:px-10">
      <div className="w-full hidden justify-end gap-4 lg:flex">
        <AddNewLeagueModal />
        <InviteUsersModal />
        <Button
          leftIcon={<Share />}
          mode="secondary"
          onClick={onShare}
          className="rounded-[8px] w-auto px-4 !h-10"
        >
          Share
        </Button>
      </div>
      <div className="w-full flex flex-col gap-4 lg:hidden">
        <div className="w-full flex justify-end gap-4">
          <AddNewLeagueModal />
          <InviteUsersModal />
        </div>
        <div className="w-full flex justify-end gap-4">
          <Button
            leftIcon={<Share />}
            mode="secondary"
            onClick={onShare}
            className="rounded-[8px] w-full px-4 !h-10"
          >
            Share
          </Button>
        </div>
      </div>
      <div className="mt-6 flex gap-6 flex-col lg:flex-row">
        <div className="flex h-full flex-col gap-6 w-full lg:w-[40%]">
          <div className="flex h-[80px]">
            <div className="relative bg-red-bgGradient2-1 py-4 pl-6 rounded-l-[12px] w-[45%] flex flex-col justify-center items-center">
              <p className="text-text-redLight text-xs font-gilroyBold">
                Total Revenues
              </p>
              <p className="mt-1 font-absoluteEmpire text-[18px]">
                ${numberWithCommas(Number(data?.totalRevenues)?.toFixed(2))}
              </p>
              <RedRec className="absolute right-[-48px] z-[10]" />
            </div>
            <div className="relative bg-card-bgGreyLight py-4 pl-6 rounded-r-[12px] w-[55%] flex flex-col justify-center items-center">
              <p className="text-text-grey text-xs font-gilroyBold">
                Total Expenses
              </p>
              <p className="mt-1 font-absoluteEmpire text-[18px]">
                ${numberWithCommas(Number(data?.totalExpenses)?.toFixed(2))}
              </p>
            </div>
          </div>
          <div className="w-full ">
            <div className="px-6 py-4 bg-filterContainer border-t-[1px] border-x-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
              <p className="font-gilroyBold">Latest Transactions</p>
              <p
                className="w-fit px-1 font-gilroyMedium text-text-red cursor-pointer"
                onClick={() => navigate("/transactions")}
              >
                View all
              </p>
            </div>
            <div className="w-full border-b-[1px] border-x-[1px] border-modal-modalStroke h-[400px] bg-card-bgGreyDark rounded-b-[12px] overflow-hidden flex flex-col-reverse justify-end p-6">
              {latestTransactions?.length ? (
                Object.keys(groupMessagesByDay(latestTransactions))?.map(
                  (date, i) => {
                    const group = groupMessagesByDay(latestTransactions)[date];
                    return (
                      <div className="flex flex-col gap-1" key={i}>
                        <div className="flex gap-4 my-1">
                          <p className="text-text-grey font-gilroyMedium text-nowrap">
                            {formatAsFriendlyPastDate(
                              new Date(group[0].createdAt)
                            )?.toString()}
                          </p>
                        </div>
                        {group?.map((item: any, index: number) => {
                          return (
                            <TransactionCard key={index} transaction={item} />
                          );
                        })}
                      </div>
                    );
                  }
                )
              ) : (
                <p className="font-gilroyMedium text-text-grey">
                  No transactions
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-6 w-[68%]">
          <div className="flex h-[80px]">
            <div className="relative bg-card-bgGreyLight py-4 pl-6 rounded-l-[12px] w-full flex flex-col justify-center items-center">
              <p className="text-text-grey text-xs font-gilroyBold">
                Active Leagues
              </p>
              <p className="mt-1 font-absoluteEmpire text-[18px]">
                {leaguesCount}
              </p>
              <GreyRec className="absolute right-[-48px] z-[10]" />
            </div>
            <div className="relative bg-modal-inputBg py-4 pl-6 rounded-r-[12px] w-full flex flex-col justify-center items-center">
              <p className="text-text-grey text-xs font-gilroyBold">
                Total Users
              </p>
              <p className="mt-1 font-absoluteEmpire text-[18px]">
                {usersCount}
              </p>
            </div>
          </div>
          <div className="w-full flex gap-4 flex-col lg:flex-row">
            <div className="w-full h-full lg:w-[60%]">
              <div className="px-6 py-4 bg-filterContainer border-t-[1px] border-x-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
                <p className="font-gilroyBold">League Status</p>
                <p
                  onClick={() => navigate("/leagues")}
                  className="w-fit px-1 font-gilroyMedium text-text-red cursor-pointer"
                >
                  View all
                </p>
              </div>
              <div className="w-full border-b-[1px] border-x-[1px] border-modal-modalStroke bg-card-bgGreyDark rounded-b-[12px] h-[400px] flex flex-col gap-2 p-4 overflow-y-auto scrollbar">
                <div className="flex justify-between items-center px-2 my-1 md:px-4">
                  <p className="text-text-grey text-xs">Leagues</p>
                  <div className="flex justify-between items-center w-[46%]">
                    <p className="text-text-grey text-xs">Teams</p>
                    <p className="text-text-grey text-xs mr-8">Status</p>
                  </div>
                </div>
                {leagues?.message?.length
                  ? leagues?.message?.map((league: any, index: number) => (
                      <LeagueCard key={index} league={league} />
                    ))
                  : null}
              </div>
            </div>
            <div className="border-[1px] border-modal-modalStroke rounded-[13px] w-full h-full bg-modal-grey lg:w-[45%]">
              <div className="relative px-6 py-4 bg-filterContainer rounded-t-[12px] flex flex-row justify-between items-center">
                <p className="font-gilroyBold">Latest Registered Users</p>
                <p
                  onClick={() => navigate("/users")}
                  className="w-fit px-2 font-gilroyMedium text-text-red cursor-pointer"
                >
                  View all
                </p>
              </div>
              <div className="rounded-b-[12px] h-[400px] p-6 flex flex-col-reverse justify-end overflow-y-auto scrollbar">
                {users?.message?.length ? (
                  Object.keys(groupMessagesByDay(users?.message))?.map(
                    (date, i) => {
                      const group = groupMessagesByDay(users?.message)[date];
                      return (
                        <div className="flex flex-col" key={i}>
                          <div className="flex gap-4 my-1">
                            <p className="text-text-grey font-gilroyMedium text-nowrap">
                              {formatAsFriendlyPastDate(
                                new Date(group[0].createdAt)
                              )?.toString()}
                            </p>
                          </div>
                          {group?.map((item: any, index: number) => {
                            return <UserCard user={item} key={index} />;
                          })}
                        </div>
                      );
                    }
                  )
                ) : (
                  <p className="font-gilroyMedium text-text-grey">
                    No transactions
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
