import SideBar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import { Suspense } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useAuthStore from "../stores/auth/auth";

const Layout = () => {
  const { userData } = useAuthStore.getState();
  const navigate = useNavigate();
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="flex h-full w-full">
        {userData?.role !== "user" && <SideBar />}
        <div className="h-full w-full">
          <Header />
          <div className="h-full w-full">
            <Outlet />
          </div>
        </div>
      </div>
      <div className="flex justify-center py-6">
        <p className="text-xs md:text-sm text-text-grey text-center">
          <span
            onClick={() => navigate("/privacy-policy")}
            className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer"
          >
            Privacy Policy,
          </span>{" "}
          <span
            onClick={() => navigate("/terms-conditions")}
            className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer"
          >
            Terms and Conditions,
          </span>{" "}
          <span
            onClick={() => navigate("/help")}
            className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer"
          >
            FAQ,
          </span>{" "}
          <a href="mailto:cutthroatfantasysports@gmail.com">
            Contact us:{" "}
            <span className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer">
              cutthroatfantasysports@gmail.com
            </span>
          </a>
        </p>
      </div>
    </Suspense>
  );
};

export default Layout;
