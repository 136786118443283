import league_logo from "../../../../../assets/images/ball-logo.png";
import { useNavigate } from "react-router";
import { isValidImg } from "../../../../../utils/helpers";

interface MobileLeaguesProps {
  leagues: { [key: string]: any }[];
}
const MobileDrafts = ({ leagues }: MobileLeaguesProps) => {
  const navigate = useNavigate();

  return (
    <>
      {leagues?.length > 0 ? (
        leagues?.map((data: { [key: string]: any }, index: number) => (
          <div
            key={index}
            className=" bg-card-bgGreyMedium gap-4 rounded-[16px] p-6 flex flex-col"
          >
            <div className="flex justify-between">
              <img
                className="rounded-full"
                width={40}
                height={40}
                src={isValidImg(data.logoUrl) || league_logo}
                alt="League logo"
              />
              <div>
                <p className="font-gilroyBold text-base">{data?.name || ""}</p>
                <p className="text-sm text-text-subGrey">
                  {data?.status || ""}
                </p>
              </div>
              <div
                onClick={() => navigate(`/draft/${data?.draft?.[0]?.id}`)}
                className={`flex flex-col cursor-pointer`}
              >
                <p className="underline text-nowrap">Go To Draft</p>
              </div>
            </div>
            <div className="bg-card-strokeGradient-2 h-[2px]"></div>
            <div className="flex justify-between"></div>
          </div>
        ))
      ) : (
        <div className=" bg-card-bgGreyMedium gap-4 rounded-[16px] p-6 flex flex-col">
          <p className="text-base text-text-subGrey">No data available</p>
        </div>
      )}
    </>
  );
};

export default MobileDrafts;
