import clsx from "clsx";

import { DescriptionViewPropsType } from "./type";

export const DescriptionView = (props: DescriptionViewPropsType) => {
  const { description = "", className = "" } = props;
  return (
    <div
      className={clsx(
        "text-s text-left font-normal text-neutral-white-4 capitalize",
        className
      )}
    >
      {description}
    </div>
  );
};
