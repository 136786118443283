import { Button, Modal, useModal } from "../../../../../components";
// import { ReactComponent as DepositIcon } from "../../../../../assets/icons/deposit-icon.svg";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "../../../../../components/LabeledInput/LabeledInput";
import { toast } from "react-toastify";
import { PaymentsServices } from "../../../../../services/apis/Payments/PaymentsServices";
import { isNumeric } from "../../../../../utils/helpers";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useState } from "react";
import { PaymentsInfoModal } from "./PaymentsInfo";

export const DepositModal = ({
  refetchBalance,
}: {
  refetchBalance?: () => void;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <Deposit closeModal={closeModal} refetchBalance={refetchBalance} />
  );
  const { modalControl } = useModal({
    title: "Deposit",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <PaymentsInfoModal />

      {/* Disable until payment provider will be connected */}

      {/* <Button
        leftIcon={<DepositIcon />}
        mode="secondary"
        onClick={openModal}
        className="rounded-[8px] w-full px-4 text-nowrap md:w-[120px]"
      >
        Deposit
      </Button> */}
    </>
  );
};

const Deposit = ({
  closeModal,
  refetchBalance,
}: {
  closeModal?: () => void;
  refetchBalance?: () => void;
}) => {
  const [date, setDate] = useState<any>(null);
  const formOptions = useForm({
    defaultValues: {
      amount: "",
      ccNumber: "",
    },
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = formOptions;

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      await PaymentsServices.makeDeposit({
        amount: data?.amount,
        ccNumber: data?.ccNumber,
        ccExp: moment(date).format("MMYY"),
      });
      toast.success("Deposit was successful");
      refetchBalance?.();
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      closeModal?.();
    }
  });
  return (
    <>
      <div className="w-full flex flex-col justify-center items-center mt-[32px]">
        <h2 className="font-absoluteEmpire text-[32px] leading-none">
          Make a deposit
        </h2>
        <p className="text-text-subGrey font-gilroyMedium mt-1">
          Specify the amount and card with which you want to make a deposit
        </p>
        <p className="text-text-subGrey font-gilroyMedium mt-1">
          All deposits must be wagered at least once before withdrawal to
          prevent money laundering. All prizes above $600 will be reported to
          the IRS.
        </p>
      </div>
      <Controller
        control={control}
        name="amount"
        render={({ field }) => (
          <LabeledInput
            {...field}
            onChange={(e) => {
              if (isNumeric(e?.target?.value) || e?.target?.value === "") {
                // @ts-ignore
                setValue("amount", Number(e?.target?.value), {
                  shouldValidate: true,
                });
              }
            }}
            placeholder="Specify the amount you want to deposit..."
            error={errors?.amount?.message}
            errorClassName="text-text-red"
            label="Total"
            maxLength={6}
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-[28px]"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
        rules={{
          required: {
            value: true,
            message: "<p>This is a wrong amount</p>",
          },
        }}
      />
      <Controller
        control={control}
        name="ccNumber"
        render={({ field }) => (
          <LabeledInput
            {...field}
            onChange={(e) => {
              if (isNumeric(e?.target?.value) || e?.target?.value === "") {
                // @ts-ignore
                setValue("ccNumber", Number(e?.target?.value), {
                  shouldValidate: true,
                });
              }
            }}
            placeholder="Enter your card number..."
            error={errors?.ccNumber?.message}
            errorClassName="text-text-red"
            minLength={16}
            maxLength={16}
            label="Card Number"
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative my-4"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
        rules={{
          required: {
            value: true,
            message: "<p>This is a wrong card number</p>",
          },
        }}
      />
      <DatePicker
        showPopperArrow={false}
        selected={date}
        minDate={moment()?.toDate()}
        showPreviousMonths={false}
        onChange={(date: any) => setDate(date)}
        showMonthYearPicker
        dateFormat="MM/yy"
        customInput={
          <div className="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7 cursor-pointer ">
            <p className="absolute top-[12px] left-[16px] text-text-subGrey text-xs font-gilroyMedium">
              Expiration Date
            </p>
            <p className="text-text-grey text-base font-gilroyMedium text-left sm:text-nowrap">
              {date ? moment(date).format("MM/YY") : "Enter Expiration Date..."}
            </p>
          </div>
        }
      />
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
        <Button
          disabled={!isValid || !date}
          onClick={onSubmit}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Deposit
        </Button>
      </div>
    </>
  );
};

export default Deposit;
