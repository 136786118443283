import useAuthStore from "../../../stores/auth/auth";
import axios from "axios";

export const PaymentsServices = {
  makeDeposit: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/payment/deposit`;
    try {
      const makeDeposit = await axios.post(endPoint, payload, config);
      return makeDeposit?.data;
    } catch (error) {
      throw error;
    }
  },
  getUserTransactions: async (userId: string, params?: any) => {
    const endPoint = `/api/payment/transactions/${userId}`;
    try {
      const transactions = await axios.get(endPoint, { params });
      return transactions?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  sendWithdrawalRequest: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/payment/withdrawal/request`;
    try {
      const withdrawalRequest = await axios.post(endPoint, payload, config);
      return withdrawalRequest?.data;
    } catch (error) {
      throw error;
    }
  },
  createPlaidLinkToken: async (payload: any) => {
    const endPoint = `/api/payment/create_link_token`;
    try {
      const linkToken = await axios.post(endPoint, payload);
      return linkToken?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  setPlaidPublicToken: async (payload: any) => {
    const endPoint = `/api/payment/exchange_public_token`;
    try {
      const publicToken = await axios.post(endPoint, payload);
      return publicToken?.data;
    } catch (error) {
      throw error;
    }
  },
};
