import league_logo from "../../assets/images/ball-logo.png";
import clsx from "clsx";
import { useNavigate } from "react-router";
import { ReactComponent as Info } from "../../assets/icons/info-icon.svg";
import { Popup } from "../Popup/Popup";
import { isValidImg, numberWithCommas } from "../../utils/helpers";

interface MobileLeaguesProps {
  leagues: { [key: string]: any }[];
}
const MobileLeagues = ({ leagues }: MobileLeaguesProps) => {
  const navigate = useNavigate();

  return (
    <>
      {leagues?.length > 0 ? (
        leagues?.map((data: { [key: string]: any }, index: number) => (
          <div
            key={index}
            className=" bg-card-bgGreyMedium gap-4 rounded-[16px] p-6 flex flex-col"
          >
            <div className="flex justify-between">
              <img
                className="rounded-full"
                width={40}
                height={40}
                src={isValidImg(data.logoUrl) || league_logo}
                alt="League logo"
              />
              <div>
                <p className="font-gilroyBold text-base">{data?.name || ""}</p>
              </div>
              <div
                onClick={() => navigate(`/leagues/league/${data?.id}`)}
                className={`flex flex-col cursor-pointer`}
              >
                <p className="underline text-nowrap">View League</p>
                <p className="text-sm text-text-subGrey">View Details</p>
              </div>
            </div>
            <div className="bg-card-strokeGradient-2 h-[2px]"></div>
            <div className="flex justify-between">
              <div>
                <p className="font-gilroyBold text-base">${data?.entryFee}</p>
                <p className="text-sm text-text-subGrey">Entry Fee</p>
              </div>
              <div>
                <p className="font-gilroyBold text-base">
                  {data?.team
                    ? data?.team?.length + "/" + data?.numberOfTeams
                    : data?.numberOfTeams + " Teams"}
                </p>
                <p className="text-sm text-text-subGrey">
                  {data?.teamsEliminatedPerWeek + " Eliminated Weekly"}
                </p>
              </div>
              <div>
                <p className="font-gilroyBold text-base">
                  {data?.eliminatedTeams ?? 0}
                </p>
                <p className="text-sm text-text-subGrey">Eliminated</p>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <div className={`flex flex-col`}>
                  <p className="font-gilroyBold text-base">
                    $
                    {numberWithCommas(
                      (
                        Number(data?.prizeFundFirstPlace) +
                        Number(data?.prizeFundSecondPlace)
                      )?.toFixed(2)
                    )}
                  </p>
                  <p className="text-sm text-text-subGrey">Prize Fund</p>
                </div>
                <Popup
                  icon={<Info />}
                  data={
                    <p className="text-base text-left whitespace-nowrap">
                      1st Place Prize $
                      {numberWithCommas(
                        Number(data?.prizeFundFirstPlace)?.toFixed(2)
                      )}{" "}
                      <br />
                      2nd Place Prize $
                      {numberWithCommas(
                        Number(data?.prizeFundSecondPlace)?.toFixed(2)
                      )}
                    </p>
                  }
                />
              </div>
              <div className={`flex items-center justify-between`}>
                <div
                  className={clsx(
                    "text-base px-4 py-1 rounded-[16px] bg-gradient-to-br capitalize text-nowrap",
                    data?.status === "registration" &&
                      "from-elements-chipGradient-1 to-elements-chipGradient-2",
                    data?.status === "inProgress" &&
                      "from-red-bgGradient2-1 to-red-bgGradient2-2",
                    data?.status === "completed" &&
                      "bg-white text-screenBgGrey",
                    data?.status === "archived" && "bg-white text-screenBgGrey"
                  )}
                >
                  {data?.status || ""}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className=" bg-card-bgGreyMedium gap-4 rounded-[16px] p-6 flex flex-col">
          <p className="text-base text-text-subGrey">No data available</p>
        </div>
      )}
    </>
  );
};

export default MobileLeagues;
