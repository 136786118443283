import { useNavigate, useParams } from "react-router-dom";
import { useFetchTeamDetails } from "../../../../queries/teamsQueries/teamsQueries";
import league_logo from "../../../../assets/images/ball-logo.png";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import PlayerDetailsModal from "../components/PlayerDetails";
import { Button } from "../../../../components";
import {
  useFetchAllInjuredPlayers,
  useFetchTeamPlayers,
  useFetchTeamPlayersPRJ,
  useRemovePlayer,
} from "../../../../queries/playerQueries/playerQueries";
import { toast } from "react-toastify";
import useAuthStore from "../../../../stores/auth/auth";
import {
  isValidImg,
  getCurrentWeek,
  formatDateTimeInTimeZone,
} from "../../../../utils/helpers";
import {
  useFetchAllGames,
  useFetchCurrentWeek,
  useFetchLeague,
} from "../../../../queries/leaguesQueries/leaguesQueries";
import useLoadPlayers from "../../../../pages/private/Draft/hooks/useLoadPlayers";
import DropInfoModal from "../components/DropInfoModal";
import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import clsx from "clsx";
import { useFetchTeamUserDetails } from "../../../../queries/userQueries/userQueries";
import RemovePlayerModal from "../components/RemovePlayerByAdmin";
import InjuriesHistoryModal from "../components/InjuriesHistory";

const NameRender = ({ ...data }) => {
  const player = data?.teamData?.player?.find(
    (item: any) => item?.playerId === data?.player?.id
  );
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div className={`flex items-center min-w-[130px]`}>
        <img
          src={isValidImg(player?.avatar || data?.avatar) || demo_team_logo}
          alt="League logo"
          className="w-[32px] h-[32px] rounded-[20px]"
        />
        <div className="flex flex-row flex-wrap items-center w-full ml-2 font-gilroyMedium">
          <p className="mr-1">{data?.player?.firstName}</p>
          <p className="whitespace-nowrap">
            {data?.player?.lastName} {data?.name}
            {data?.injury && data?.isCurrentWeek ? (
              <InjuriesHistoryModal injury={data?.injury} />
            ) : (
              ""
            )}
          </p>
        </div>
      </div>
    </PlayerDetailsModal>
  );
};

const PositionRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div className="font-gilroyMedium whitespace-nowrap">
        {data?.player?.position || data?.position || ""}
      </div>
    </PlayerDetailsModal>
  );
};

const TeamRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div className={`flex items-center`}>
        <img
          src={
            data?.officialImageSrc
              ? isValidImg(data?.officialImageSrc)
                ? data?.officialImageSrc
                : league_logo
              : league_logo
          }
          alt="League logo"
          className="w-[16px] h-[16px] rounded-[20px]"
        />
        <div className="flex flex-col ml-2 font-gilroyMedium whitespace-nowrap">
          {data?.team?.abbreviation || data?.nflTeam || "-"}
        </div>
      </div>
    </PlayerDetailsModal>
  );
};

const PointsRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div className="font-gilroyMedium whitespace-nowrap">
        {data?.fantasyPointsThisWeek?.toFixed(2) ??
          (data?.playerTeamPlayedThisWeek ? "0.00" : "-")}
        {/* @TODO Discuss with Yurii: Why would we calculate fantasy points on the frontend? */}
        {/*{data?.stats && data?.isRealStats*/}
        {/*  ? data?.isPPR*/}
        {/*    ? updatePlayerScore(data?.stats)?.toFixed(2)*/}
        {/*    : updatePlayerScoreNonPpr(data?.stats)?.toFixed(2)*/}
        {/*  : data?.score ?? "-"}*/}
      </div>
    </PlayerDetailsModal>
  );
};
const PRJPointsRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          "!text-text-greyDark"
        )}
      >
        {data?.projectedFantasyPointsThisWeek?.toFixed(2) ?? "0"}
        {/* @TODO Discuss with Yurii: Why would we calculate fantasy points on the frontend? */}
        {/*{data?.projectedStats && !data?.isPastWeek*/}
        {/*  ? data?.isPPR*/}
        {/*    ? updatePlayerScore(data?.projectedStats)?.toFixed(2)*/}
        {/*    : updatePlayerScoreNonPpr(data?.projectedStats)?.toFixed(2)*/}
        {/*  : data?.projectedPoints ?? "-"}*/}
      </div>
    </PlayerDetailsModal>
  );
};
const PassATTRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  const playerTeamPlayedThisWeek = data?.playerTeamPlayedThisWeek;
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && !playerTeamPlayedThisWeek && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.passing?.passCompletions
          : playerTeamPlayedThisWeek
          ? "0"
          : data?.projectedStats?.passing?.passCompletions ?? "?"}
        /
        {isRealStats
          ? data?.stats?.passing?.passAttempts
          : playerTeamPlayedThisWeek
          ? "0"
          : data?.projectedStats?.passing?.passAttempts ?? "?"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.passingCompletions*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.passing?.passCompletions*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.passing?.passCompletions*/}
        {/*      : "-") ?? "-"}*/}
        {/*/*/}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.passingAttempts*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.passing?.passAttempts*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.passing?.passAttempts*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const PassYDSRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  const playerTeamPlayedThisWeek = data?.playerTeamPlayedThisWeek;
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && !playerTeamPlayedThisWeek && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.passing?.passYards
          : playerTeamPlayedThisWeek
          ? "0"
          : data?.projectedStats?.passing?.passYards ?? "?"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.passingYards*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.passing?.passYards*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.passing?.passYards*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const PassTDRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  const playerTeamPlayedThisWeek = data?.playerTeamPlayedThisWeek;
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && !playerTeamPlayedThisWeek && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.passing?.passTD
          : playerTeamPlayedThisWeek
          ? "0"
          : data?.projectedStats?.passing?.passTD ?? "?"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.passingTds*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.passing?.passTD*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.passing?.passTD*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const PassINTRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  const playerTeamPlayedThisWeek = data?.playerTeamPlayedThisWeek;
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && !playerTeamPlayedThisWeek && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.passing?.passInt
          : playerTeamPlayedThisWeek
          ? "0"
          : data?.projectedStats?.passing?.passInt ?? "?"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.passingInterceptions*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.passing?.passInt*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.passing?.passInt*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const RushCARRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  const playerTeamPlayedThisWeek = data?.playerTeamPlayedThisWeek;
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && !playerTeamPlayedThisWeek && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.rushing?.rushAttempts
          : playerTeamPlayedThisWeek
          ? "0"
          : data?.projectedStats?.rushing?.rushAttempts ?? "?"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.rushingAttempts*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.rushing?.rushAttempts*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.rushing?.rushAttempts*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const RushYDSRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  const playerTeamPlayedThisWeek = data?.playerTeamPlayedThisWeek;
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && !playerTeamPlayedThisWeek && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.rushing?.rushYards
          : playerTeamPlayedThisWeek
          ? "0"
          : data?.projectedStats?.rushing?.rushYards ?? "?"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.rushingYards*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.rushing?.rushYards*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.rushing?.rushYards*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const RushTDRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  const playerTeamPlayedThisWeek = data?.playerTeamPlayedThisWeek;
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && !playerTeamPlayedThisWeek && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.rushing?.rushTD
          : playerTeamPlayedThisWeek
          ? "0"
          : data?.projectedStats?.rushing?.rushTD ?? "?"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.rushingTds*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.rushing?.rushTD*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.rushing?.rushTD*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const RecRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  const playerTeamPlayedThisWeek = data?.playerTeamPlayedThisWeek;
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && !playerTeamPlayedThisWeek && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.receiving?.receptions
          : playerTeamPlayedThisWeek
          ? "0"
          : data?.projectedStats?.receiving?.receptions ?? "?"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.receivingReceptions*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.receiving?.receptions*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.receiving?.receptions*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const RecYDSRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  const playerTeamPlayedThisWeek = data?.playerTeamPlayedThisWeek;
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && !playerTeamPlayedThisWeek && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.receiving?.recYards
          : playerTeamPlayedThisWeek
          ? "0"
          : data?.projectedStats?.receiving?.recYards ?? "?"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.receivingYards*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.receiving?.recYards*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.receiving?.recYards*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const RecTDRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  const playerTeamPlayedThisWeek = data?.playerTeamPlayedThisWeek;
  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && !playerTeamPlayedThisWeek && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.receiving?.recTD
          : playerTeamPlayedThisWeek
          ? "0"
          : data?.projectedStats?.receiving?.recTD ?? "?"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.receivingTds*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.receiving?.recTD*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.receiving?.recTD*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};

// const StatusRender = ({ ...data }) => {
//   const player = data?.teamData?.player?.find(
//     (item: any) => item?.playerId === data?.player?.id
//   );

//   return (
//     <PlayerDetailsModal
// //       id={data?.player?.id || data?.playerId}
//       leagueId={data?.leagueId}
//     >
//       <div className="font-gilroyMedium lowercase">
//         {player?.status || data?.status || "-"}
//       </div>
//     </PlayerDetailsModal>
//   );
// };

const ActionsRender = ({ ...data }) => {
  const limitedPosition = data?.teamData?.player?.filter(
    (item: any) => item?.position === data?.player?.primaryPosition
  );
  return (
    <>
      {data?.isMyTeam && data?.draft?.status === "completed" ? (
        limitedPosition?.length === 1 ? (
          <DropInfoModal />
        ) : (
          <Button
            className="h-[41px] w-[65px] rounded-[8px]"
            leftIcon={
              data?.isLoading ? (
                <Oval
                  visible={data?.isLoading}
                  height="20"
                  width="20"
                  color="#000000"
                  secondaryColor="#ffffff"
                />
              ) : null
            }
            disabled={data?.isLoading || !data?.canDropPlayer}
            onClick={() => data?.dropPlayer(data?.player?.id || data?.playerId)}
            mode="secondary"
          >
            Drop
          </Button>
        )
      ) : data?.isAdmin ? (
        <RemovePlayerModal
          teamId={data?.teamId}
          playerId={data?.player?.id || data?.playerId}
          week={data?.currentWeek}
        />
      ) : null}
    </>
  );
};

const OpponentRender = ({ ...data }) => {
  let opponent = "";
  let isHomeGame = "";
  if (data?.game?.awayTeamAbbreviation) {
    opponent =
      data?.game?.awayTeamAbbreviation === data?.team?.abbreviation
        ? data?.game?.homeTeamAbbreviation
        : data?.game?.awayTeamAbbreviation;
    isHomeGame =
      data?.game?.homeTeamAbbreviation !== data?.team?.abbreviation ? "@" : "";
  } else {
    const player = data?.uniquePlayers?.find(
      (item: any) => item?.player?.id === data?.playerId
    );
    opponent =
      player?.game?.awayTeamAbbreviation === player?.team?.abbreviation
        ? player?.game?.homeTeamAbbreviation
        : player?.game?.awayTeamAbbreviation;
    isHomeGame =
      player?.game?.homeTeamAbbreviation !== player?.team?.abbreviation
        ? "@"
        : "";
  }

  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div className="font-gilroyMedium whitespace-nowrap ml-1">
        {isHomeGame}
        {opponent || "BYE"}
      </div>
    </PlayerDetailsModal>
  );
};

const GameTimeRender = ({ ...data }) => {
  let opponent = "";
  let player: any;
  let myTeam;
  if (data?.game?.awayTeamAbbreviation) {
    opponent =
      data?.game?.awayTeamAbbreviation === data?.team?.abbreviation
        ? data?.game?.homeTeamAbbreviation
        : data?.game?.awayTeamAbbreviation;
    myTeam =
      data?.game?.awayTeamAbbreviation !== data?.team?.abbreviation
        ? data?.game?.homeTeamAbbreviation
        : data?.game?.awayTeamAbbreviation;
  } else {
    player = data?.uniquePlayers?.find(
      (item: any) => item?.player?.id === data?.playerId
    );
    opponent =
      player?.game?.awayTeamAbbreviation === player?.team?.abbreviation
        ? player?.game?.homeTeamAbbreviation
        : player?.game?.awayTeamAbbreviation;
    myTeam =
      player?.game?.awayTeamAbbreviation !== player?.team?.abbreviation
        ? player?.game?.homeTeamAbbreviation
        : player?.game?.awayTeamAbbreviation;
  }
  const game = data?.games?.find(
    (item: any) =>
      (item?.schedule?.awayTeam?.abbreviation === opponent &&
        item?.schedule?.homeTeam?.abbreviation === myTeam) ||
      (item?.schedule?.awayTeam?.abbreviation === myTeam &&
        item?.schedule?.homeTeam?.abbreviation === opponent)
  );

  const isGameNowOrPast =
    Date.now()?.valueOf() > new Date(game?.schedule?.startTime)?.valueOf();

  return (
    <PlayerDetailsModal
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div className="font-gilroyMedium whitespace-nowrap ml-1">
        {isGameNowOrPast ? (
          <p>
            {game?.schedule?.homeTeam?.abbreviation} (
            {game?.score?.homeScoreTotal || "-"}) -{" "}
            {game?.schedule?.awayTeam?.abbreviation} (
            {game?.score?.awayScoreTotal || "-"})
          </p>
        ) : (
          <>
            <p>
              {game?.schedule?.startTime
                ? formatDateTimeInTimeZone(game?.schedule?.startTime, "MM/DD")
                : ""}
            </p>
            <p>
              {game?.schedule?.startTime
                ? `${formatDateTimeInTimeZone(
                    game?.schedule?.startTime,
                    "hh:mm"
                  )} PT`
                : "-"}
            </p>
          </>
        )}
      </div>
    </PlayerDetailsModal>
  );
};

const useTeamHook = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userData } = useAuthStore.getState();
  const { data: currentWeek } = useFetchCurrentWeek();
  const [week, setWeek] = useState<any>(getCurrentWeek());
  const {
    data: { team, remainingPlayers } = {},
    isFetching,
    refetch,
  } = useFetchTeamDetails(id!, { week: week });
  const { data: user } = useFetchTeamUserDetails(team?.userId);
  const { mutateAsync } = useRemovePlayer();
  const { data: league, isFetching: isFetchingLeague } = useFetchLeague(
    team?.leagueId!,
    {
      week: week ? week : currentWeek,
    }
  );
  const {} = useLoadPlayers(team?.leagueId!, false);
  const [loading, setLoading] = useState(false);
  const { data: injuries } = useFetchAllInjuredPlayers();
  const [allGames, setAllGames] = useState<any>([]);
  const { data: games } = useFetchAllGames(allGames?.length ? false : true);

  useEffect(() => {
    if (games) {
      setAllGames(games);
    }
  }, []);

  const sortedTeams = league?.league?.team?.sort(
    (a: any, b: any) => Number(b?.latestScore) - Number(a?.latestScore)
  );
  const currentPosition =
    sortedTeams?.findIndex((item: any) => item?.id === id) + 1;
  const teamsWithPoints = sortedTeams?.filter(
    (item: any) => Number(item?.latestScore) > 0
  );
  const lastTeamPosition = teamsWithPoints?.length;

  const totalFP = team?.teamScore
    ?.sort((a: any, b: any) => Number(b?.score) - Number(a?.score))
    ?.find((item: any) => (item?.week === week ? week : currentWeek))?.score;

  const weekSlots = currentWeek
    ? [...Array(Math.ceil(currentWeek)).keys()]
    : [];
  const prjSlots = currentWeek
    ? [...Array(18 - Math.ceil(currentWeek)).keys()]
    : [];

  const { data: players, isFetching: isFetchingPlayers } = useFetchTeamPlayers(
    {
      names: team?.player?.map((item: any) => {
        const firstName = item?.name?.split(" ")?.[0];
        const lastName = item?.name
          ?.split(" ")
          ?.splice(1, item?.name?.split(" ")?.length - 1)
          ?.join(" ");
        return { firstName: firstName, lastName: lastName };
      }),
    },
    week ? week : currentWeek,
    week > currentWeek
  );
  const { data: playersPRJ, isFetching: isFetchingPlayersPRJ } =
    useFetchTeamPlayersPRJ(
      {
        names: team?.player?.map((item: any) => {
          const firstName = item?.name?.split(" ")?.[0];
          const lastName = item?.name
            ?.split(" ")
            ?.splice(1, item?.name?.split(" ")?.length - 1)
            ?.join(" ");
          return { firstName: firstName, lastName: lastName };
        }),
      },
      week ? week : currentWeek,
      true
      // !week || week <= currentWeek
    );

  const playersAll = players ? Object.values(players) : [];
  const playersAllPRJ = playersPRJ ? Object.values(playersPRJ) : [];

  const handleUniquePlayers = () => {
    const combinedPlayers = [...playersAllPRJ, ...playersAll];
    const playersWithStats = Array.from(
      new Map(
        combinedPlayers?.map((item: any) => [item?.player?.id, item])
      )?.values()
    );

    const existingIds = new Set(
      playersWithStats?.map((item) => item?.player?.id)
    );
    const missingPlayers =
      team?.player?.filter(
        (player: any) => !existingIds?.has(player?.playerId)
      ) || [];
    if (loading && !missingPlayers.length && !playersWithStats.length) {
      setLoading(false);
    }
    return [...playersWithStats, ...missingPlayers];
  };

  const uniquePlayers = handleUniquePlayers();

  const currentTeamsNumber = league?.league?.team?.filter(
    (item: any) => item?.eliminatedAt === 0 || item?.eliminatedAt >= week
  )?.length;

  const orderPlayers = (players: any) => {
    const responseData = injuries?.players ? injuries?.players : [];

    let allPlayers = players?.reduce((accumulator: any, currentPlayer: any) => {
      const injury = responseData?.find(
        (item: any) =>
          item?.id === currentPlayer?.player?.id ||
          item?.id === currentPlayer?.playerId
      );
      if (injury) {
        currentPlayer.injury = injury;
      } else {
        currentPlayer.injury = false;
      }
      return [...accumulator, currentPlayer];
    }, []);

    const orderedPlayers = [];
    const positionPriority = ["QB", "RB", "WR", "TE"];
    const positionMap: any = { QB: null, RB: null, WR: null, TE: null };

    positionPriority?.forEach((pos) => {
      const player = allPlayers?.find(
        (item: any) => item?.player?.position === pos || item?.position === pos
      );
      if (player) {
        orderedPlayers?.push(player);
        positionMap[pos] = player;
        allPlayers = allPlayers?.filter(
          (item: any) =>
            item?.player?.id !== player?.player?.id ||
            item?.playerId !== player?.playerId
        );
      }
    });

    orderedPlayers.push(...allPlayers);
    if (loading) {
      setLoading(false);
    }

    return orderedPlayers;
  };

  const dropPlayer = async (playerId: string) => {
    try {
      await mutateAsync({ teamId: id, playerId: playerId });
      refetch();
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };
  const isMyTeam =
    userData?.id === team?.userId && team?.status === "Confirmed";
  const isAdmin = userData?.role === "admin";

  const columns = [
    {
      title: "Player Name",
      dataIndex: "name",
      render: (_: string, record: any) => {
        return (
          <NameRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            teamData={team}
            teamId={id}
            isCurrentWeek={week === currentWeek || !week}
          />
        );
      },
      sort: true,
    },
    {
      title: "Pos",
      dataIndex: "position",
      render: (_: string, record: any) => {
        return (
          <PositionRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            teamId={id}
          />
        );
      },
      sort: true,
    },
    {
      title: "Team",
      dataIndex: "team",
      render: (_: string, record: any) => {
        return (
          <TeamRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            teamId={id}
            uniquePlayers={playersAllPRJ}
          />
        );
      },
      sort: true,
    },
    {
      title: "OPP",
      dataIndex: "opp",
      render: (_: string, record: any) => {
        return (
          <OpponentRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            teamId={id}
            uniquePlayers={playersAllPRJ}
          />
        );
      },
      sort: true,
    },
    {
      title: "Game Time",
      dataIndex: "gameTime",
      render: (_: string, record: any) => {
        return (
          <GameTimeRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            teamId={id}
            uniquePlayers={playersAllPRJ}
            games={games?.games?.filter(
              (item: any) =>
                item?.schedule?.week === week ||
                item?.schedule?.week === currentWeek
            )}
          />
        );
      },
      sort: true,
    },
    {
      title: "Fantasy Points",
      dataIndex: "points",
      render: (_: string, record: any) => {
        return (
          <PointsRender
            // isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            // isPPR={league?.league?.receivingOption === "PPR"}
            // isRealStats={week <= currentWeek}
            teamId={id}
          />
        );
      },
      sort: true,
    },
    {
      title: "Proj",
      dataIndex: "prj",
      render: (_: string, record: any) => {
        return (
          <PRJPointsRender
            // isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            // isPPR={league?.league?.receivingOption === "PPR"}
            // isRealStats={week <= currentWeek}
            // isCurrentWeek={isCurrentWeek}
            // isPastWeek={isPastWeek}
            teamId={id}
            // isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">CMP/ATT</p>
      ),
      dataIndex: "passATT",
      render: (_: string, record: any) => {
        return (
          <PassATTRender
            // isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            // isRealStats={week <= currentWeek}
            // isCurrentWeek={isCurrentWeek}
            // isPastWeek={isPastWeek}
            teamId={id}
            // isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "passYDS",
      render: (_: string, record: any) => {
        return (
          <PassYDSRender
            // isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            // isRealStats={week <= currentWeek}
            // isCurrentWeek={isCurrentWeek}
            // isPastWeek={isPastWeek}
            teamId={id}
            // isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: (
        <div className="relative">
          <p className="text-[12px] font-gilroyBold text-text-grey top-[-12px] -translate-x-[100%] absolute">
            Passing
          </p>
          <p className="text-base font-gilroyMedium text-text-grey">TDs</p>
        </div>
      ),
      dataIndex: "passTD",
      render: (_: string, record: any) => {
        return (
          <PassTDRender
            // isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            // isRealStats={week <= currentWeek}
            // isCurrentWeek={isCurrentWeek}
            // isPastWeek={isPastWeek}
            teamId={id}
            // isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey mr-4">INTs</p>
      ),
      dataIndex: "passINT",
      render: (_: string, record: any) => {
        return (
          <PassINTRender
            // isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            // isRealStats={week <= currentWeek}
            // isCurrentWeek={isCurrentWeek}
            // isPastWeek={isPastWeek}
            teamId={id}
            // isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">CAR</p>,
      dataIndex: "rushCAR",
      render: (_: string, record: any) => {
        return (
          <RushCARRender
            // isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            // isRealStats={week <= currentWeek}
            // isCurrentWeek={isCurrentWeek}
            // isPastWeek={isPastWeek}
            teamId={id}
            // isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "rushYDS",
      render: (_: string, record: any) => {
        return (
          <RushYDSRender
            // isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            // isRealStats={week <= currentWeek}
            // isCurrentWeek={isCurrentWeek}
            // isPastWeek={isPastWeek}
            teamId={id}
            // isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: (
        <div className="relative mr-4">
          <p className="text-[12px] font-gilroyBold text-text-grey top-[-12px] -translate-x-[110%] absolute">
            Rushing
          </p>
          <p className="text-base font-gilroyMedium text-text-grey">TDs</p>
        </div>
      ),
      dataIndex: "rushTD",
      render: (_: string, record: any) => {
        return (
          <RushTDRender
            // isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            // isRealStats={week <= currentWeek}
            // isCurrentWeek={isCurrentWeek}
            // isPastWeek={isPastWeek}
            teamId={id}
            // isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">Rec</p>,
      dataIndex: "rec",
      render: (_: string, record: any) => {
        return (
          <RecRender
            // isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            // isRealStats={week <= currentWeek}
            // isCurrentWeek={isCurrentWeek}
            // isPastWeek={isPastWeek}
            teamId={id}
            // isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: (
        <div className="relative">
          <p className="text-[12px] font-gilroyBold text-text-grey top-[-12px] -translate-x-[20%] absolute">
            Receiving
          </p>
          <p className="text-base font-gilroyMedium text-text-grey">YDS</p>
        </div>
      ),
      dataIndex: "recYDS",
      render: (_: string, record: any) => {
        return (
          <RecYDSRender
            // isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            // isRealStats={week <= currentWeek}
            // isCurrentWeek={isCurrentWeek}
            // isPastWeek={isPastWeek}
            teamId={id}
            // isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">TDs</p>,
      dataIndex: "recTD",
      render: (_: string, record: any) => {
        return (
          <RecTDRender
            // isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            // isRealStats={week <= currentWeek}
            // isCurrentWeek={isCurrentWeek}
            // isPastWeek={isPastWeek}
            teamId={id}
            // isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: (_: string, record: any) => {
    //     return (
    //       <StatusRender
    //         isMyTeam={isMyTeam}
    //         {...record}
    //         leagueId={team?.leagueId}
    //         teamData={team}
    //         teamId={id}
    //       />
    //     );
    //   },
    //   sort: true,
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: string, record: any) => {
        return (
          <ActionsRender
            teamData={team}
            isMyTeam={isMyTeam}
            dropPlayer={dropPlayer}
            draft={league?.league?.draft?.[0]}
            isLoading={isFetching || isFetchingPlayers}
            {...record}
            leagueId={team?.leagueId}
            teamId={id}
            isAdmin={isAdmin}
            canDropPlayer={!week || week >= currentWeek}
            currentWeek={currentWeek}
          />
        );
      },
      sort: true,
    },
  ];

  return {
    isFetching,
    team,
    userData,
    columns,
    isMyTeam,
    isAdmin,
    league,
    navigate,
    week,
    setWeek,
    weekSlots,
    players,
    isFetchingPlayers,
    refetch,
    prjSlots,
    currentWeek,
    uniquePlayers,
    isFetchingPlayersPRJ,
    user,
    orderPlayers,
    currentPosition,
    lastTeamPosition,
    totalFP,
    playersAll,
    playersAllPRJ,
    isFetchingLeague,
    loading,
    setLoading,
    remainingPlayers,
    games,
    currentTeamsNumber,
  };
};

export default useTeamHook;
