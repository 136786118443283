import scoringRules from "../configs/scoringRules";
import { PhoneNumberUtil } from "google-libphonenumber";
import moment from "moment-timezone";

export const containsUppercase = (str: string) => {
  return /[A-Z]/.test(str);
};

export const containsNumber = (str: string) => {
  return /\d/.test(str);
};

export const emailValidation =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const phoneValidation = (phone: string): boolean => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    return phoneUtil.isPossibleNumber(
      phoneUtil.parseAndKeepRawInput(phone, "US")
    );
  } catch (error) {
    return false;
  }
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
};

export const isFloat = (n: any) => {
  return n === +n && n !== (n | 0);
};

export const isInteger = (n: any) => {
  return n === +n && n === (n | 0);
};

export const isOdd = (num: number) => {
  return num % 2;
};

export const groupMessagesByDay = (messages: any) => {
  const messagesOrdered = messages?.sort((a: any, b: any) =>
    a?.createdAt > b?.createdAt ? 1 : b?.createdAt > a?.createdAt ? -1 : 0
  );
  const groups: any = {};
  messagesOrdered?.forEach((message: any) => {
    const messageDate = moment(message?.createdAt).format("YYYY-MM-DD");
    let group = groups[messageDate];
    if (!group) {
      groups[messageDate] = [message];
    } else {
      group.push(message);
    }
  });
  return groups;
};

export const formatAsFriendlyPastDate = (date: Date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  if (diff < 1000 * 60 * 60 * 24) {
    // less than a day
    // format as time ago e.g. '2 hours ago'
    return moment(date).fromNow();
  } else if (diff < 1000 * 60 * 60 * 24 * 7) {
    // less than a week
    // format as day of week e.g. 'Monday', 'Tuesday'
    return moment(date).format("dddd");
  } else {
    // more than a week
    // format as date e.g. '12 May'
    return moment(date).format("MMM D");
  }
};

export const isNumeric = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const isValidImg = (urlTocheck = "", defaultValue = false) => {
  let image = new Image();
  image.src = urlTocheck;
  if (image?.width == 0) {
    return defaultValue;
  } else {
    return urlTocheck;
  }
};

export const numberWithCommas = (x: number | string) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatDateTimeInTimeZone = (
  dateTime: Date,
  format = "LL",
  timezone = "America/Los_Angeles"
) => {
  return moment(dateTime).tz(timezone).format(format);
};

export const getTimezoneAbbr = () => {
  return moment().tz("America/Los_Angeles").zoneAbbr();
};

export const updatePlayerScore = (stats: any) => {
  if (!stats) {
    return 0;
  }
  if (
    stats?.passing?.passYards == null ||
    stats?.passing?.passTD == null ||
    stats?.passing?.passInt == null ||
    stats?.rushing?.rushYards == null ||
    stats?.rushing?.rushTD == null ||
    stats?.receiving?.receptions == null ||
    stats?.receiving?.recYards == null ||
    stats?.receiving?.recTD == null ||
    stats?.twoPointAttempts?.twoPtPassMade == null ||
    stats?.twoPointAttempts?.twoPtRushMade == null ||
    stats?.twoPointAttempts?.twoPtPassRec == null
  ) {
    return 0;
  }

  let score = 0;

  score += stats?.passing?.passYards * scoringRules?.passingYard;
  score += stats?.passing?.passTD * scoringRules?.passingTD;
  score += stats?.passing?.passInt * scoringRules?.interception;
  score += stats?.rushing?.rushYards * scoringRules?.rushingYard;
  score += stats?.rushing?.rushTD * scoringRules?.rushingTD;
  score += stats?.receiving?.receptions * scoringRules?.reception;
  score += stats?.receiving?.recYards * scoringRules?.receivingYard;
  score += stats?.receiving?.recTD * scoringRules?.receivingTD;
  score += stats?.twoPointAttempts?.twoPtPassMade * scoringRules?.twoPointPass;
  score += stats?.twoPointAttempts?.twoPtRushMade * scoringRules?.twoPointRush;
  score +=
    stats?.twoPointAttempts?.twoPtPassRec * scoringRules?.twoPointReceive;

  return score;
};

export const updatePlayerScoreNonPpr = (stats: any) => {
  if (
    !stats?.passing?.passYards ||
    !stats?.passing?.passTD ||
    !stats?.passing?.passInt ||
    !stats?.rushing?.rushYards ||
    !stats?.rushing?.rushTD ||
    !stats?.receiving?.receptions ||
    !stats?.receiving?.recYards ||
    !stats?.receiving?.recTD ||
    !stats?.twoPointAttempts?.twoPtPassMade ||
    !stats?.twoPointAttempts?.twoPtRushMade ||
    !stats?.twoPointAttempts?.twoPtPassRec
  ) {
    return 0;
  }

  let score = 0;

  score += stats?.passing?.passYards * scoringRules?.passingYard;
  score += stats?.passing?.passTD * scoringRules?.passingTD;
  score += stats?.passing?.passInt * scoringRules?.interception;
  score += stats?.rushing?.rushYards * scoringRules?.rushingYard;
  score += stats?.rushing?.rushTD * scoringRules?.rushingTD;
  score += stats?.receiving?.recYards * scoringRules?.receivingYard;
  score += stats?.receiving?.recTD * scoringRules?.receivingTD;

  if (!stats?.twoPointAttempts) {
    return 0;
  }
  score += stats?.twoPointAttempts?.twoPtPassMade * scoringRules?.twoPointPass;
  score += stats?.twoPointAttempts?.twoPtRushMade * scoringRules?.twoPointRush;
  score +=
    stats?.twoPointAttempts?.twoPtPassRec * scoringRules?.twoPointReceive;

  return score;
};

export const getCurrentWeek = () =>
{
  const preseasonStart = moment.tz('2024-08-05', 'America/Los_Angeles').day(2);  // Start from the first Tuesday
  const regularSeasonStart = moment.tz('2024-09-01', 'America/Los_Angeles').day(2); // Adjust regular season start to the first Tuesday
  const currentDate = moment().tz('America/Los_Angeles', true);  // Current date without adjusting

  let currentWeek;

  if (currentDate.isBefore(preseasonStart)) {
    currentWeek = 0;
  } else if (currentDate.isBefore(regularSeasonStart)) {
    currentWeek = currentDate.diff(preseasonStart, 'weeks') + 1;
  } else {
    currentWeek = currentDate.diff(regularSeasonStart, 'weeks') + 1;
  }

  return currentWeek;
}
