import moment from "moment";
import useFilter from "../../../../hooks/useFilter";
import clsx from "clsx";
import { useEffect, useState } from "react";
import {
  useFetchLatestTransactions,
  useFetchTransactionsCount,
} from "../../../../queries/adminQueries/adminQueries";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import { useNavigate } from "react-router-dom";
import { AdminServices } from "../../../../services/apis/Admin/AdminServices.ts";
import { toast } from "react-toastify";

const DateRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row items-center`}>
      <div className="flex flex-col font-gilroyMedium">
        {moment(data?.createdAt).format("lll")}
      </div>
    </div>
  );
};

const TypeRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row items-center`}>
      <div className="flex flex-col font-gilroyMedium capitalize">
        {data?.type || "-"}
      </div>
    </div>
  );
};

const UserRender = ({ ...data }) => {
  return (
    <div
      className={`flex items-center`}
      onClick={() => data?.navigate(`/users/user-profile/${data?.userId}`)}
    >
      <img
        src={data?.user?.profileUrl || demo_team_logo}
        alt="Avatar"
        className="w-[32px] h-[32px] rounded-[50px] mr-2"
      />
      <div>
        <p className="font-gilroyMedium text-[14px] leading-none">
          {data?.user?.name}
        </p>
        <p className="text-text-subGrey text-xs font-gilroyMedium mt-1">
          {data?.user?.email}
        </p>
      </div>
    </div>
  );
};

const AmountRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row items-center`}>
      <p
        className={clsx(
          "text-text-red font-gilroyMedium",
          (data?.type === "deposit" ||
            data?.type?.toLocaleLowerCase()?.includes("win") ||
            data?.type === "deposit from admin" ||
            data?.type === "transfer from admin" ||
            data?.type === "credit from admin" ||
            data?.type === "refund") &&
            "!text-text-green"
        )}
      >
        {data?.type === "deposit" ||
        data?.type?.toLocaleLowerCase()?.includes("win") ||
        data?.type === "deposit from admin" ||
        data?.type === "transfer from admin" ||
        data?.type === "credit from admin" ||
        data?.type === "refund"
          ? "+"
          : "-"}
        ${data?.amount}
      </p>
    </div>
  );
};

const StatusRender = ({ ...data }) => {
  return (
    <div className={`flex items-center justify-between`}>
      <div
        className={clsx(
          "text-xs flex justify-center items-center font-gilroyBold px-3 py-1 rounded-[16px] bg-gradient-to-br capitalize text-nowrap",
          data?.status === "registration" &&
            "from-elements-chipGradient-1 to-elements-chipGradient-2",
          data?.status === "inProgress" &&
            "from-red-bgGradient2-1 to-red-bgGradient2-2",
          data?.status === "completed" && "bg-white text-screenBgGrey",
          data?.status === "archived" && "bg-white text-screenBgGrey"
        )}
      >
        {data?.status || ""}
      </div>
    </div>
  );
};

const useTransactionHistoryHook = () => {
  const navigate = useNavigate();
  const { filter, onChangeFilter } = useFilter({});
  const { data, isFetching } = useFetchLatestTransactions({
    page: filter?.page,
    limit: filter?.limit,
    type: filter?.type !== "All" ? filter?.type : null,
  });

  const { data: transactionCount } = useFetchTransactionsCount();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [transactions, setTransactions] = useState(data ?? []);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const onFilterValues = () => {
    const dataForFilter = [...data];
    const filteredTransactions = dataForFilter?.filter((item: any) => {
      if (fromDate !== null && toDate !== null) {
        return (
          new Date(item?.createdAt) >= new Date(fromDate) &&
          new Date(item?.createdAt) <= new Date(toDate)
        );
      } else return item;
    });

    setTransactions(filteredTransactions);
  };

  useEffect(() => {
    onFilterValues();
  }, [data, fromDate, toDate, filter?.type]);

  const filters = [
    {
      name: "Type",
      value: "type",
      filters: [
        "All",
        "Joining League",
        "Deposit",
        "Withdrawal",
        "Deposit From Admin",
        "Credit From Admin",
        "Refund",
      ],
    },
  ];

  const columns = [
    {
      title: "Date & Time",
      dataIndex: "date",
      render: (_: string, record: any) => {
        return <DateRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (_: string, record: any) => {
        return <TypeRender {...record} />;
      },
      sort: true,
    },
    {
      title: "User",
      dataIndex: "user",
      render: (_: string, record: any) => {
        return <UserRender {...record} navigate={navigate} />;
      },
      sort: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_: string, record: any) => {
        return <AmountRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: string, record: any) => {
        return <StatusRender {...record} />;
      },
      sort: true,
    },
  ];

  const downloadTransactionsTable = async () => {
    try {
      setIsExportLoading(true);
      const exportedTransactions = await AdminServices.getLatestTransactions({
        page: 1,
        limit: transactionCount?.data,
      });
      const exportTransactionsData = exportedTransactions.map(
        (transaction: any) => ({
          "Date & Time": moment(transaction?.createdAt).format("lll") || "",
          Type: transaction?.type || "",
          User:
            `${transaction?.user?.name} - ${transaction?.user?.email}` || "",
          Amount: `${
            transaction?.type === "deposit" ||
            transaction?.type?.toLocaleLowerCase()?.includes("win") ||
            transaction?.type === "deposit from admin" ||
            transaction?.type === "transfer from admin" ||
            transaction?.type === "credit from admin" ||
            transaction?.type === "refund"
              ? "+"
              : "-"
          }$${transaction?.amount}`,
          Status: transaction?.status || "",
        })
      );
      const titleKeys = Object.keys(exportTransactionsData?.[0]);
      const csvContent = [
        titleKeys.join(","),
        ...exportTransactionsData?.map((transcation: any) =>
          titleKeys?.map((key) => transcation[key]).join(",")
        ),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Transactions.csv";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      toast.error("Something went wrong with exporting transactions.");
    } finally {
      setIsExportLoading(false);
    }
  };

  return {
    columns,
    filter,
    onChangeFilter,
    transactions,
    isFetching,
    filters,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    downloadTransactionsTable,
    isExportLoading,
  };
};

export default useTransactionHistoryHook;
