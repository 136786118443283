import { useFetchTerms } from "../../../../queries/adminQueries/adminQueries";
import useAuthStore from "../../../../stores/auth/auth";
import { useState } from "react";

const useTermsHook = () => {
  const { userData } = useAuthStore.getState();
  const { data: terms } = useFetchTerms();
  const [isEdit, setIsEdit] = useState(false);
  const [isNewBlock, setIsNewBlock] = useState(false);
  const [isNewTitle, setIsNewTitle] = useState(false);

  const isAdmin = userData?.role === "admin";

  return {
    isEdit,
    setIsEdit,
    isAdmin,
    terms,
    isNewBlock,
    setIsNewBlock,
    isNewTitle,
    setIsNewTitle,
  };
};

export default useTermsHook;
