import { useCallback, useEffect, useState } from "react";

const useCountdown = (targetDate: any) => {
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  const onChange = useCallback(() => {
    setCountDown(countDownDate - new Date().getTime() - 1000);
  }, []);

  useEffect(() => {
    let interval = null;
    if (new Date(targetDate).getTime() > new Date().getTime()) {
      interval = setInterval(onChange, 1000);
    }
    return () => clearInterval(interval!);
  }, [countDown, targetDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 48)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export default useCountdown;
