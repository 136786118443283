import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Close } from "../../../../assets/icons/delete-icon.svg";
import { Oval } from "react-loader-spinner";
import { useRemoveUserFromLeague } from "../../../../queries/adminQueries/adminQueries";
import { toast } from "react-toastify";

export const RemoveTeamModal = ({
  leagueId,
  userId,
}: {
  leagueId?: string;
  userId: string;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <RemoveTeam closeModal={closeModal} leagueId={leagueId} userId={userId} />
  );
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Close
        onClick={(e: any) => {
          e?.stopPropagation();
          openModal();
        }}
      />
    </>
  );
};

const RemoveTeam = ({
  closeModal,
  leagueId,
  userId,
}: {
  closeModal?: () => void;
  leagueId?: string;
  userId: string;
}) => {
  const { mutateAsync: removeTeam, isPending } = useRemoveUserFromLeague();

  const handleRemoveTeam = async () => {
    try {
      await removeTeam({ leagueId, userId });
      toast.success("Team removed successfully");
      window.location.reload();
    } catch (error) {
      toast.error("Error removing team");
    } finally {
      closeModal?.();
    }
  };

  return (
    <>
      <p className="text-[24px] font-gilroySemibold mb-2">
        Are you sure you want to Remove this team from league?
      </p>
      <p className="font-gilroyMedium text-text-subGrey">
        Entry fee funds will be returned to the user balance.
      </p>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          disabled={isPending}
          leftIcon={
            <Oval
              visible={isPending}
              height="20"
              width="20"
              color="#000000"
              secondaryColor="#ffffff"
            />
          }
          onClick={handleRemoveTeam}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Remove Team
        </Button>
        <Button
          onClick={closeModal}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default RemoveTeamModal;
