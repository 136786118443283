import { Navigate, RouteObject } from "react-router";
import { createBrowserRouter } from "react-router-dom";

import Layout from "./../containers/Layout";
import Dashboard from "../pages/private/Dashboard/Dashboard";
import Leagues from "../pages/private/Leagues/Leagues";
import League from "../pages/private/League/League";
import Team from "../pages/private/Team/Team";
import Draft from "../pages/private/Draft/Draft";
import Profile from "../pages/private/UserPages/Profile/Profile.tsx";
import useAuthStore from "../stores/auth/auth";
import UserLeagues from "../pages/private/UserPages/Leagues/UserLeagues.tsx";
import Users from "../pages/private/Users/Users.tsx";
import UserProfile from "../pages/private/UserProfile/UserProfile.tsx";
import Finances from "../pages/private/UserPages/Finances/Finances.tsx";
import TransactionsHistory from "../pages/private/UserPages/TransactionHistory/TransactionHistory.tsx";
import Home from "../pages/private/UserPages/Home/Home.tsx";
import PrivacyPolicy from "../pages/guest/PrivacyPolicy/PrivacyPolicy.tsx";
import TermsAndConditions from "../pages/guest/TermsAndConditions/TermsAndConditions.tsx";
import Help from "../pages/private/UserPages/Help/Help.tsx";
import Transactions from "../pages/private/Transactions/Transactions.tsx";
import UsersDrafts from "../pages/private/UserPages/UserDrafts/UserDrafts.tsx";

const useRouter = () => {
  const { userData } = useAuthStore(["userData"]);
  const isAdmin = userData?.role === "admin";

  const routes: RouteObject[] = [
    {
      element: <Layout />,
      children: [
        { path: "/dashboard", element: isAdmin ? <Dashboard /> : <Home /> },
        {
          path: "/leagues",
          element: isAdmin ? <Leagues /> : <UserLeagues />,
        },
        { path: "/leagues/league/:id", element: <League /> },
        { path: "/leagues/team/:id", element: <Team /> },
        { path: "/draft/:id", element: <Draft /> },
        { path: "/profile", element: <Profile /> },
        { path: "/users", element: isAdmin ? <Users /> : <></> },
        {
          path: "/users/user-profile/:id",
          element: isAdmin ? <UserProfile /> : <></>,
        },
        { path: "/finances", element: <Finances /> },
        {
          path: "/finances/transaction-history",
          element: <TransactionsHistory />,
        },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/terms-conditions", element: <TermsAndConditions /> },
        { path: "/help", element: <Help /> },
        { path: "/transactions", element: isAdmin ? <Transactions /> : <></> },
        { path: "/drafts", element: <UsersDrafts /> },
        { path: "*", element: <Navigate to="/dashboard" replace /> },
      ],
    },
  ];

  const privateRouter = createBrowserRouter(routes);
  return { privateRouter };
};

export default useRouter;
