import { toast } from "react-toastify";
import {
  useFetchAllUsers,
  useFetchLatestTransactions,
  useFetchLeaguesCount,
  useFetchStats,
  useFetchUsersCount,
} from "../../../../queries/adminQueries/adminQueries";
import { useFetchLeaguesList } from "../../../../queries/leaguesQueries/leaguesQueries";
import { useNavigate } from "react-router-dom";

const useDashboard = () => {
  const { data } = useFetchStats();
  const { data: usersCount } = useFetchUsersCount();
  const { data: leaguesCount } = useFetchLeaguesCount();
  const { data: users } = useFetchAllUsers(true);
  const { data: latestTransactions } = useFetchLatestTransactions({
    status: "completed",
    limit: 5,
  });
  const { data: leagues } = useFetchLeaguesList({ limit: 5 });
  const navigate = useNavigate();

  const onShare = () => {
    const inviteLink = `${window.location.href?.slice(0, -10)}`;
    navigator.clipboard.writeText(inviteLink);
    toast.success("Link copied to clipboard");
  };
  return {
    data,
    usersCount,
    leaguesCount,
    users,
    latestTransactions,
    leagues,
    navigate,
    onShare,
  };
};
export default useDashboard;
