import moment from "moment";
import {
  useFetchUserNotifications,
  useRemoveNotification,
} from "../../queries/notificationsQueries/notificationsQueries";
import useAuthStore from "../../stores/auth/auth";
import { useEffect, useState } from "react";
import { Button } from "../../components/Button/Button";
import { NotificationsServices } from "../../services/apis/Notifications/NotificationsServices";
import { useNavigate } from "react-router-dom";
import { AdminServices } from "../../services/apis/Admin/AdminServices";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";

const NotificationCenter = ({
  checkIndicator,
}: {
  checkIndicator: () => void;
}) => {
  const navigate = useNavigate();
  const { userData } = useAuthStore.getState();
  const isAdmin = userData?.role === "admin";
  const isEnabled = userData?.isNotification;
  const [page, setPage] = useState(1);
  const { mutateAsync } = useRemoveNotification();
  const { data, isFetching, refetch } = useFetchUserNotifications(
    userData?.id,
    {
      page: page,
      limit: 5,
      createdAtSort: "desc",
    },
    isAdmin
  );
  const [notifications, setNotifications] = useState<any>([]);

  useEffect(() => {
    if (data?.length) {
      handleNotifications();
    }
  }, [data]);

  const handleNotifications = () => {
    setNotifications([...notifications, ...data]);
    setPage(page + 1);
    data?.forEach(async (item: any) => {
      if (item?.status === "unread") {
        await NotificationsServices?.readNotification({
          notificationId: item?.id,
        });
        checkIndicator();
      } else return;
    });
  };

  const readMore = async () => {
    try {
      await refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const readAll = async () => {
    try {
      await NotificationsServices?.readAllNotification(userData?.id);
      checkIndicator();
    } catch (error) {
      console.log(error);
    }
  };

  const confirmWithdrawal = async (
    withdrawalId: string,
    confirmation: boolean,
    notificationId: string
  ) => {
    try {
      await AdminServices?.makeWithdrawal({
        withdrawalId,
        confirm: confirmation,
      });
      toast.success("Withdrawal request has been processed");
      await mutateAsync({ notificationId: notificationId });
      setNotifications((prev: any) =>
        prev?.filter((item: any) => item?.id !== notificationId)
      );
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <div className="absolute top-20 right-3 w-[94%] z-[20] md:w-[450px] md:right-10 scrollbar-hide z-[80]">
      <div className="relative px-6 py-4 bg-filterContainer border-t-[1px] border-x-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
        <p className="font-gilroyBold">Notifications</p>
        <Button
          onClick={readAll}
          mode="secondary"
          className="bg-card-bgGreyLight rounded-[8px] w-auto px-6"
        >
          Mark all as read
        </Button>
      </div>
      <div className="bg-card-bgGreyDark p-4 rounded-b-[12px] border-x-[1px] border-b-[1px] border-modal-modalStroke overflow-y-scroll h-[570px] scrollbar">
        {notifications?.length ? (
          notifications
            ?.filter((item: any) => (!isEnabled ? item?.type !== "info" : item))
            ?.map((item: any) => (
              <div key={item?.id} className="w-full mb-4">
                <p className="text-base font-gilroyBold capitalize">
                  {item?.type}
                </p>
                <p className="text-xs font-gilroyMedium text-text-grey mt-2">
                  {item?.message}
                </p>
                <p className="text-xs text-text-grey mt-2">
                  {moment(item?.createdAt)?.format("lll")}
                </p>
                {item?.type === "withdrawal" && (
                  <div className="flex gap-2 mt-2">
                    <Button
                      onClick={() =>
                        confirmWithdrawal(item?.params, false, item?.id)
                      }
                      mode="secondary"
                      className="rounded-[8px] w-full !max-h-[40px]"
                    >
                      Decline
                    </Button>
                    <Button
                      onClick={() =>
                        confirmWithdrawal(item?.params, true, item?.id)
                      }
                      mode="primary"
                      className="rounded-[8px] w-full !max-h-[40px]"
                    >
                      Accept
                    </Button>
                  </div>
                )}
                {item?.type === "draft" &&
                  item?.message !== "The draft has ended" && (
                    <div className="flex jusstify-center mt-2">
                      <Button
                        onClick={() => navigate(`/draft/${item?.params}`)}
                        mode="primary"
                        className="rounded-[8px] w-full !max-h-[40px]"
                      >
                        Go To Draft
                      </Button>
                    </div>
                  )}
                <div className="bg-card-strokeGradient-2 w-full h-[1px] mt-4" />
              </div>
            ))
        ) : isFetching ? (
          <Oval
            visible={isFetching}
            height="20"
            width="20"
            color="#000000"
            secondaryColor="#ffffff"
          />
        ) : (
          <p className="font-gilroyMedium text-text-grey">No notifications</p>
        )}
        {data?.length ? (
          <div className="w-full flex justify-center">
            <Button
              className="rounded-[8px] !max-h-[40px]"
              onClick={() => readMore()}
            >
              Load more
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default NotificationCenter;
