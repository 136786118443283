import { ReactComponent as ChangeArrow } from "../../../../assets/icons/arrowUp-icon.svg";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import clsx from "clsx";
import { Button, Input, TextArea } from "../../../../components";
import { useEffect, useState } from "react";
import usePrivacyPolicyHook from "../hooks/usePrivacyPolicy";
import {
  useAddPrivacyPolicy,
  useChangePrivacyPriority,
  useEditPrivacyPolicy,
} from "../../../../queries/adminQueries/adminQueries";
import { toast } from "react-toastify";
import { DeleteSectionModal } from "./DeleteSection";

const EditComponent = ({ item }: { item?: any }) => {
  const { privacyPolicy, setIsNewBlock } = usePrivacyPolicyHook();
  const { mutateAsync } = useAddPrivacyPolicy();
  const { mutateAsync: changePrivacyPriority } = useChangePrivacyPriority();
  const { mutateAsync: editPrivacyPolicy } = useEditPrivacyPolicy();
  const [title, setTitle] = useState(item?.title || "");
  const [content, setContent] = useState(item?.content || "");

  const minPriority = privacyPolicy?.length
    ? privacyPolicy?.reduce((acc: any, item: any) =>
        acc.priority < item.priority ? acc : item
      )
    : null;
  const maxPriority = privacyPolicy?.length
    ? privacyPolicy?.reduce((acc: any, item: any) =>
        acc.priority > item.priority ? acc : item
      )
    : null;

  useEffect(() => {
    setTitle(item?.title);
    setContent(item?.content);
  }, [item?.title, item?.content]);

  const onAddPrivacy = async () => {
    try {
      if (title && content) {
        await mutateAsync({
          title: title,
          content: content,
        });
        setIsNewBlock(false);
        setTitle("");
        setContent("");
        toast.success("Block created successfully");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  const onUpdatePrivacy = async () => {
    try {
      await editPrivacyPolicy({
        id: item?.id,
        title: title,
        content: content,
      });
      toast.success("Block updated successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const onChangePriority = async (termId: number, direction: string) => {
    try {
      await changePrivacyPriority({ termId, direction });
    } catch (error) {}
  };

  return (
    <div className="w-full">
      <div className="flex items-center">
        <Input
          value={title}
          placeholder="Enter the Title..."
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          containerClassName={clsx("w-[85%] border-none rounded-[8px]")}
        />
        <div className="flex gap-2 ml-2">
          {item && (
            <ChangeArrow
              className={clsx(
                "cursor-pointer",
                maxPriority?.priority === item?.priority && "opacity-50"
              )}
              onClick={() =>
                maxPriority?.priority === item?.priority
                  ? null
                  : onChangePriority(item?.id, "decrease")
              }
            />
          )}
          {item && (
            <ChangeArrow
              className={clsx(
                "rotate-180 cursor-pointer",
                minPriority?.priority === item?.priority && "opacity-50"
              )}
              onClick={() =>
                minPriority?.priority === item?.priority
                  ? null
                  : onChangePriority(item?.id, "increase")
              }
            />
          )}
          {item && <DeleteSectionModal sectionId={item?.id} />}
        </div>
      </div>
      <TextArea
        value={content}
        placeholder="Enter the description..."
        onChange={(e) => {
          setContent(e.target.value);
        }}
        containerClassName="w-[85%] border-none rounded-[8px] mt-6"
      />
      {((item && item?.title !== title) ||
        (item && item?.content !== content) ||
        (!item && title && content)) && (
        <Button
          className="rounded-[8px] mt-6"
          leftIcon={<Check />}
          onClick={!item ? onAddPrivacy : onUpdatePrivacy}
        >
          Save this Block Changes
        </Button>
      )}
      <div className="w-full h-[1px] bg-modal-modalStroke my-6" />
    </div>
  );
};

export default EditComponent;
