import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Launch } from "../../../../assets/icons/launch.svg";
import useLeagueHook from "../hooks/useLeague";

export const LaunchDraftModal = () => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <LaunchDraft closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        leftIcon={<Launch />}
        mode="secondary"
        onClick={openModal}
        className="rounded-[8px] w-full px-4 text-nowrap !h-10 xl:w-auto"
      >
        Launch Draft
      </Button>
    </>
  );
};

const LaunchDraft = ({ closeModal }: { closeModal?: () => void }) => {
  const { launchDraft } = useLeagueHook();
  return (
    <>
      <p className="text-[24px] font-gilroySemibold mb-2">
        Are you ready to launch the draft for the league?
      </p>
      <p className="font-gilroyMedium text-text-subGrey">
        Once initiated, all team managers will be able to make their player
        selections. This action can’t be undone
      </p>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={launchDraft}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Launch Draft
        </Button>
        <Button
          onClick={closeModal}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default LaunchDraft;
