import { ReactComponent as ChangeArrow } from "../../../../assets/icons/arrowUp-icon.svg";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import clsx from "clsx";
import { Button, Input } from "../../../../components";
import { useEffect, useState } from "react";
import {
  useAddTermsTitle,
  useChangeTerms,
  useEditTerms,
} from "../../../../queries/adminQueries/adminQueries";
import { toast } from "react-toastify";
import useTermsHook from "../hooks/useTerms";
import { DeleteTitleModal } from "./DeleteTitle";

const EditTitle = ({ item }: { item?: any }) => {
  const { terms, setIsNewTitle } = useTermsHook();
  const { mutateAsync } = useAddTermsTitle();
  const { mutateAsync: changeTermsPriority } = useChangeTerms();
  const { mutateAsync: editTerms } = useEditTerms();
  const [title, setTitle] = useState(item?.name || "");

  const minPriority = terms?.length
    ? terms?.reduce((acc: any, item: any) =>
        acc.priority < item.priority ? acc : item
      )
    : null;
  const maxPriority = terms?.length
    ? terms?.reduce((acc: any, item: any) =>
        acc.priority > item.priority ? acc : item
      )
    : null;

  useEffect(() => {
    setTitle(item?.name);
  }, [item?.name]);

  const onAddTerms = async () => {
    try {
      if (title) {
        await mutateAsync({
          name: title,
        });
        setIsNewTitle(false);
        setTitle("");
        toast.success("Block created successfully");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  const onUpdateTerms = async () => {
    try {
      await editTerms({
        titleId: item?.id,
        newTitleData: {
          name: title,
        },
      });
      toast.success("Block updated successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const onChangePriority = async (titleId: number, direction: string) => {
    try {
      await changeTermsPriority({ titleId, direction });
    } catch (error) {}
  };

  return (
    <div className="w-full">
      <div className="flex items-center">
        <Input
          value={title}
          placeholder="Enter the Title..."
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          containerClassName={clsx("w-[85%] border-none rounded-[8px]")}
        />
        <div className="flex gap-2 ml-2">
          {item && (
            <ChangeArrow
              className={clsx(
                "cursor-pointer",
                maxPriority?.priority === item?.priority && "opacity-50"
              )}
              onClick={() =>
                maxPriority?.priority === item?.priority
                  ? null
                  : onChangePriority(item?.id, "decrease")
              }
            />
          )}
          {item && (
            <ChangeArrow
              className={clsx(
                "rotate-180 cursor-pointer",
                minPriority?.priority === item?.priority && "opacity-50"
              )}
              onClick={() =>
                minPriority?.priority === item?.priority
                  ? null
                  : onChangePriority(item?.id, "increase")
              }
            />
          )}
          {item && <DeleteTitleModal titleId={item?.id} />}
        </div>
      </div>
      {((item && item?.name !== title) || (!item && title)) && (
        <Button
          className="rounded-[8px] mt-6"
          leftIcon={<Check />}
          onClick={!item ? onAddTerms : onUpdateTerms}
        >
          Save this Title Changes
        </Button>
      )}
      <div className="w-full h-[1px] bg-modal-modalStroke my-6" />
    </div>
  );
};

export default EditTitle;
