import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Close } from "../../../../assets/icons/close-icon.svg";
import { Oval } from "react-loader-spinner";
import { useQuitLeague } from "../../../../queries/leaguesQueries/leaguesQueries";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const QuitLeagueModal = ({ leagueId }: { leagueId?: string }) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <QuitLeague closeModal={closeModal} leagueId={leagueId} />
  );
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        leftIcon={<Close />}
        mode="secondary"
        onClick={openModal}
        className="rounded-[8px] w-full px-4 text-nowrap !h-10 xl:w-auto"
      >
        Quit League
      </Button>
    </>
  );
};

const QuitLeague = ({
  closeModal,
  leagueId,
}: {
  closeModal?: () => void;
  leagueId?: string;
}) => {
  const { mutateAsync, isPending } = useQuitLeague();
  const navigate = useNavigate();

  const handleQuiteLeague = async () => {
    try {
      await mutateAsync(leagueId!);
      toast.success("You have successfully quited the league!");
      navigate("/leagues");
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      closeModal?.();
    }
  };

  return (
    <>
      <p className="text-[24px] font-gilroySemibold mb-2">
        Are you sure you want to Quit this league?
      </p>
      <p className="font-gilroyMedium text-text-subGrey">
        Entry fee funds will be returned to the your balance.
      </p>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          disabled={isPending}
          leftIcon={
            <Oval
              visible={isPending}
              height="20"
              width="20"
              color="#000000"
              secondaryColor="#ffffff"
            />
          }
          onClick={handleQuiteLeague}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Quit League
        </Button>
        <Button
          onClick={closeModal}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default QuitLeague;
