import useLeaguesHook from "../../Leagues/hooks/useLeagues.tsx";
import leagueLogo from "../../../../assets/images/ball-logo.png";
import { useState } from "react";
import useLeagueHook from "../../League/hooks/useLeague.tsx";
import { useFetchSelectedLeaguesPost } from "../../../../queries/leaguesQueries/leaguesQueries.tsx";
import { toast } from "react-toastify";

type CallbackFunction = () => void;
const useMoveTeams = () => {
  const { id, data: league } = useLeagueHook();
  const { leagues } = useLeaguesHook();
  const { mutateAsync } = useFetchSelectedLeaguesPost();
  const [selectedTeams, setSelectedTeams] = useState<any[]>([]);
  const [selectedLeague, setSelectedLeague] = useState<any>();

  const selectLeague = (data: any) => {
    if (id !== data?.id) {
      setSelectedLeague(data);
    }
  };
  const selectAll = () => setSelectedTeams(league?.league?.team);
  const clearAll = () => setSelectedTeams([]);
  const removeSelectedTeam = (record: any) => {
    setSelectedTeams(
      selectedTeams.filter((league) => league?.id !== record?.id)
    );
  };
  const selectTeam = (record: any) => {
    const selected = selectedTeams.find((league) => league?.id === record?.id);
    if (!selected) {
      setSelectedTeams([...selectedTeams, record]);
    }
  };
  const leaguesBySelect = leagues.filter(
    (data: any) => data.id !== league?.league?.id
  );

  const sendInvites = async (cb: CallbackFunction) => {
    try {
      const teams: string[] = selectedTeams.map((league) => league?.id);
      const leagueId = selectedLeague?.id;
      const reqBody = {
        finalLeagueId: leagueId,
        teamIds: teams,
      };
      if (teams.length > 0 && leagueId && id) {
        await mutateAsync({ id, reqBody });
        toast.success("League updated successfully!");
        window.location.reload();
      }
      cb();
    } catch (e: any) {
      toast.error(e?.error?.response?.data.error);
      cb();
    }
  };

  const columnsLeagues = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: string, record: any) => {
        return (
          <div
            className="w-[100%] flex gap-3 items-center"
            onClick={() => selectTeam(record)}
          >
            <img
              className="w-[40px] h-[40px] rounded-full"
              src={record?.logo_url || leagueLogo}
              alt="League logo"
            />
            <div>{record.name}</div>
          </div>
        );
      },
    },
  ];
  const columnsSelectedLeagues = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: string, record: any) => {
        return (
          <div
            className="w-[100%] flex gap-3 items-center"
            onClick={() => removeSelectedTeam(record)}
          >
            <img
              className="w-[40px] h-[40px] rounded-full"
              src={record?.logo_url || leagueLogo}
              alt="League logo"
            />
            <div>{record.name}</div>
          </div>
        );
      },
    },
  ];
  return {
    columnsLeagues,
    columnsSelectedLeagues,
    league,
    selectedTeams,
    clearAll,
    selectAll,
    sendInvites,
    leagues,
    selectedLeague,
    selectLeague,
    leaguesBySelect,
  };
};

export default useMoveTeams;
