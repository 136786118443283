import { useMemo, FC } from "react";
import Select, { MultiValue, StylesConfig, components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { colors } from "../../configs/colors";
import { CheckboxSelectPropsType } from "./type";

const CustomOption: (typeof components)["Option"] = ({
  children,
  ...props
}) => {
  return (
    <components.Option {...props} className="text-left">
      <div className="flex items-center">
        <input
          type="checkbox"
          className="border-primary-1-light text-primary-1-light rounded-primary w-6 h-6"
          checked={props.isSelected}
        />
        <span className="text-[16px]">{children}</span>
      </div>
    </components.Option>
  );
};

const CustomSingleOption: (typeof components)["Option"] = ({
  children,
  ...props
}) => {
  return (
    <components.Option {...props} className="text-left">
      <div className="flex items-center">
        <span className="text-[16px]">{children}</span>
      </div>
    </components.Option>
  );
};

const CustomMenuList: (typeof components)["MenuList"] = ({
  children,
  ...props
}) => {
  return (
    <components.MenuList {...props} className="text-left">
      {children}
    </components.MenuList>
  );
};

const CustomMultiValueLabel: (typeof components)["MultiValueLabel"] = ({
  children,
  ...props
}) => {
  const optionValue = props.data.value;
  const selectValue = props.selectProps.value as MultiValue<{
    value: string;
    label: string;
  }>;
  const isLast =
    selectValue?.[(selectValue?.length || 1) - 1]?.value === optionValue;
  return (
    <components.MultiValueLabel {...props}>
      {children}
      {isLast ? "" : ","}
    </components.MultiValueLabel>
  );
};

export const CheckboxSelect: FC<CheckboxSelectPropsType> = ({
  value,
  onChange,
  options,
  isCreatable,
  required = false,
  isDisabled = false,
  isSearchable = false,
  isMultiSelect = true,
  closeMenuOnSelect = true,
  placeholder = "Select",
  customComponents = {
    Option: isMultiSelect ? CustomOption : CustomSingleOption,
    MenuList: CustomMenuList,
    MultiValueLabel: CustomMultiValueLabel,
  },
  overrideControlSyles,
}) => {
  const styles = useMemo<StylesConfig>(
    () => ({
      control: (provided, state) => ({
        ...provided,
        textAlign: "left",
        borderColor: colors.transparent,
        borderRadius: "25px",
        minHeight: "52px",
        backgroundColor: colors.card.bgGreyMedium,
        borderWidth: "2px",
        color: colors.text.white,
        cursor: "pointer",
        "&:hover": {
          borderColor: colors.transparent,
        },
        "&:focus-within": {
          borderColor: colors.card.strokeGradient[1],
          outline: "none",
          boxShadow: "none",
        },
        ...(state.isDisabled && {
          opacity: 0.3,
        }),
        ...overrideControlSyles,
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: colors.card.bgGreyMedium,
        color: colors.text.white,
        borderColor: colors.card.bgGreyMedium,
        borderRadius: "8px !important",
        marginTop: 0,
        zIndex: 10,
      }),
      menuList: (provided) => ({
        ...provided,
        backgroundColor: colors.modal.inputBg,
        color: colors.text.white,
        textTransform: "capitalize",
        borderRadius: "8px !important",
      }),
      option: (provided) => ({
        ...provided,
        backgroundColor: colors.modal.inputBg,
        color: colors.text.white,
        display: "flex",
        cursor: "pointer",
        selected: {
          backgroundColor: colors.card.bgGreyMedium,
        },
        "&:hover": {
          backgroundColor: colors.text.greyDark,
        },
        "&:active": {
          backgroundColor: colors.card.bgGreyMedium,
        },
        height: 52,
      }),
      multiValue: (provided) => ({
        ...provided,
        color: colors.text.white,
        backgroundColor: `${colors.transparent}`,
        borderRadius: "16px",
        gap: "4px",
        textTransform: "capitalize",
        border: "none",
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        color: colors.text.white,
        fontWeight: "normal",
        paddingLeft: 0,
        border: "none",
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        display: "none",
      }),
      input: (provided) => ({
        ...provided,
        color: colors.text.white,
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        paddingRight: 6,
        marginTop: -20,
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),
      clearIndicator: (provided) => ({
        ...provided,
        display: "none",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: colors.text.white,
        backgroundColor: colors.transparent,
        gap: "4px",
        textTransform: "capitalize",
        border: "none",
        paddingLeft: 6,
      }),
      placeholder: (provided) => ({
        ...provided,
        color: colors.text.grey,
        fontSize: 16,
        paddingLeft: 6,
      }),
    }),
    []
  );

  const Component = isCreatable ? CreatableSelect : Select;

  return (
    <Component
      isDisabled={isDisabled}
      styles={styles}
      value={value}
      placeholder={placeholder}
      noOptionsMessage={({ inputValue }) => {
        return inputValue === "" ? "Start Typing To Search" : "No result found";
      }}
      options={options}
      isSearchable={isSearchable}
      isMulti={isMultiSelect}
      hideSelectedOptions={false}
      required={required}
      components={customComponents}
      closeMenuOnSelect={closeMenuOnSelect}
      onChange={(val) => {
        if (!val) {
          return;
        }
        onChange(val as MultiValue<{ value: string; label: string }>);
      }}
    />
  );
};
