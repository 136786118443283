import useAuthStore from "../../../../../stores/auth/auth";
import {
  useAddFAQQuestion,
  useAddFAQSection,
  useChangeFAQPriority,
  useEditFAQ,
  useFetchFAQ,
} from "../../../../../queries/adminQueries/adminQueries";
import { useEffect, useState } from "react";

const useHelp = () => {
  const { data } = useFetchFAQ();
  const { mutateAsync: addSection, isPending: isPendingSection } =
    useAddFAQSection();
  const { mutateAsync: addQuestion } = useAddFAQQuestion();
  const { mutateAsync: changePriority } = useChangeFAQPriority();
  const { mutateAsync: updateFAQ } = useEditFAQ();
  const { userData } = useAuthStore.getState();
  const isAdmin = userData?.role === "admin";
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [allFAQ, setAllFAQ] = useState(data || []);
  const [activeSection, setActiveSection] = useState<string>(data?.[0]?.name);
  const [activeSectionId, setActiveSectionId] = useState<number>();
  const [isNewQuestion, setIsNewQuestion] = useState(false);

  useEffect(() => {
    setAllFAQ(data);
    if (!activeSection && activeSection !== data?.[0]?.name) {
      setActiveSection(data?.[0]?.name);
    }
    if (!activeSectionId && activeSectionId !== data?.[0]?.id) {
      setActiveSectionId(data?.[0]?.id);
    }
  }, [data]);

  const onChangePriority = async (sectionId: number, direction: string) => {
    try {
      await changePriority({ sectionId, direction });
    } catch (error) {}
  };

  const minPriority = data?.length
    ? data?.reduce((acc: any, item: any) =>
        acc.priority < item.priority ? acc : item
      )
    : null;
  const maxPriority = data?.length
    ? data?.reduce((acc: any, item: any) =>
        acc.priority > item.priority ? acc : item
      )
    : null;

  return {
    data,
    activeSection,
    setActiveSection,
    isAdmin,
    isEdit,
    setIsEdit,
    allFAQ,
    setAllFAQ,
    activeSectionId,
    setActiveSectionId,
    addSection,
    addQuestion,
    changePriority,
    onChangePriority,
    minPriority,
    maxPriority,
    updateFAQ,
    isPendingSection,
    isNewQuestion,
    setIsNewQuestion,
  };
};
export default useHelp;
