import { Modal, useModal } from "../../../../components";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import { useFetchPlayerBidsHistory } from "../../../../queries/waiverQueries/waiverQueries";
import { numberWithCommas } from "../../../../utils/helpers";

export const BidHistoryModal = ({ log }: { log?: any }) => {
  const addContent = () => <BidHistory log={log} />;
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <div
        onClick={() => (log?.type === "bidAction" ? openModal() : null)}
        className="flex items-center mt-2 justify-between w-full"
      >
        <div className="flex items-center">
          <img
            src={demo_team_logo}
            alt="CutThroat"
            className="w-[38px] h-[38px] rounded-[50px] mr-2"
          />
          <div>
            <p
              className={"text-base font-gilroyBold text-text-white text-left"}
            >
              {log?.desc}
            </p>
            <p className="text-text-grey text-xs font-gilroyMedium capitalize text-left">
              {log?.type}
            </p>
          </div>
        </div>
        {log?.amount && (
          <p className="text-text-green font-gilroyBold">
            ${numberWithCommas(log?.amount)}
          </p>
        )}
      </div>
    </>
  );
};

const BidHistory = ({ log }: { log?: any }) => {
  const { data } = useFetchPlayerBidsHistory({
    leagueId: log?.leagueId,
    playerId: log?.playerId,
  });

  const groupByWeek = (arr: any) => {
    return arr?.reduce((acc: any, item: any) => {
      if (!acc[item?.week]) {
        acc[item?.week] = [];
      }
      acc[item?.week].push(item);
      return acc;
    }, []);
  };
  const groupedData = groupByWeek(data);

  return (
    <>
      <p className="text-[24px] font-gilroySemibold mb-2">Bids History</p>
      {groupedData?.length ? (
        groupedData?.map((item: any, index: number) => (
          <div key={index}>
            <div className="flex items-center w-full gap-2">
              <div className="h-[1px] w-full bg-stroke" />
              <p className="whitespace-nowrap text-text-grey text-xs">
                Week {item?.[0]?.week}
              </p>
              <div className="h-[1px] w-full bg-stroke" />
            </div>
            {item?.length
              ? item
                  ?.sort((a: any, b: any) => b?.amount - a?.amount)
                  ?.map((item: any) => (
                    <div
                      key={item?.id}
                      className="flex justify-between items-center mt-2"
                    >
                      <div className="flex items-center">
                        <img
                          src={item?.userAvatar || demo_team_logo}
                          alt="CutThroat"
                          className="w-[38px] h-[38px] rounded-[50px] mr-2"
                        />
                        <p
                          className={
                            "text-base font-gilroyBold text-text-white text-left"
                          }
                        >
                          {item?.username}
                        </p>
                      </div>

                      <p className="text-text-green font-gilroyBold">
                        ${numberWithCommas(item?.amount)}
                      </p>
                    </div>
                  ))
              : null}
          </div>
        ))
      ) : (
        <p className="font-gilroyMedium text-text-grey">No bids</p>
      )}
    </>
  );
};

export default BidHistory;
