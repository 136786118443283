import { useFetchPrivacyPolicy } from "../../../../queries/adminQueries/adminQueries";
import useAuthStore from "../../../../stores/auth/auth";
import { useState } from "react";

const usePrivacyPolicyHook = () => {
  const { userData } = useAuthStore.getState();
  const { data: privacyPolicy } = useFetchPrivacyPolicy();
  const [isEdit, setIsEdit] = useState(false);
  const [isNewBlock, setIsNewBlock] = useState(false);

  const isAdmin = userData?.role === "admin";

  return {
    isEdit,
    setIsEdit,
    isAdmin,
    privacyPolicy,
    isNewBlock,
    setIsNewBlock,
  };
};

export default usePrivacyPolicyHook;
