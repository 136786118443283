import { useArchiveLeague } from "../../../../queries/adminQueries/adminQueries";
import { Button, Modal, useModal } from "../../../../components";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";

export const ArchiveLeagueModal = ({ leagueId }: { leagueId?: string }) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <ArchiveLeague closeModal={closeModal} leagueId={leagueId} />
  );
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        mode="secondary"
        onClick={openModal}
        className="rounded-[8px] w-full px-4 text-nowrap !h-10 xl:w-auto"
      >
        Archive League
      </Button>
    </>
  );
};

const ArchiveLeague = ({
  closeModal,
  leagueId,
}: {
  closeModal?: () => void;
  leagueId?: string;
}) => {
  const { mutateAsync, isPending } = useArchiveLeague();

  const onSubmit = async () => {
    try {
      await mutateAsync({ leagueId: leagueId });
      toast.success("League was successfully archived");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      closeModal?.();
    }
  };

  return (
    <>
      <p className="text-[24px] font-gilroySemibold mb-2">
        Are you sure you want to archive this league?
      </p>
      <p className="font-gilroyMedium text-text-subGrey">
        This action cannot be undone. All associated data will be permanently
        archived.
      </p>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          disabled={isPending}
          leftIcon={
            <Oval
              visible={isPending}
              height="20"
              width="20"
              color="#000000"
              secondaryColor="#ffffff"
            />
          }
          onClick={onSubmit}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Archive League
        </Button>
        <Button
          onClick={closeModal}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default ArchiveLeague;
