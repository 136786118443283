import TableCardsListing from "../../../components/TableCardsListing/TableCardsListing";
import { Button, Input } from "../../../components";
import { ReactComponent as Search } from "../../../assets/icons/search-icon.svg";
import useUsersHook from "./hooks/useUsers";
import MobileFilters from "../../../components/MobileFilters/MobileFilters.tsx";
import MobileUsers from "../../../components/MobileUsers/MobileUsers.tsx";
import { InviteUsersModal } from "../League/components/InviteUsers.tsx";
import { ReactComponent as ExportIcon } from "../../../assets/icons/export.svg";
import { Pagination } from "../../../components/Pagination/Pagination.tsx";
import { Oval } from "react-loader-spinner";

const Users = () => {
  const {
    columns,
    isFetching,
    setName,
    users,
    usersCount,
    setStatus,
    setNumOfLeagues,
    setCredits,
    setBalance,
    status,
    numOfLeagues,
    balance,
    credits,
    filter,
    onChangeFilter,
    downloadUsersTable,
    isExportLoading,
  } = useUsersHook();

  const userFilters = columns
    .filter((item: any) => item.name)
    .map((item: any, index: number) => ({
      name: item.name,
      value: item.dataIndex,
      filters: item.filters,
      key: index,
    }));
  const allUsers = users.map((item: { [key: string]: any }, index: number) => ({
    ...item,
    openAction: false,
    key: index,
  }));
  return (
    <div className="px-4 pb-6 md:px-10">
      <div className="w-full justify-end gap-4 hidden lg:flex">
        <InviteUsersModal />
        <Button
          loading={isExportLoading}
          disabled={isExportLoading}
          leftIcon={
            isExportLoading ? (
              <Oval
                visible={isExportLoading}
                height="20"
                width="20"
                color="#000000"
                secondaryColor="#ffffff"
              />
            ) : (
              <ExportIcon />
            )
          }
          className="rounded-[8px] w-full md:w-[150px] px-4 text-nowrap !h-10 xl:auto"
          mode="secondary"
          onClick={downloadUsersTable}
        >
          Export
        </Button>
      </div>
      <div className="mt-6 hidden lg:block">
        <div className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row gap-6 items-center">
          <p className="font-gilroyBold">All Users ({usersCount})</p>
          <Input
            containerClassName="!h-[20px] border-none !py-0 !px-1 bg-transparent focus-within:bg-transparent !w-[70px]"
            inputClassName="placeholder-text-white text-sm"
            placeholder="Search"
            leftIcon={<Search />}
            onChange={(e) => {
              setName(e.target.value);
              onChangeFilter({ keywords: e.target.value });
            }}
          />
        </div>
        <TableCardsListing
          columns={columns}
          dataSource={users}
          loading={isFetching}
          isPlayers
          tableClassName="!min-h-[260px]"
          paginationData={{
            page: filter?.page as number,
            limit: filter?.limit as number,
            count: users?.length ?? 0,
            onChange: (params) => {
              onChangeFilter(params);
            },
          }}
        />
      </div>
      <div className="flex gap-4 flex-col lg:hidden">
        <p className="font-gilroyBold mt-8 mb-3">All Users ({usersCount})</p>

        <MobileFilters
          status={status}
          numOfLeagues={numOfLeagues}
          balance={balance}
          credits={credits}
          setStatus={setStatus}
          setNumOfLeagues={setNumOfLeagues}
          setBalance={setBalance}
          setCredits={setCredits}
          filters={userFilters}
          setName={setName}
          page="users"
        />
        <MobileUsers users={allUsers} />
        <Pagination
          onChange={(params) => {
            onChangeFilter(params);
          }}
          page={filter?.page as number}
          limit={filter?.limit as number}
          count={users?.length ?? 0}
        />
        <div className="w-full justify-end gap-4 flex flex-col">
          <InviteUsersModal />
          <Button
            disabled={isExportLoading}
            leftIcon={
              isExportLoading ? (
                <Oval
                  visible={isExportLoading}
                  height="20"
                  width="20"
                  color="#000000"
                  secondaryColor="#ffffff"
                />
              ) : (
                <ExportIcon />
              )
            }
            loading={isExportLoading}
            className="rounded-[8px] w-full md:w-[150px] px-4 text-nowrap !h-10 xl:auto"
            mode="secondary"
            onClick={downloadUsersTable}
          >
            Export
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Users;
