import { StatisticTable } from "../Table/StatisticTable";
import { TableCardsListingPropsType } from "./type";

// @TODO Discuss with Yuri: Why does the function name not match the file name?

const TableCardsListing = ({
  columns,
  dataSource,
  className,
  emptyState,
  onClickRow,
  size,
  tableClassName,
  loading,
  containerClass,
  overrideTableContainerClass,
}: TableCardsListingPropsType) => {
  return (
    <StatisticTable
      loading={loading}
      size={size}
      columns={columns}
      dataSource={dataSource}
      tableClassName={tableClassName}
      containerClass={`${containerClass ?? ""} ${
        className ?? ""
      }`}
      onClickRow={onClickRow}
      emptyState={emptyState}
      overrideTableContainerClass={overrideTableContainerClass}
    />
  );
};

export default TableCardsListing;
