import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Delete } from "../../../../assets/icons/delete-white-icon.svg";
import { Oval } from "react-loader-spinner";
import { useRemoveTermsTitle } from "../../../../queries/adminQueries/adminQueries";
import { toast } from "react-toastify";

export const DeleteTitleModal = ({ titleId }: { titleId: number }) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <DeleteTitle closeModal={closeModal} titleId={titleId} />
  );
  const { modalControl, openModal } = useModal({
    title: "Delete Title",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Delete className="cursor-pointer" onClick={openModal} />
    </>
  );
};

const DeleteTitle = ({
  titleId,
  closeModal,
}: {
  titleId: number;
  closeModal?: () => void;
}) => {
  const { mutateAsync, isPending } = useRemoveTermsTitle();
  const onDeleteTitle = async () => {
    try {
      await mutateAsync({ titleId: titleId });
      toast.success("Title deleted successfully");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      closeModal?.();
    }
  };
  return (
    <>
      <p className="text-[24px] font-gilroySemibold mb-2">Delete the Title?</p>
      <p className="font-gilroyMedium text-text-subGrey">
        This action can’t be undone
      </p>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
        <Button
          disabled={isPending}
          leftIcon={
            <Oval
              visible={isPending}
              height="20"
              width="20"
              color="#000000"
              secondaryColor="#ffffff"
            />
          }
          onClick={onDeleteTitle}
          size="md"
          mode="primary"
          className="!text-base w-full !rounded-[8px]"
        >
          Delete
        </Button>
      </div>
    </>
  );
};

export default DeleteTitle;
