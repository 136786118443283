import { ReactComponent as ChangeArrow } from "../../../../../assets/icons/arrowUp-icon.svg";
import { ReactComponent as Check } from "../../../../../assets/icons/check.svg";
import clsx from "clsx";
import { Button, Input, TextArea } from "../../../../../components";
import { useEffect, useState } from "react";
import useHelp from "../hooks/useHelp";
import {
  useAddFAQQuestion,
  useChangeFAQQuestionPriority,
  useEditFAQ,
} from "../../../../../queries/adminQueries/adminQueries";
import { toast } from "react-toastify";
import { DeleteQuestionModal } from "./DeleteQuestion";

const EditComponent = ({
  item,
  sectionId,
}: {
  item?: any;
  sectionId?: number;
}) => {
  const { allFAQ, setIsNewQuestion } = useHelp();
  const { mutateAsync } = useAddFAQQuestion();
  const { mutateAsync: updateFAQ } = useEditFAQ();
  const { mutateAsync: changeQuestionPriority } =
    useChangeFAQQuestionPriority();
  const [question, setQuestion] = useState(item?.question || "");
  const [answer, setAnswer] = useState(item?.answer || "");

  const section = allFAQ?.find((item: any) => item?.id === sectionId);
  const minPriority = section?.question?.length
    ? section?.question?.reduce((acc: any, item: any) =>
        acc.priority < item.priority ? acc : item
      )
    : null;
  const maxPriority = section?.question?.length
    ? section?.question?.reduce((acc: any, item: any) =>
        acc.priority > item.priority ? acc : item
      )
    : null;

  useEffect(() => {
    setQuestion(item?.question);
    setAnswer(item?.answer);
  }, [item?.question, item?.answer]);

  const onAddQuestion = async () => {
    try {
      if (answer && question) {
        await mutateAsync({
          sectionId,
          question: question,
          answer: answer,
        });
        setIsNewQuestion(false);
        setAnswer("");
        setQuestion("");
        toast.success("Question created successfully");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  const onUpdateQuestion = async () => {
    try {
      await updateFAQ({
        questionId: item?.id,
        newQuestionData: { ...item, question, answer },
      });
      toast.success("Question updated successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const onChangePriority = async (questionId: number, direction: string) => {
    try {
      await changeQuestionPriority({ questionId, direction });
    } catch (error) {}
  };

  return (
    <div className="w-[650px]">
      <div className="flex items-center">
        <Input
          value={question}
          placeholder="Enter Question"
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
          containerClassName={clsx("w-[500px] border-none rounded-[8px]")}
        />
        <div className="flex gap-2 ml-2">
          {item && (
            <ChangeArrow
              className={clsx(
                "cursor-pointer",
                maxPriority?.priority === item?.priority && "opacity-50"
              )}
              onClick={() =>
                maxPriority?.priority === item?.priority
                  ? null
                  : onChangePriority(item?.id, "decrease")
              }
            />
          )}
          {item && (
            <ChangeArrow
              className={clsx(
                "rotate-180 cursor-pointer",
                minPriority?.priority === item?.priority && "opacity-50"
              )}
              onClick={() =>
                minPriority?.priority === item?.priority
                  ? null
                  : onChangePriority(item?.id, "increase")
              }
            />
          )}
          {item && <DeleteQuestionModal questionId={item?.id} />}
        </div>
      </div>
      <TextArea
        value={answer}
        placeholder="Enter Answer"
        onChange={(e) => {
          setAnswer(e.target.value);
        }}
        containerClassName="w-[500px] border-none rounded-[8px] mt-6"
      />
      {((item && item?.question !== question) ||
        (item && item?.answer !== answer) ||
        (!item && question && answer)) && (
        <Button
          className="rounded-[8px] mt-6"
          leftIcon={<Check />}
          onClick={!item ? onAddQuestion : onUpdateQuestion}
        >
          Save this Block Changes
        </Button>
      )}
      <div className="w-[500px] h-[1px] bg-modal-modalStroke my-6" />
    </div>
  );
};

export default EditComponent;
