import { Navigate, RouteObject } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import Signup from "../pages/guest/Signup/Signup";
import ForgotPassword from "../pages/guest/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/guest/ResetPassword/ResetPassword";
import Login from "../pages/guest/Login/Login";
import PrivacyPolicy from "../pages/guest/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../pages/guest/TermsAndConditions/TermsAndConditions";
import Help from "../pages/private/UserPages/Help/Help";
import GuestLayout from "../containers/GuestLayout";
import GuestHome from "../pages/guest/GuestHome/GuestHome";
import UserLeagues from "../pages/private/UserPages/Leagues/UserLeagues";
import League from "../pages/private/League/League";

const publicRoutes: RouteObject[] = [
  {
    element: <GuestLayout />,
    children: [
      { path: "/login", element: <Login /> },
      { path: "/signup", element: <Signup /> },
      { path: "/forgot-password", element: <ForgotPassword /> },
      { path: "/reset-password", element: <ResetPassword /> },
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
      { path: "/terms-conditions", element: <TermsAndConditions /> },
      { path: "/help", element: <Help /> },
      { path: "/allleagues", element: <UserLeagues /> },
      { path: "/allleagues/league/:id", element: <League /> },

      { path: "/", element: <GuestHome /> },
      { path: "*", element: <Navigate to="/" replace /> },
    ],
  },
];

export const publicRouter = createBrowserRouter(publicRoutes);
