import { Controller } from "react-hook-form";
import {
  Button,
  ErrorMsg,
  Input,
  Modal,
  useModal,
} from "../../../../components";
import useAddNewLeagueHook from "../hooks/useAddNewLeague";
import LabeledInput from "../../../../components/LabeledInput/LabeledInput";
import LabeledTextArea from "../../../../components/LabeledTextArea/LabeledTextArea";
import { CheckboxSelect } from "../../../../components/CheckboxSelect/CheckboxSelect";
import { colors } from "../../../../configs/colors";
import { ReactComponent as Time } from "../../../../assets/icons/time.svg";
import { ReactComponent as Calendar } from "../../../../assets/icons/calendar.svg";
import { ReactComponent as Pencil } from "../../../../assets/icons/pencil.svg";
import { ReactComponent as Radio } from "../../../../assets/icons/radio-button.svg";
import { ReactComponent as CreateLeague } from "../../../../assets/icons/create-league.svg";
import { ReactComponent as Settings } from "../../../../assets/icons/settings.svg";
import { ReactComponent as Edit } from "../../../../assets/icons/edit-icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import teamsNumber from "../../../../configs/teamsNumber";
import moment from "moment-timezone";
import { Oval } from "react-loader-spinner";
import clsx from "clsx";
import { getTimezoneAbbr, isNumeric } from "../../../../utils/helpers";

export const AddNewLeagueModal = ({
  isEdit,
  leagueId,
  isListView,
}: {
  isEdit?: boolean;
  leagueId?: string;
  isListView?: boolean;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <AddNewLeague closeModal={closeModal} isEdit={isEdit} leagueId={leagueId} />
  );
  const { modalControl, openModal } = useModal({
    title: isEdit ? "Edit League" : "Create New League",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      {isListView ? (
        <Edit onClick={openModal} className="cursor-pointer" />
      ) : (
        <Button
          leftIcon={isEdit ? <Settings /> : <CreateLeague />}
          mode="secondary"
          onClick={openModal}
          className="rounded-[8px] w-full px-4 text-nowrap !h-10 xl:w-auto"
        >
          {isEdit ? "League Settings" : "Create New League"}
        </Button>
      )}
    </>
  );
};

const AddNewLeague = ({
  closeModal,
  isEdit,
  leagueId,
}: {
  closeModal?: () => void;
  isEdit?: boolean;
  leagueId?: string;
}) => {
  const {
    isFirstTab,
    isSecondTab,
    isThirdTab,
    setActiveTab,
    receivingOption,
    setReceivingOption,
    date,
    setDate,
    errors,
    control,
    data,
    onSubmit,
    imageSrc,
    handleFileChange,
    handleIconClick,
    fileInputRef,
    getValues,
    isLoading,
    isPendingUpdate,
    isPendingCreate,
    hours,
    setHours,
    minutes,
    setMinutes,
    isAm,
    setIsAm,
  } = useAddNewLeagueHook({ closeModal, isEdit, leagueId });

  return (
    <>
      <div className="flex flex-row gap-2 justify-center items-center mt-2">
        <button
          onClick={() => setActiveTab(1)}
          className={`text-text-subGrey ${
            isFirstTab && "text-text-white"
          } text-xs text-nowrap font-gilroySemibold`}
        >
          League Information
        </button>
        <div className="w-full h-[1px] bg-modal-modalStroke" />
        <button
          onClick={() => setActiveTab(2)}
          className={`text-text-subGrey ${
            isSecondTab && "text-text-white"
          } text-xs text-nowrap font-gilroySemibold`}
        >
          Settings
        </button>
        <div className="w-full h-[1px] bg-modal-modalStroke" />
        <button
          onClick={() => setActiveTab(3)}
          className={`text-text-subGrey ${
            isThirdTab && "text-text-white"
          } text-xs text-nowrap font-gilroySemibold`}
        >
          Draft Settings
        </button>
      </div>
      <div className="w-full h-[1px] bg-modal-modalStroke mt-[14px]" />

      <form className="flex flex-col gap-4 pt-6">
        {isFirstTab && (
          <div>
            <div className="flex justify-center items-center mb-[32px] mt-[16px]">
              <div className="relative">
                <img
                  src={imageSrc}
                  alt="CutThroat"
                  className="w-[100px] h-[100px] rounded-[50px]"
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(event) => handleFileChange(event)}
                />
                <div
                  className="absolute bottom-0 right-0 cursor-pointer"
                  onClick={handleIconClick}
                >
                  <Pencil />
                </div>
              </div>
            </div>

            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <LabeledInput
                  {...field}
                  placeholder="Enter league name"
                  error={errors?.name?.message}
                  errorClassName="text-text-red"
                  label="League Name"
                  labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                  containerClassName="relative"
                  inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                />
              )}
            />
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <LabeledTextArea
                  {...field}
                  error={!!errors?.description?.message}
                  label="League Description"
                  placeholder="Enter league description"
                  labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                  containerClassName="relative mt-6"
                  inputContainerClassName="rounded-[8px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                />
              )}
            />
          </div>
        )}
        {isSecondTab && (
          <div>
            <Controller
              control={control}
              name="entryFee"
              render={({ field }) => (
                <LabeledInput
                  {...field}
                  placeholder="Enter entry fee"
                  error={errors?.name?.message}
                  errorClassName="text-text-red"
                  label="Entry Fee"
                  labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                  containerClassName="relative"
                  inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                />
              )}
            />
            <Controller
              control={control}
              name="prizeFundFirstPlace"
              render={({ field }) => (
                <LabeledInput
                  {...field}
                  placeholder="Enter first place Prize"
                  error={errors?.prizeFundFirstPlace?.message}
                  errorClassName="text-text-red"
                  label="First place Prize"
                  labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                  containerClassName="relative mt-6"
                  inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                />
              )}
            />
            <Controller
              control={control}
              name="prizeFundSecondPlace"
              render={({ field }) => (
                <LabeledInput
                  {...field}
                  placeholder="Enter second place Prize"
                  error={errors?.prizeFundSecondPlace?.message}
                  errorClassName="text-text-red"
                  label="Second place Prize"
                  labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                  containerClassName="relative mt-6"
                  inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                />
              )}
            />
            <Controller
              control={control}
              name="numberOfTeams"
              render={({ field: { ref: _, ...restField } }) => (
                <div className="flex flex-col gap-2 w-full relative mt-6">
                  <p className="absolute z-[1] top-[12px] left-[16px] font-gilroyMedium !text-text-subGrey !text-xs">
                    Number of Teams
                  </p>
                  <CheckboxSelect
                    {...restField}
                    isMultiSelect={false}
                    placeholder="Select number of teams"
                    overrideControlSyles={{
                      minHeight: "60px",
                      padding: 0,
                      borderRadius: 8,
                      borderWidth: 0,
                      backgroundColor: colors?.modal?.inputBg,
                      paddingTop: 20,
                    }}
                    options={teamsNumber}
                  />
                  {errors?.numberOfTeams?.message && (
                    <ErrorMsg
                      error={errors?.numberOfTeams?.message as string}
                    />
                  )}
                </div>
              )}
            />
            <Controller
              control={control}
              name="teamsEliminatedPerWeek"
              render={({ field: { ref: _, ...restField } }) => (
                <div className="flex flex-col gap-2 w-full relative mt-6">
                  <p className="absolute z-[1] top-[12px] left-[16px] font-gilroyMedium !text-text-subGrey !text-xs">
                    Number of Teams Eliminated Each Week
                  </p>
                  <CheckboxSelect
                    {...restField}
                    isMultiSelect={false}
                    placeholder="Select number of teams eliminated each week"
                    overrideControlSyles={{
                      minHeight: "60px",
                      padding: 0,
                      borderRadius: 8,
                      borderWidth: 0,
                      backgroundColor: colors?.modal?.inputBg,
                      paddingTop: 20,
                    }}
                    options={[
                      { label: "2", value: "2" },
                      { label: "4", value: "4" },
                    ]}
                  />
                  {errors?.numberOfTeams?.message && (
                    <ErrorMsg
                      error={errors?.numberOfTeams?.message as string}
                    />
                  )}
                </div>
              )}
            />
            <p className="mt-8 text-left text-xs text-modal-label forn-gilroyMedium">
              Scoring Options
            </p>
            <div className="h-[44px] w-full rounded-[10px] bg-card-bgGreyLight p-1 flex flex-row mt-2">
              <div
                onClick={() => setReceivingOption("PPR")}
                className={`w-[50%] bg-gradient-to-br ${
                  receivingOption === "PPR" &&
                  "from-red-bgGradient2-1 to-red-bgGradient2-2"
                } h-full rounded-[8px] flex justify-center items-center text-sm text-text-white font-gilroyBold cursor-pointer`}
              >
                PPR
              </div>
              <div
                onClick={() => setReceivingOption("Non-PPR")}
                className={`w-[50%] bg-gradient-to-br ${
                  receivingOption !== "PPR" &&
                  "from-red-bgGradient2-1 to-red-bgGradient2-2"
                } h-full rounded-[8px] flex justify-center items-center text-sm text-text-white font-gilroyBold cursor-pointer`}
              >
                Non-PPR
              </div>
            </div>
            <p className="mt-8 text-left text-xs text-modal-label forn-gilroyMedium">
              Rosted Req.
            </p>
            <div className="flex flex-row gap-1 mt-3">
              {data?.map((item) => (
                <div
                  key={item}
                  className="w-10 h-10 rounded-[20px] bg-modal-inputBg flex justify-center items-center text-text-white font-gilroyBold bg-gradient-to-br"
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
        {isThirdTab && (
          <div>
            <div className="flex flex-row justify-between relative z-[5]">
              <DatePicker
                showPreviousMonths={false}
                minDate={moment()?.toDate()}
                showPopperArrow={false}
                selected={date}
                dateFormat="yyyy-mm-dd"
                onChange={(date: any) => {
                  setDate(date);
                }}
                customInput={
                  <div className="py-2 px-4 bg-card-bgGreyMedium rounded-[8px] h-[60px] w-full cursor-pointer flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center">
                      <p className="text-text-subGrey text-xs font-gilroyMedium text-left">
                        Draft Date
                      </p>
                      <p className="text-text-white text-base font-gilroyMedium text-left mt-[2px] sm:text-nowrap">
                        {date ? moment(date).format("LL") : "Select draft date"}
                      </p>
                    </div>
                    <Calendar />
                  </div>
                }
              />
              <div className="w-4" />
              <div className="py-2 px-4 bg-card-bgGreyMedium rounded-[8px] h-[60px] w-full cursor-pointer flex flex-row justify-between items-center">
                <div className="flex flex-col justify-center">
                  <p className="text-text-subGrey text-xs font-gilroyMedium text-left">
                    Draft Time ({getTimezoneAbbr()})
                  </p>
                  <p className="text-text-white text-base font-gilroyMedium text-left mt-[2px] sm:text-nowrap">
                    <div className="flex items-center gap-2">
                      <Input
                        value={hours}
                        placeholder=""
                        maxLength={2}
                        onChange={(e) => {
                          if (
                            isNumeric(e?.target?.value) &&
                            Number(e?.target?.value) <= 12 &&
                            e?.target?.value !== "00"
                          ) {
                            setHours(e?.target?.value);
                          } else if (
                            !e?.target?.value &&
                            e?.target?.value !== "00"
                          ) {
                            setHours(e?.target?.value);
                          }
                        }}
                        containerClassName={
                          "!w-[34px] !h-[30px] !px-2 !py-0 border-none rounded-[8px] !bg-card-bgGreyLight !pl-1 flex"
                        }
                        inputClassName="text-center"
                      />
                      <p className="font-gilroyBold">:</p>
                      <Input
                        value={minutes}
                        placeholder=""
                        maxLength={2}
                        onChange={(e) => {
                          if (
                            isNumeric(e?.target?.value) &&
                            Number(e?.target?.value) < 60
                          ) {
                            setMinutes(e?.target?.value);
                          } else if (!e?.target?.value) {
                            setMinutes(e?.target?.value);
                          }
                        }}
                        containerClassName={
                          "!w-[34px] !h-[30px] !px-1 !py-0 border-none rounded-[8px] !bg-card-bgGreyLight"
                        }
                        inputClassName="text-center"
                      />

                      <div className=" rounded-[6px] flex items-center">
                        <div
                          onClick={() => setIsAm(!isAm)}
                          className={clsx(
                            "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[28px] h-[28px] rounded-[6px]",
                            isAm &&
                              "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                          )}
                        >
                          <p className="font-gilroyBold">AM</p>
                        </div>
                        <div
                          onClick={() => setIsAm(!isAm)}
                          className={clsx(
                            "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[28px] h-[28px] rounded-[6px]",
                            !isAm &&
                              "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                          )}
                        >
                          <p className="font-gilroyBold">PM</p>
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
                <Time className="hidden sm:block" />
              </div>
            </div>
            <p className="mt-8 text-left text-xs text-modal-label forn-gilroyMedium">
              Draft Type
            </p>
            <div className="flex items-center mt-2 cursor-pointer">
              <Radio />
              <p className="ml-2 text-modal-label forn-gilroyMedium">Snake</p>
            </div>
            <Controller
              control={control}
              name="timeForEachTeam"
              render={({ field: { ref: _, ...restField } }) => (
                <div className="flex flex-col gap-2 w-full relative mt-6">
                  <p className="absolute z-[1] top-[12px] left-[16px] font-gilroyMedium !text-text-subGrey !text-xs">
                    Selection Time For Each Team
                  </p>
                  <CheckboxSelect
                    {...restField}
                    isMultiSelect={false}
                    placeholder="Select time for each team"
                    overrideControlSyles={{
                      minHeight: "60px",
                      padding: 0,
                      borderRadius: 8,
                      borderWidth: 0,
                      backgroundColor: colors?.modal?.inputBg,
                      paddingTop: 20,
                    }}
                    options={[
                      { label: "60 Seconds", value: "60" },
                      { label: "90 Seconds", value: "90" },
                    ]}
                  />
                  {errors?.timeForEachTeam?.message && (
                    <ErrorMsg
                      error={errors?.timeForEachTeam?.message as string}
                    />
                  )}
                </div>
              )}
            />
          </div>
        )}

        <div className="flex flex-col gap-4 md:flex-row mt-4">
          <Button
            onClick={closeModal}
            size="md"
            mode="secondary"
            className="!text-base w-full !rounded-[8px]"
          >
            {isThirdTab ? "Schedule Later" : "Cancel"}
          </Button>
          <Button
            disabled={
              isThirdTab &&
              (!getValues("name") ||
                !getValues("entryFee") ||
                !getValues("numberOfTeams") ||
                !getValues("teamsEliminatedPerWeek") ||
                !getValues("prizeFundFirstPlace") ||
                !getValues("prizeFundSecondPlace") ||
                !getValues("timeForEachTeam")?.value ||
                !date ||
                isLoading ||
                isPendingCreate ||
                isPendingUpdate)
            }
            onClick={onSubmit}
            size="md"
            className="!text-base w-full !rounded-[8px]"
            loading={isLoading}
            leftIcon={
              <Oval
                visible={isPendingCreate || isPendingUpdate}
                height="20"
                width="20"
                color="#000000"
                secondaryColor="#ffffff"
              />
            }
          >
            {isThirdTab
              ? isEdit
                ? "Save Changes"
                : "Create League"
              : " Next Step"}
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddNewLeague;
