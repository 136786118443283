import TableCardsListing from "../../../components/TableCardsListing/TableCardsListing";
import useTransactionHistoryHook from "./hooks/useTransactions";
import DateFilter from "./components/DateFilter";
import Filters from "../../../components/Filters/Filters";
import { Button } from "../../../components";
import { ReactComponent as ExportIcon } from "../../../assets/icons/export.svg";
import {Oval} from "react-loader-spinner";

const Transactions = () => {
  const {
    columns,
    filter,
    onChangeFilter,
    transactions,
    isFetching,
    filters,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    downloadTransactionsTable,
    isExportLoading
  } = useTransactionHistoryHook();

  return (
    <>
      <div className="hidden px-10 pb-6 mt-6 md:block">
        <div className="w-full flex justify-between">
          <div className="flex ml-auto items-center gap-3">
            <Button
              disabled={isExportLoading}
              leftIcon={isExportLoading
                  ? <Oval
                  visible={isExportLoading}
                  height="20"
                  width="20"
                  color="#000000"
                  secondaryColor="#ffffff"/>
                  : <ExportIcon />}
              loading={isExportLoading}
              className="rounded-[8px] w-full md:w-[150px] px-4 text-nowrap !h-10 xl:auto"
              mode="secondary"
              onClick={downloadTransactionsTable}
            >
              Export
            </Button>
          </div>
        </div>
        <div className="mt-6 flex gap-6">
          <div className="w-full">
            <div className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-start items-center">
              <div className="relative flex flex-row justify-between items-center gap-[32px]">
                <div className="flex items-center gap-2">
                  {filters?.map((item) => (
                    <Filters
                      key={item?.value}
                      name={item?.name}
                      value={item?.value}
                      filters={item?.filters}
                    />
                  ))}
                </div>
                <div className="flex items-center gap-2"></div>

                <DateFilter
                  fromDate={fromDate}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                />
              </div>
            </div>
            <TableCardsListing
              columns={columns}
              onClickRow={() => {}}
              dataSource={transactions ?? []}
              overrideTableContainerClass=""
              loading={isFetching}
              paginationData={{
                page: filter?.page as number,
                limit: filter?.limit as number,
                count: transactions?.length ?? 0,
                onChange: (params) => {
                  onChangeFilter(params);
                },
              }}
              isPlayers
            />
          </div>
        </div>
      </div>

      <div className="block px-[16px] pb-6 mt-6 md:hidden">
        <div className="w-[100%] flex flex-col gap-6 justify-between">
          <div className="flex items-center gap-3">
            <Button
              disabled={isExportLoading}
              leftIcon={isExportLoading
                  ? <Oval
                    visible={isExportLoading}
                    height="20"
                    width="20"
                    color="#000000"
                    secondaryColor="#ffffff"/>
                  : <ExportIcon />}
              loading={isExportLoading}
              className="rounded-[8px] w-full md:w-[150px] px-4 text-nowrap !h-10 xl:auto"
              mode="secondary"
              onClick={downloadTransactionsTable}
            >
              Export
            </Button>
          </div>
        </div>

        <div className="mt-6 flex gap-6">
          <div className="w-full">
            <div className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-start items-center">
              <div className="relative flex flex-row justify-between items-center gap-[29px] md:gap-[35px]">
                <div className="flex items-center gap-2">
                  <p className="font-gilroyMedium">Type</p>
                  {filters?.map((item) => (
                    <Filters
                      key={item?.value}
                      name={item?.name}
                      value={item?.value}
                      filters={item?.filters}
                    />
                  ))}
                </div>
                <div className="flex items-center gap-2"></div>

                <DateFilter
                  fromDate={fromDate}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                />
              </div>
            </div>

            <div className="overflow-x-auto">
              <div className="w-[800px] pb-4">
                <TableCardsListing
                  columns={columns}
                  onClickRow={() => {}}
                  dataSource={transactions ?? []}
                  overrideTableContainerClass=""
                  loading={isFetching}
                  paginationData={{
                    page: filter?.page as number,
                    limit: filter?.limit as number,
                    count: transactions?.length ?? 0,
                    onChange: (params) => {
                      onChangeFilter(params);
                    },
                  }}
                  isPlayers
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
