import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import clsx from "clsx";

const UserCard = ({ user }: { user: any }) => {
  return (
    <div className="flex justify-between items-center my-2">
      <div className="w-[70%] gap-2 flex items-center">
        <img
          src={user?.profileUrl || demo_team_logo}
          alt="CutThroat"
          className="w-[38px] h-[38px] rounded-[50px] mr-2"
        />
        <div className="w-fit flex flex-col gap-2">
          <p className="text-base font-gilroyBold">{user?.name}</p>
          <p className="text-text-grey text-xs font-gilroyMedium capitalize">
            {user?.transaction?.[0]?.type}
          </p>
        </div>
      </div>
      <div>
        {user?.transaction?.length ? (
          <p
            className={clsx(
              "w-fit px-1 text-text-red font-gilroyBold",
              (user?.transaction?.[0]?.type === "deposit" ||
                user?.transaction?.[0]?.type
                  ?.toLocaleLowerCase()
                  ?.includes("win") ||
                user?.transaction?.[0]?.type === "deposit from admin" ||
                user?.transaction?.[0]?.type === "transfer from admin" ||
                user?.transaction?.[0]?.type === "credit from admin" ||
                user?.transaction?.[0]?.type === "refund") &&
                "!text-text-green"
            )}
          >
            {user?.transaction?.[0]?.type === "deposit" ||
            user?.transaction?.[0]?.type
              ?.toLocaleLowerCase()
              ?.includes("win") ||
            user?.transaction?.[0]?.type === "deposit from admin" ||
            user?.transaction?.[0]?.type === "transfer from admin" ||
            user?.transaction?.[0]?.type === "credit from admin" ||
            user?.transaction?.[0]?.type === "refund"
              ? "+"
              : "-"}
            ${user?.transaction?.[0]?.amount}
          </p>
        ) : null}
      </div>
    </div>
  );
};
export default UserCard;
