import { Modal, useModal } from "../../../../components";
import player_demo from "../../../../assets/images/player_demo.png";
import usePlayerDetailsHook from "../hooks/usePlayerDetails";
import clsx from "clsx";
import TableStatisticListing from "../../../../components/TableCardsListing/TableStatisticListing";
import { isValidImg } from "../../../../utils/helpers";
import { LoadingSkeleton } from "../../../../components/LoadingSkeleton/LoadingSkeleton";
import { Oval } from "react-loader-spinner";

export const PlayerDetailsModal = ({
  id,
  children,
  enabled = true,
  leagueId,
}: {
  id: string;
  children: any;
  enabled?: boolean;
  leagueId: string;
}) => {
  const addContent = () => <PlayerDetails id={id} leagueId={leagueId} />;
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "full",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <div onClick={enabled ? openModal : () => {}}>{children}</div>
    </>
  );
};

const PlayerDetails = ({ id, leagueId }: { id: string; leagueId: string }) => {
  const {
    data,
    columnsFantasy,
    isFetching,
    columnsPass,
    columnsRushing,
    activeTab,
    setActiveTab,
    columnsReceiving,
    week,
    isFetchingStats,
    isError,
    allData,
  } = usePlayerDetailsHook({ id, leagueId });

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center">
        {!isFetching ? (
          <img
            src={isValidImg(data?.player?.officialImageSrc) || player_demo}
            alt="CutThroat"
            className="w-[100px] h-[100px] rounded-[30px] mr-2 md:w-[160px] md:h-[160px] md:mr-6"
          />
        ) : (
          <div className="w-[100px] h-[100px] md:w-[160px] md:h-[160px] mr-2 md:mr-6 flex justify-center items-center">
            <Oval
              visible={isFetching}
              height="40"
              width="40"
              color="#000000"
              secondaryColor="#ffffff"
            />
          </div>
        )}
        <div className="hidden flex-col gap-4 md:flex">
          {isFetching ? (
            <LoadingSkeleton
              className="w-[200px]"
              lineHeight={32}
              lineCount={1}
            />
          ) : (
            <p className="text-[32px] font-absoluteEmpire">
              {data?.player?.firstName} {data?.player?.lastName}
            </p>
          )}
          <div className="h-[40px] rounded-[8px] bg-modal-inputBg w-[50%] flex items-center">
            <div className="w-[40px] h-[40px] rounded-[8px] bg-gradient-to-br from-red-light-1 to-red-light-2 flex justify-center items-center">
              <p className="font-gilroyMedium">
                {data?.player?.primaryPosition}
              </p>
            </div>
            <p className="ml-3 text-base font-gilroyMedium">
              {data?.player?.currentTeam?.abbreviation || ""} #
              {data?.player?.jerseyNumber}
            </p>
          </div>
          <div className="flex">
            <div className="flex flex-col justify-center items-center pr-3">
              <p className="text-text-subGrey font-gilroyMedium">Age</p>
              {isFetching ? (
                <LoadingSkeleton
                  className="w-[20px] mt-1"
                  lineCount={1}
                  lineHeight={18}
                />
              ) : (
                <p className="text-base font-gilroyBold mt-1">
                  {data?.player?.age}
                </p>
              )}
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
            <div className="flex flex-col justify-center items-center px-3">
              <p className="text-text-subGrey font-gilroyMedium">Height</p>
              {isFetching ? (
                <LoadingSkeleton
                  className="w-[30px] mt-1"
                  lineCount={1}
                  lineHeight={18}
                />
              ) : (
                <p className="text-base font-gilroyBold mt-1">
                  {data?.player?.height}
                </p>
              )}
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
            <div className="flex flex-col justify-center items-center px-3">
              <p className="text-text-subGrey font-gilroyMedium">Weight</p>
              {isFetching ? (
                <LoadingSkeleton
                  className="w-[30px] mt-1"
                  lineCount={1}
                  lineHeight={18}
                />
              ) : (
                <p className="text-base font-gilroyBold mt-1">
                  {data?.player?.weight} lbs
                </p>
              )}
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
            <div className="flex flex-col justify-center items-center px-3">
              <p className="text-text-subGrey font-gilroyMedium">College</p>
              {isFetching ? (
                <LoadingSkeleton
                  className="w-[40px] mt-1"
                  lineCount={1}
                  lineHeight={18}
                />
              ) : (
                <p className="text-base font-gilroyBold mt-1">
                  {data?.player?.college}
                </p>
              )}
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
          </div>
        </div>
        <p className="block text-[22px] font-absoluteEmpire md:hidden">
          {data?.player?.firstName} {data?.player?.lastName}
        </p>
      </div>
      <div className="block w-[100%] flex flex-col gap-3 md:hidden">
        <div className="h-[40px] rounded-[8px] bg-modal-inputBg w-[50%] flex items-center">
          <div className="w-[40px] h-[40px] rounded-[8px] bg-gradient-to-br from-red-light-1 to-red-light-2 flex justify-center items-center">
            <p className="font-gilroyMedium">{data?.player?.primaryPosition}</p>
          </div>
          <p className="ml-3 text-base font-gilroyMedium">
            {data?.player?.currentTeam?.abbreviation || ""} #
            {data?.player?.jerseyNumber}
          </p>
        </div>
        <div className="w-[100%] flex items-start mt-2">
          <div className="flex flex-col justify-center items-center pr-[7px]">
            <p className="text-text-subGrey font-gilroyMedium">Age</p>
            <p className="text-base font-gilroyBold mt-1">
              {data?.player?.age}
            </p>
          </div>
          <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
          <div className="flex flex-col justify-center items-center px-[7px]">
            <p className="text-text-subGrey font-gilroyMedium">Height</p>
            <p className="text-base font-gilroyBold mt-1">
              {data?.player?.height}
            </p>
          </div>
          <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
          <div className="flex flex-col justify-center items-center px-[7px]">
            <p className="text-text-subGrey font-gilroyMedium">Weight</p>
            <p className="text-base font-gilroyBold mt-1">
              {data?.player?.weight} lbs
            </p>
          </div>
          <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
          <div className="flex flex-col justify-center items-center px-[7px]">
            <p className="text-text-subGrey font-gilroyMedium">College</p>
            <p className="text-base font-gilroyBold mt-1">
              {data?.player?.college}
            </p>
          </div>
          <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
        </div>
      </div>

      <div className="mt-7">
        <div className="flex gap-6">
          <p
            onClick={() => setActiveTab(1)}
            className={clsx(
              "text-base text-text-subGrey cursor-pointer",
              activeTab === 1 && "text-text-white font-gilroyBold"
            )}
          >
            Game Logs
          </p>
          {data?.player?.currentInjury && (
            <p
              onClick={() => setActiveTab(2)}
              className={clsx(
                "text-base text-text-subGrey cursor-pointer",
                activeTab === 2 && "text-text-white font-gilroyBold"
              )}
            >
              Injuries
            </p>
          )}
        </div>
      </div>

      {activeTab === 1 && (
        <>
          {allData?.length ? (
            <div className="overflow-x-auto">
              <div className="flex justify-between my-6 min-w-[900px] max-w-[1600px]">
                <div className="w-[30%]">
                  <div className="flex items-center">
                    <div className="w-full h-[1px] bg-text-red mr-4" />
                    <p className="text-xs text-text-red font-gilroyMedium">
                      Fantasy
                    </p>
                    <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                  </div>
                  <TableStatisticListing
                    columns={columnsFantasy}
                    onClickRow={() => {}}
                    dataSource={!isError && allData?.length ? allData : []}
                    overrideTableContainerClass="!px-0 mr-1 !rounded-[0px]"
                    containerClass="border-0"
                    loading={isFetching}
                    isPlayers
                  />
                </div>
                <div className="w-[30%]">
                  <div className="flex items-center">
                    <div className="w-full h-[1px] bg-text-red mr-4" />
                    <p className="text-xs text-text-red font-gilroyMedium">
                      Passing
                    </p>
                    <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                  </div>
                  <TableStatisticListing
                    columns={columnsPass}
                    onClickRow={() => {}}
                    dataSource={!isError && allData?.length ? allData : []}
                    overrideTableContainerClass="!px-0 mr-1 !rounded-[0px]"
                    containerClass="border-0"
                    loading={isFetching}
                    isPlayers
                  />
                </div>
                <div className="w-[20%]">
                  <div className="flex items-center">
                    <div className="w-full h-[1px] bg-text-red mr-4" />
                    <p className="text-xs text-text-red font-gilroyMedium">
                      Rushing
                    </p>
                    <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                  </div>
                  <TableStatisticListing
                    columns={columnsRushing}
                    onClickRow={() => {}}
                    dataSource={!isError && allData?.length ? allData : []}
                    overrideTableContainerClass="!px-0 mr-1 !rounded-[0px]"
                    containerClass="border-0"
                    loading={isFetching}
                    isPlayers
                  />
                </div>
                <div className="w-[20%]">
                  <div className="flex items-center">
                    <div className="w-full h-[1px] bg-text-red mr-4" />
                    <p className="text-xs text-text-red font-gilroyMedium">
                      Receiving
                    </p>
                    <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                  </div>
                  <TableStatisticListing
                    columns={columnsReceiving}
                    onClickRow={() => {}}
                    dataSource={!isError && allData?.length ? allData : []}
                    overrideTableContainerClass="!px-0 mr-1 !rounded-[0px]"
                    containerClass="border-0"
                    loading={isFetching}
                    isPlayers
                  />
                </div>
              </div>
            </div>
          ) : (
            <p className="text-text-subGrey font-gilroyMedium">No logs found</p>
          )}
          {week <= 18 && isFetchingStats && (
            <LoadingSkeleton className="w-full" lineCount={1} />
          )}
        </>
      )}
      {activeTab === 2 && (
        <>
          {data?.player?.currentInjury ? (
            <p className="text-text-white font-gilroyBold my-6 text-left">
              Description: {data?.player?.currentInjury?.description}
            </p>
          ) : (
            <p className="text-text-subGrey font-gilroyMedium">
              No injuries found
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default PlayerDetailsModal;
