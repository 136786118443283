import { Oval } from "react-loader-spinner";
import useCountdown from "../../../../hooks/useCountdown";

const Timer = ({ draftTime }: { draftTime: string }) => {
  const [days, hours, minutes, seconds] = useCountdown(draftTime);
  return (
    <div className="flex justify-center items-center gap-2 font-absoluteEmpire text-[18px] text-center wrap">
      <p>Time left:</p>
      {seconds < 0 ? (
        <Oval
          visible={seconds < 0}
          height="20"
          width="20"
          color="#000000"
          secondaryColor="#ffffff"
        />
      ) : (
        <>
          {days > 0 ? (
            <p>
              {days} {days === 1 ? "Day" : "Days"},
            </p>
          ) : null}
          {hours > 0 ? (
            <p>
              {hours} {hours === 1 ? "Hour" : "Hours"},
            </p>
          ) : null}
          {minutes > 0 ? (
            <p>
              {minutes} {minutes === 1 ? "Minute" : "Minutes"},
            </p>
          ) : null}
          <p>
            {minutes < 0 || isNaN(seconds) ? 0 : seconds}{" "}
            {seconds === 1 ? "Second" : "Seconds"}
          </p>
        </>
      )}
    </div>
  );
};
export default Timer;
