import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Settings } from "../../../../assets/icons/settings.svg";
import demo_logo from "../../../../assets/images/demoLogo.png";
import useLeagueHook from "../hooks/useLeague";
import useAuthStore from "../../../../stores/auth/auth";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { PaymentsServices } from "../../../../services/apis/Payments/PaymentsServices";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchUserDetails } from "../../../../queries/userQueries/userQueries";
import moment from "moment";
import { Oval } from "react-loader-spinner";

export const LeagueRegistrationModal = ({
  league,
  isLeagueView,
}: {
  league: any;
  isLeagueView?: boolean;
}) => {
  const { userData } = useAuthStore.getState();
  const navigate = useNavigate();
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <LeagueRegistration league={league} closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: league?.entryFee !== "0.00" ? "League Registration" : "Join League",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });

  return (
    <>
      <Modal {...modalControl} />
      {isLeagueView ? (
        <Button
          leftIcon={<Settings />}
          className="rounded-[8px] w-auto px-4 text-nowrap !h-10"
          onClick={userData?.id ? openModal : () => navigate("/login")}
        >
          Join League
        </Button>
      ) : (
        <>
          <Button
            leftIcon={<Settings />}
            className="rounded-[8px] w-full px-4 text-nowrap !h-10 block xl:hidden"
            onClick={userData?.id ? openModal : () => navigate("/login")}
          >
            Join League
          </Button>
          <div
            onClick={userData?.id ? openModal : () => navigate("/login")}
            className="flex-col cursor-pointer hidden xl:flex"
          >
            <p className="underline text-text-red text-nowrap">Join League</p>
            <p className="text-sm text-text-subGrey">Action</p>
          </div>
        </>
      )}
    </>
  );
};

const LeagueRegistration = ({
  closeModal,
  league,
}: {
  closeModal?: () => void;
  league: any;
}) => {
  const { userData } = useAuthStore.getState();
  const { data: details } = useFetchUserDetails(userData?.id);
  const [date] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const balance =
    Number(details?.balance?.amount) + Number(details?.balance?.credit_amount);
  const [activeTab] = useState(
    balance >= Number(league?.entryFee) ||
      Number(details?.balance?.winning_amount) >= Number(league?.entryFee)
      ? 2
      : 1
  );

  const { joinLeague, isPendingJoin } = useLeagueHook(league?.id);

  const formOptions = useForm({
    defaultValues: {
      cardNumber: "",
      name: "",
    },
  });

  const {
    formState: { isValid },
    handleSubmit,
  } = formOptions;

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true);
      await PaymentsServices.makeDeposit({
        amount: league?.entryFee,
        ccNumber: data?.cardNumber,
        ccExp: moment(date).format("MMYY"),
      });
      onConfirm();
      toast.success("Deposit was successful");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  });

  const onConfirm = async () => {
    try {
      await joinLeague();
      closeModal?.();
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <>
      <form className="flex flex-col gap-4 pt-6">
        {activeTab === 1 ? (
          //Disable until payment provider will be connected

          // <div className="flex flex-col gap-4">
          //   <Controller
          //     control={control}
          //     name="cardNumber"
          //     render={({ field }) => (
          //       <LabeledInput
          //         {...field}
          //         placeholder="Enter your card number..."
          //         error={errors?.cardNumber?.message}
          //         maxLength={16}
          //         errorClassName="text-text-red"
          //         label="Card Number"
          //         labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
          //         containerClassName="relative"
          //         inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          //       />
          //     )}
          //   />
          //   <div className="flex">
          //     <Controller
          //       control={control}
          //       name="name"
          //       render={({ field }) => (
          //         <LabeledInput
          //           {...field}
          //           placeholder="Cardholder Name..."
          //           error={errors?.name?.message}
          //           errorClassName="text-text-red"
          //           label="Cardholder Name"
          //           labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
          //           containerClassName="relative mr-2"
          //           inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          //         />
          //       )}
          //     />
          //     <DatePicker
          //       showPopperArrow={false}
          //       selected={date}
          //       minDate={moment()?.toDate()}
          //       showPreviousMonths={false}
          //       onChange={(date: any) => setDate(date)}
          //       showMonthYearPicker
          //       dateFormat="MM/yy"
          //       customInput={
          //         <div className="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7 cursor-pointer">
          //           <p className="absolute top-[12px] left-[16px] text-text-subGrey text-xs font-gilroyMedium">
          //             Expiration Date
          //           </p>
          //           <p className="text-text-grey text-base font-gilroyMedium text-left sm:text-nowrap">
          //             {date
          //               ? moment(date).format("MM/YY")
          //               : "Enter Expiration Date..."}
          //           </p>
          //         </div>
          //       }
          //     />
          //   </div>
          //   <div className="w-full h-[1px] bg-modal-modalStroke mt-4" />
          //   <div className="flex justify-between items-center">
          //     <p className="text-base">Total:</p>
          //     <p className="text-[24px] font-absoluteEmpire">
          //       ${league?.entryFee}
          //     </p>
          //   </div>
          // </div>
          <p className="text-text-subGrey font-gilroyMedium">
            We currently do not accept payments, please contact admin for funds
            to be added to your account at{" "}
            <a href="mailto:cutthroatfantasysports@gmail.com">
              <span className=" underline cursor-pointer">
                cutthroatfantasysports@gmail.com
              </span>
            </a>
          </p>
        ) : (
          <div className="flex flex-col items-center w-full mt-6">
            <img
              src={league?.logo || demo_logo}
              alt="CutThroat"
              className="w-[100px] h-[100px] rounded-[50px]"
            />
            <p className="text-[24px] font-gilroyBold mt-4">
              Congratulations, {userData?.name}!
            </p>
            <p className="font-gilroyMedium text-text-subGrey mt-2">
              You are about to join the league. Please confirm your{" "}
              <span className="text-text-red">${league?.entryFee}</span> entry.
            </p>
          </div>
        )}

        <div className="flex flex-col gap-4 md:flex-row">
          <Button
            onClick={closeModal}
            size="md"
            mode="secondary"
            className="!text-base w-full !rounded-[8px]"
          >
            Cancel
          </Button>
          <Button
            leftIcon={
              <Oval
                visible={isPendingJoin || loading}
                height="20"
                width="20"
                color="#000000"
                secondaryColor="#ffffff"
              />
            }
            onClick={activeTab === 1 ? onSubmit : onConfirm}
            size="md"
            disabled={
              (activeTab === 1 && (!isValid || !date)) ||
              isPendingJoin ||
              loading
            }
            className="!text-base w-full !rounded-[8px]"
          >
            {activeTab === 1 ? "Register" : "Confirm"}
          </Button>
        </div>
      </form>
    </>
  );
};

export default LeagueRegistrationModal;
