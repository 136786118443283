import { forwardRef } from "react";

import clsx from "clsx";

import CheckboxSvg from "../../assets/icons/checkbox-square.svg";
import CheckboxCheckedSvg from "../../assets/icons/checkbox-checked.svg";

import { CheckboxPropsType } from "./type";

export const Checkbox = forwardRef<HTMLInputElement, CheckboxPropsType>(
  (
    { children = "", containerClassName, ...restProps }: CheckboxPropsType,
    ref
  ) => {
    return (
      <label
        htmlFor={restProps?.id}
        className={`selector-container flex flex-row items-center ${
          containerClassName ?? ""
        }`}
      >
        <div className="w-6 h-6 relative">
          <input
            ref={ref}
            className={clsx(
              "peer",
              "z-10 rounded-md cursor-pointer relative appearance-none w-full h-full"
            )}
            {...restProps}
            type="checkbox"
          />
          <div
            className={clsx(
              "peer-checked:border-0 peer-checked:bg-primary-1-light",
              "border-transparent bg-transparent absolute top-0 left-0 border-neutral-white-6 rounded-md w-full h-full"
            )}
          />

          <img
            alt="square"
            src={restProps?.checked ? CheckboxCheckedSvg : CheckboxSvg}
            className={clsx(
              "peer-checked:inline",
              "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            )}
          />
        </div>
        {children}
      </label>
    );
  }
);
