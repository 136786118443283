import { useParams } from "react-router-dom";
import { getSocket } from "../../../../socket";
import { useEffect, useState } from "react";
import { DraftServices } from "../../../../services/apis/Draft/DraftServices";

const useDraftCountdown = (
  currentTime?: number,
  refetch?: () => void,
  isSelectedPlayers?: boolean
) => {
  const { id } = useParams();
  const socket = getSocket();
  const [loading, setLoading] = useState(false);
  const [currentCountDownTime, setCountDown] = useState(currentTime);

  const getCurrentTime = async () => {
    try {
      setLoading(true);
      const timeData = await DraftServices.getDraftCurrentTime(id!);
      const time =
        timeData?.remainingTime * 1000 -
        (Date.now()?.valueOf() - new Date(timeData?.serverTime)?.valueOf());
      setCountDown(time > 0 ? time : 0);
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentTime();
  }, [id, currentTime]);

  useEffect(() => {
    socket?.emit("joinDraft", id);
    const handleDraftPick = async (data: any) => {
      if (data?.draftId === id) {
        await getCurrentTime();
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
        refetch?.();
      }
    };
    socket?.on("draft-pick", handleDraftPick);

    return () => {
      socket?.off("draft-pick", handleDraftPick);
    };
  }, [id, isSelectedPlayers, currentTime]);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setCountDown(
        currentCountDownTime! >= 1000 ? currentCountDownTime! - 1000 : 0
      );
    }, 1000);
    if (currentCountDownTime! <= 0) {
      clearInterval(interval);
      setLoading(true);
    }

    return () => clearInterval(interval);
  }, [currentCountDownTime, currentTime]);

  return {
    roundSeconds: getReturnValues(currentCountDownTime!),
    setCountDown,
    loading,
  };
};

const getReturnValues = (countDown: number) => {
  const roundSeconds = Math.floor(countDown / 1000);

  return roundSeconds;
};

export default useDraftCountdown;
