import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Edit } from "../../../../assets/icons/edit-icon.svg";
import { Controller } from "react-hook-form";
import LabeledInput from "../../../../components/LabeledInput/LabeledInput";
import useEditTeamHook from "../hooks/useEditTeam";
import { ReactComponent as Pencil } from "../../../../assets/icons/pencil.svg";
import demo_img from "../../../../assets/images/demoTeamLogo.png";
import { Oval } from "react-loader-spinner";

export const EditTeamModal = ({
  name,
  logo,
}: {
  name?: string;
  logo?: string;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <EditTeam name={name} logo={logo} closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "Edit Team",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        leftIcon={<Edit />}
        mode="secondary"
        onClick={openModal}
        className="rounded-[8px] w-auto px-4 text-nowrap !h-10"
      >
        <div className="hidden md:block"> Edit Team</div>
      </Button>
    </>
  );
};

const EditTeam = ({
  closeModal,
  name,
  logo,
}: {
  closeModal?: () => void;
  name?: string;
  logo?: string;
}) => {
  const { control, errors, onSubmit, handleTeamLogo, isPending } =
    useEditTeamHook({
      closeModal,
      name,
    });

  return (
    <>
      <div className="flex justify-center items-center mb-[32px] mt-[16px]">
        <div className="relative">
          <img
            src={logo || demo_img}
            alt="CutThroat"
            className="w-[100px] h-[100px] rounded-[50px]"
          />
          <div className="absolute bottom-0 right-0 cursor-pointer">
            <label className="cursor-pointer">
              <input type="file" onChange={handleTeamLogo} accept="image/*" />
              <Pencil />
            </label>
          </div>
        </div>
      </div>
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <LabeledInput
            {...field}
            placeholder="Enter team name"
            error={errors?.name?.message}
            errorClassName="text-text-red"
            label="Team Name"
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-[28px]"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
      />
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          disabled={isPending}
          leftIcon={
            <Oval
              visible={isPending}
              height="20"
              width="20"
              color="#000000"
              secondaryColor="#ffffff"
            />
          }
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Save Changes
        </Button>
      </div>
    </>
  );
};

export default EditTeam;
