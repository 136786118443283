import { Button, Modal, useModal } from "../../../../components";
import useMoveTeamsHook from "../hooks/useMoveTeams.tsx";
import { Table } from "../../../../components/Table/Table.tsx";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/arrow-down.svg";
import { ReactComponent as RightIcon } from "../../../../assets/icons/draft-arrow-right.svg";
import { useState } from "react";
import leagueLogo from "../../../../assets/images/ball-logo.png";

const MoveTeamsModal = () => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <MoveTeams closeModal={closeModal} />
  );

  const { modalControl, openModal } = useModal({
    title: "Moving Teams",
    content: addContent,
    size: "xxl",
    showCloseIcon: true,
  });

  return (
    <>
      <Modal {...modalControl} />
      <div
        className="font-gilroyMedium text-text-red cursor-pointer pl-4"
        onClick={openModal}
      >
        Move Teams
      </div>
    </>
  );
};

export default MoveTeamsModal;

const MoveTeams = ({ closeModal }: { closeModal: () => void }) => {
  const {
    columnsLeagues,
    columnsSelectedLeagues,
    league,
    selectedTeams,
    clearAll,
    selectAll,
    sendInvites,
    selectLeague,
    selectedLeague,
    leaguesBySelect,
  } = useMoveTeamsHook();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mt-6 flex flex-col gap-3">
      <div className="flex flex-col gap-4 items-start h-full md:flex-row">
        <div className="flex flex-col gap-4 w-[100%] h-full items-start md:w-[48%]">
          <div className="text-3xl text-text-white my-3">Source League</div>
          <div className="w-[100%] flex flex-col gap-2 bg-card-bgGreyMedium p-4 rounded-[8px] items-start">
            <div className="text-xs">League</div>
            <div className="text-xl text-text-white">
              {league?.league?.name}
            </div>
          </div>
          <div className="w-[100%] flex flex-col">
            <div className="flex justify-between mb-[1px] items-center p-6 bg-card-bgGreyMedium rounded-t-[8px] ">
              <div className="text-lg text-text-white">League's Teams</div>
              <div
                className="font-gilroyMedium text-text-red cursor-pointer"
                onClick={selectAll}
              >
                Select all
              </div>
            </div>
            <Table
              columns={columnsLeagues}
              dataSource={league?.league?.team}
              size="lg"
              onClickRow={() => {}}
              overrideTableContainerClass="bg-card-bgGreyMedium h-[200px] rounded-b-[8px] overflow-y-auto md:h-[400px]"
            />
          </div>
        </div>
        <div className="flex my-4 gap-5 w-[100%] h-[7px] items-center h-[100%] md:w-[4%] md:h-full md:flex-col md:my-0">
          <div className="w-[48%] h-full bg-card-bgGreyMedium rounded-[8px] md:h-[285px] md:w-[7px]"></div>
          <div className="items-center">
            <RightIcon className={"rotate-90 md:rotate-0"} />
          </div>
          <div className="w-[48%] h-full bg-card-bgGreyMedium rounded-[8px] md:h-[285px] md:w-[7px]"></div>
        </div>
        <div className="flex flex-col gap-4 w-[100%] h-full items-start md:w-[48%]">
          <div className="text-3xl text-text-white my-3">
            Destination League
          </div>
          <div
            className={`w-[100%] h-full flex justify-between bg-card-bgGreyMedium p-4 ${
              isOpen ? "rounded-t-[8px]" : "rounded-[8px]"
            } items-center relative cursor-pointer`}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <div className="w-[100%] flex flex-col gap-2 items-start">
              <div className="text-xs">League</div>
              <div className="text-xl text-text-white">
                {selectedLeague ? selectedLeague?.name : "Select League"}
              </div>
            </div>
            {isOpen && (
              <div className="w-[100%] mt-[2px] absolute max-h-[350px] overflow-y-auto left-0 top-[100%] border-y border-card-strokeGradient-1 bg-card-bgGreyMedium rounded-b-[8px] z-50 px-3">
                {leaguesBySelect.map((data: any) => (
                  <div
                    className="my-4 px-4 flex items-center gap-4 cursor-pointer border p-2 rounded-[8px] border-card-strokeGradient-1"
                    onClick={() => selectLeague(data)}
                  >
                    <img
                      className="w-[40px] h-[40px] rounded-full"
                      src={data?.logoUrl || leagueLogo}
                      alt="League logo"
                    />
                    <div className="text-xl">{data?.name}</div>
                  </div>
                ))}
              </div>
            )}
            <ArrowDown className={isOpen ? "rotate-180" : ""} />
          </div>

          <div className="w-[100%] flex flex-col">
            <div className="flex justify-between items-center p-6 bg-card-bgGreyMedium rounded-t-[8px] mb-[1px]">
              <div className="text-lg text-text-white">Moving Teams</div>
              <div
                className="font-gilroyMedium text-text-red cursor-pointer"
                onClick={clearAll}
              >
                Clear all
              </div>
            </div>
            <Table
              columns={columnsSelectedLeagues}
              dataSource={selectedTeams}
              size="lg"
              onClickRow={() => {}}
              overrideTableContainerClass="bg-card-bgGreyMedium h-[200px] rounded-b-[8px] overflow-y-auto md:h-[400px]"
            />
          </div>
        </div>
      </div>
      <div className="text-sm my-3">
        Teams will receive a notification to confirm their participation. This
        action can not be undone
      </div>
      <div className="flex gap-2">
        <Button
          mode="primary"
          className="w-full rounded-[8px]"
          onClick={closeModal}
        >
          Back
        </Button>
        <Button
          mode="secondary"
          className="w-full rounded-[8px]"
          onClick={() => sendInvites(closeModal)}
        >
          Send invites
        </Button>
      </div>
    </div>
  );
};
