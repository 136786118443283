import useAuthStore from "../../../stores/auth/auth";
import axios from "axios";

export const PlayerServices = {
  getAvailablePlayers: async (leagueId: string, params: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
      params,
    };
    const endPoint = `/api/game/player/available/${leagueId}`;
    try {
      const availablePlayers = await axios.get(endPoint, config);
      return availablePlayers?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  addPlayerToTeam: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/game/player/add/${payload?.teamId}`;
    try {
      delete payload?.teamId;
      const availablePlayers = await axios.post(
        endPoint,
        {
          player_id: payload?.player_id,
        },
        config
      );
      return availablePlayers?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getPlayerById: async (playerId: string, leagueId: string) => {
    const endPoint = `/api/game/player/${playerId}`;
    try {
      const player = await axios.get(endPoint, {
        params: { leagueId: leagueId },
      });
      return player?.data?.message ? player?.data?.message : null;
    } catch (error) {
      throw error;
    }
  },
  getPlayerStatsByName: async (firstName: string, lastName: string) => {
    const endPoint = `/api/game/player/stats/player`;
    try {
      const player = await axios.get(endPoint, {
        params: {
          firstName,
          lastName,
          latest: true,
        },
      });
      return player?.data?.message || null;
    } catch (error: any) {
      throw error;
    }
  },
  getPlayerInjuresByName: async (firstName: string, lastName: string) => {
    const endPoint = `/api/game/player/injures/player`;
    try {
      const player = await axios.get(endPoint, {
        params: {
          firstName,
          lastName,
          latest: true,
        },
      });
      return player?.data?.message;
    } catch (error: any) {
      throw error?.error;
    }
  },
  removePlayer: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
      data: payload,
    };
    const endPoint = `/api/game/player/drop/${payload?.teamId}`;
    try {
      delete payload?.teamId;
      const player = await axios.delete(endPoint, config);
      return player?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  searchPlayer: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/game/player/search/name/${payload?.leagueId}`;
    delete payload?.leagueId;
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
      params: payload,
    };
    try {
      const searchPlayer = await axios.get(endPoint, config);
      return searchPlayer?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getPlayerWeeklyStats: async (payload: any, isPRJ: boolean) => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/game/player/${
      isPRJ ? "projections" : "stats"
    }/weekly/player`;

    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
      params: payload,
    };
    try {
      const searchPlayer = await axios.get(endPoint, config);
      return searchPlayer?.data?.message ? searchPlayer?.data?.message : null;
    } catch (error) {
      throw error;
    }
  },
  replacePlayer: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/game/player/replace/${payload?.teamId}`;
    delete payload?.teamId;
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    try {
      const player = await axios.post(endPoint, payload, config);
      return player?.data;
    } catch (error) {
      throw error;
    }
  },
  getTeamPlayersByWeek: async (payload: any, week: number, isPRJ: boolean) => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/game/player/${
      isPRJ ? "projections" : "stats"
    }/weekly/player/array?week=${week}`;
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    try {
      const players = await axios.post(endPoint, payload, config);
      return players?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getAllInjuries: async () => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/game/player/injuries/all`;
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    try {
      const players = await axios.get(endPoint, config);
      return players?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getInjuriesHistory: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/game/player/injuries/player?firstName=${payload?.firstName}&lastName=${payload?.lastName}`;
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    try {
      const history = await axios.get(endPoint, config);
      return history?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getAllSelectedPlayers: async (leagueId: string) => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/game/player/all?leagueId=${leagueId}`;
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    try {
      const players = await axios.get(endPoint, config);
      return players?.data?.message;
    } catch (error) {
      throw error;
    }
  },
};
