import { useState } from "react";
import {
  useUpdateTeam,
  useUploadTeamLogo,
} from "../../../../queries/teamsQueries/teamsQueries";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import demo_img from "../../../../assets/images/demoTeamLogo.png";
import { Buffer } from "buffer";

const useEditTeamHook = ({
  closeModal,
  name,
}: {
  closeModal?: () => void;
  name?: string;
}) => {
  const { id } = useParams();
  const { mutateAsync, isPending } = useUpdateTeam();
  const { mutateAsync: uploadTeamLogo } = useUploadTeamLogo();
  const [image, setImage] = useState<any>(demo_img);

  const formOptions = useForm({
    defaultValues: {
      name: name || "",
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formOptions;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await mutateAsync({
        name: data?.name,
        teamId: id,
      });
      toast.success("Team updated successfully");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      closeModal?.();
    }
  });

  const handleTeamLogo = async (e: any) => {
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(e.target.files[0]);
      reader.onload = async () => {
        // @ts-ignore
        const buffer = Buffer.from(reader.result);
        try {
          await uploadTeamLogo({
            file: buffer,
            fileName: e.target.files[0]?.name,
            fileType: e.target.files[0]?.type,
            teamId: id,
          });
          toast.success("Team Logo updated successfully");
        } catch (error: any) {
          toast.error(error?.response?.data?.error);
        }
      };
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      closeModal?.();
    }
  };

  return {
    control,
    errors,
    onSubmit,
    image,
    setImage,
    handleTeamLogo,
    isPending,
  };
};

export default useEditTeamHook;
