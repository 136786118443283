import axios from "axios";

export const MessagesServices = {
  sendMessage: async (payload: any) => {
    const endPoint = "/api/message/send";
    try {
      const newMessage = await axios.post(endPoint, payload);
      return newMessage;
    } catch (error) {
      throw error;
    }
  },
  getMessages: async (chatRoomId: any) => {
    const endPoint = "/api/message";
    try {
      const messages = await axios.post(endPoint, { chatRoomId });
      return messages?.data?.data;
    } catch (error: any) {
      throw error?.error;
    }
  },
};
