import { useFetchInjuriesHistory } from "../../../../queries/playerQueries/playerQueries";
import { Button, Modal, useModal } from "../../../../components";
import moment from "moment";
import { Oval } from "react-loader-spinner";

export const InjuriesHistoryModal = ({ injury }: { injury: any }) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <InjuriesHistory closeModal={closeModal} injury={injury} />
  );
  const { modalControl, openModal } = useModal({
    title: `Injuries History (${injury?.firstName} ${injury?.lastName})`,
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <span
        onClick={(e) => {
          e?.stopPropagation();
          openModal();
        }}
        className="ml-1 text-base text-orange-3 font-gilroyBold whitespace-nowrap cursor-pointer"
      >
        {injury?.currentInjury?.playingProbability?.[0]}
      </span>
    </>
  );
};

const InjuriesHistory = ({
  closeModal,
  injury,
}: {
  closeModal?: () => void;
  injury: any;
}) => {
  const { data, isFetching } = useFetchInjuriesHistory({
    firstName: injury?.firstName,
    lastName: injury?.lastName,
  });
  const initDate = 1725138001000;

  return (
    <>
      {isFetching ? (
        <div className="flex justify-center items-center mt-3">
          <Oval
            visible={isFetching}
            height="30"
            width="30"
            color="#000000"
            secondaryColor="#ffffff"
          />
        </div>
      ) : (
        <>
          <p className="text-[18px] font-gilroySemibold mb-2 mt-2">
            {injury?.currentInjury?.playingProbability} -{" "}
            {injury?.currentInjury?.description?.toUpperCase()}
          </p>
          <p className="font-gilroyMedium text-text-subGrey text-left">
            Current Status: {injury?.currentInjury?.playingProbability} -{" "}
            {injury?.currentInjury?.description?.toUpperCase()}
          </p>
          {data?.playerInjuries?.[0]?.injuryHistory?.length ? (
            <p className="font-gilroyMedium text-text-subGrey text-left">
              Details:
            </p>
          ) : null}
          {data?.playerInjuries?.[0]?.injuryHistory
            ?.filter(
              (item: any) => new Date(item?.asOfDate)?.getTime() > initDate
            )
            ?.sort(
              (a: any, b: any) =>
                new Date(b?.asOfDate)?.valueOf() -
                new Date(a?.asOfDate)?.valueOf()
            )
            ?.map((injury: any, index: number) => (
              <div className="flex flex-row flex-wrap mt-3" key={index}>
                <p
                  key={index}
                  className="font-gilroyMedium text-text-subGrey text-left"
                >
                  {moment(injury?.asOfDate).format("MM/DD/YYYY")} (
                  {injury?.nextGameProbability?.toUpperCase()}){" "}
                  {injury?.longDescription}
                </p>
                <div className="h-[1px] w-full bg-stroke mt-3" />
              </div>
            ))}
        </>
      )}
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Close
        </Button>
      </div>
    </>
  );
};

export default InjuriesHistoryModal;
