import moment from "moment-timezone";
import { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  useCreateLeague,
  useFetchLeague,
  useUpdateLeague,
} from "../../../../queries/leaguesQueries/leaguesQueries";
import { useCreateLeaguePhoto } from "../../../../queries/leaguesQueries/leaguesQueries.tsx";
import { Buffer } from "buffer";
import demoImg from "../../../../assets/images/demoImg.png";
import { getTimezoneAbbr } from "../../../../utils/helpers.ts";

const useAddNewLeagueHook = ({
  closeModal,
  isEdit,
  leagueId,
}: {
  closeModal?: () => void | undefined;
  isEdit?: boolean;
  leagueId?: string;
}) => {
  const { id } = useParams();
  const { data: league } = useFetchLeague(leagueId || id!);
  const { mutateAsync, isPending: isPendingUpdate } = useUpdateLeague();
  const { mutateAsync: createLeaguePhoto } = useCreateLeaguePhoto();
  const { mutateAsync: createLeague, isPending: isPendingCreate } =
    useCreateLeague();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [receivingOption, setReceivingOption] = useState<string>("PPR");
  const [date, setDate] = useState<any>(null);
  const isFirstTab = activeTab === 1;
  const isSecondTab = activeTab === 2;
  const isThirdTab = activeTab === 3;
  const [imageSrc, setImageSrc] = useState(demoImg);
  const [imageData, setImageData] = useState("");
  const [file, setFile] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [hours, setHours] = useState<any>("12");
  const [minutes, setMinutes] = useState<any>("00");
  const [isAm, setIsAm] = useState(true);

  function combineDateTime(
    dateString: string,
    hours: number,
    minutes: number,
    period: string,
    timezone: string
  ) {
    // Parse the input date string
    const date = new Date(dateString);

    // Convert to local time if necessary
    let localDate = new Date(date.getTime());
    let parsedHours = hours;
    // Determine if the period is AM or PM and adjust hours accordingly
    if (period === "PM" && parsedHours !== 12) {
      parsedHours += 12; // Convert PM hours to 24-hour format
    } else if (period === "AM" && parsedHours === 12) {
      parsedHours = 0; // Convert 12 AM to 0 hours
    }

    // Set the hours and minutes
    localDate.setHours(parsedHours);
    localDate.setMinutes(minutes);
    localDate.setSeconds(0); // Reset seconds to 0 if needed

    // Convert timezone abbreviation to UTC offset
    const timezoneOffsets = {
      PST: "-08:00", // PST is UTC-8
      PDT: "-07:00", // PDT (Daylight Saving) is UTC-7
      // Add other timezones if needed
    };
    //@ts-ignore
    const offset = timezoneOffsets[timezone];
    if (!offset) {
      throw new Error("Unsupported timezone");
    }

    const dateToFormat = moment(localDate)?.format("YYYY-MM-DD");

    const dateAndTime =
      dateToFormat +
      "T" +
      (parsedHours < 10 ? "0" + parsedHours?.toString() : parsedHours) +
      ":" +
      (minutes < 10 ? "0" + minutes?.toString() : minutes) +
      ":00" +
      offset;

    return dateAndTime;
  }

  function getHoursAndMinutes(isoString: string, timezoneOffsetHours: any) {
    // Create a Date object from the ISO string (assumed to be in UTC)
    const dateUTC = new Date(isoString);

    // Convert the date to the local time based on the timezone offset
    const offsetMillis = timezoneOffsetHours * 60 * 60 * 1000;
    const localDate = new Date(dateUTC.getTime() + offsetMillis);

    // Extract hours and minutes
    let hours = localDate.getUTCHours(); // Local hours
    const minutes = localDate.getUTCMinutes(); // Local minutes
    const validMinutes = minutes < 10 ? "0" + minutes?.toString() : minutes;

    // Convert to 12-hour format and determine AM/PM
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12; // Convert hours to 12-hour format
    hours = hours ? hours : 12; // Adjust for 0 hour (midnight)
    const validHours = hours < 10 ? "0" + hours?.toString() : hours;

    return {
      hours: validHours,
      minutes: validMinutes,
      period,
    };
  }

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => {
        setImageData(reader.result as string);
        setImageSrc(URL.createObjectURL(file));
      };
      return file;
    }
  };

  useEffect(() => {
    if (league && isEdit) {
      setDate(league?.league?.draftDateTime);
      const parsedDate = getHoursAndMinutes(
        league?.league?.draftDateTime,
        getTimezoneAbbr() === "PST" ? -8 : -7
      );
      setHours(parsedDate?.hours);
      setMinutes(parsedDate?.minutes);
      setIsAm(parsedDate?.period === "AM" ? true : false);
    }
    if (league?.league?.logoUrl) {
      setImageSrc(league?.league?.logoUrl);
    }
  }, [isEdit, league]);

  const formOptions = useForm({
    values: {
      name: isEdit ? league?.league?.name : "",
      description: isEdit ? league?.league?.description : "",
      entryFee: isEdit ? league?.league?.entryFee : "",
      numberOfTeams:
        isEdit && league?.league?.numberOfTeams
          ? [
              {
                label: league?.league?.numberOfTeams,
                value: league?.league?.numberOfTeams,
              },
            ]
          : ([] as any),
      teamsEliminatedPerWeek:
        isEdit && league?.league?.teamsEliminatedPerWeek
          ? [
              {
                label: league?.league?.teamsEliminatedPerWeek,
                value: league?.league?.teamsEliminatedPerWeek,
              },
            ]
          : ([] as any),
      timeForEachTeam:
        isEdit && league?.league?.draftPickTime
          ? {
              label:
                league?.league?.draftPickTime === "60"
                  ? "60 Seconds"
                  : "90 Seconds",
              value: league?.league?.draftPickTime,
            }
          : ([] as any),
      prizeFundFirstPlace: isEdit ? league?.league?.prizeFundFirstPlace : "",
      prizeFundSecondPlace: isEdit ? league?.league?.prizeFundSecondPlace : "",
      //TODO: fix mock data
      pricePoint: 5.0,
      totalWeeks: 8,
    },
  });

  const data = ["QB", "RB", "WR", "TE", "Flex", "Flex "];

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    getValues,
  } = formOptions;

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (isFirstTab) {
        setActiveTab(2);
        return;
      } else if (isSecondTab) {
        setActiveTab(3);
        return;
      } else if (isThirdTab) {
        setIsLoading(true);
        if (isEdit) {
          if (file) {
            const buffer = Buffer.from(imageData);
            await createLeaguePhoto({
              leagueId: leagueId,
              body: {
                file: buffer,
                fileName: file.name,
                fileType: file.type,
              },
            });
          }
          await mutateAsync({
            name: data?.name,
            description: data?.description,
            draftDateTime: combineDateTime(
              date,
              Number(hours),
              Number(minutes),
              isAm ? "AM" : "PM",
              getTimezoneAbbr()
            ),
            entryFee: data?.entryFee,
            pricePoint: data?.pricePoint,
            numberOfTeams: data?.numberOfTeams?.value,
            teamsEliminatedPerWeek: data?.teamsEliminatedPerWeek?.value,
            totalWeeks: data?.totalWeeks,
            receivingOption: receivingOption,
            prizeFundFirstPlace: data?.prizeFundFirstPlace,
            prizeFundSecondPlace: data?.prizeFundSecondPlace,
            draftPickTime: data?.timeForEachTeam?.value,
            leagueId: leagueId || id,
          });
          toast.success("League updated successfully!");
        } else {
          const newLeague = await createLeague({
            name: data?.name,
            description: data?.description,
            draftDateTime: combineDateTime(
              date,
              Number(hours),
              Number(minutes),
              isAm ? "AM" : "PM",
              getTimezoneAbbr()
            ),
            entryFee: data?.entryFee,
            pricePoint: data?.pricePoint,
            numberOfTeams: data?.numberOfTeams?.value,
            teamsEliminatedPerWeek: data?.teamsEliminatedPerWeek?.value,
            totalWeeks: data?.totalWeeks,
            receivingOption: receivingOption,
            prizeFundFirstPlace: data?.prizeFundFirstPlace,
            prizeFundSecondPlace: data?.prizeFundSecondPlace,
            draftPickTime: data?.timeForEachTeam?.value,
          });
          const id = newLeague?.data?.data?.league?.id;
          if (file && id) {
            const buffer = Buffer.from(imageData);
            await createLeaguePhoto({
              leagueId: id,
              body: {
                file: buffer,
                fileName: file.name,
                fileType: file.type,
              },
            });
          }
          toast.success("League created successfully!");
        }
        setIsLoading(false);
      }
      closeModal?.();
    } catch (error: any) {
      closeModal?.();
      toast.error(error?.response?.data?.error);
    }
  });
  return {
    activeTab,
    setActiveTab,
    receivingOption,
    setReceivingOption,
    date,
    setDate,
    errors,
    control,
    data,
    onSubmit,
    isFirstTab,
    isSecondTab,
    isThirdTab,
    imageSrc,
    handleFileChange,
    handleIconClick,
    fileInputRef,
    dirtyFields,
    getValues,
    isLoading,
    isPendingUpdate,
    isPendingCreate,
    hours,
    setHours,
    minutes,
    setMinutes,
    isAm,
    setIsAm,
  };
};

export default useAddNewLeagueHook;
