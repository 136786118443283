import { useState } from "react";
import AppContext from "./appContext";

const ContextProvider = (props: any) => {
  const [timers, _setTimers] = useState({});
  const [completedDrafts, _setCompletedDrafts] = useState({});
  const [activeDrafts, _setActiveDraftd] = useState({});
  const [activePicks, setActivePicks] = useState({});

  return (
    <AppContext.Provider
      value={{
        timers,
        completedDrafts,
        activeDrafts,
        activePicks,
        setActivePicks,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
