import { ReactComponent as First } from "../../../assets/icons/first-place-icon.svg";
import { ReactComponent as Second } from "../../../assets/icons/second-place-icon.svg";
import useUserProfileHook from "./hooks/useUserProfile";
import demo_logo from "../../../assets/images/demoLogo.png";
import {
  formatAsFriendlyPastDate,
  groupMessagesByDay,
  numberWithCommas,
} from "../../../utils/helpers";
import moment from "moment";
import clsx from "clsx";
import { AddCreditsModal } from "./components/AddCredits";
import { BlockUnblockUserModal } from "./components/BlockUnblockUser";
import { EditUserModal } from "./components/EditUser";
import { ChangePasswordModal } from "./components/ChangePassword";

const UserProfile = () => {
  const { data, accountBalance, winRate } = useUserProfileHook();
  return (
    <div className="px-4 pb-6 md:p-10">
      <div className="w-full flex justify-between">
        <div className="flex items-center">
          <img
            src={data?.profileUrl || demo_logo}
            alt="Avatar"
            className="w-[100px] h-[100px] rounded-[35px] mr-6"
          />
          <div>
            <div className="flex items-center gap-4">
              <h2 className="font-absoluteEmpire text-[30px] leading-none">
                {data?.name}
              </h2>
              <div
                className={clsx(
                  "bg-chipGreen px-[18px] py-2 rounded-[8px]",
                  data?.account_status === "blocked" && "bg-red-bgGradient-1"
                )}
              >
                <p
                  className={clsx(
                    "font-gilroyBold text-text-green capitalize",
                    data?.account_status === "blocked" && "!text-red-dark"
                  )}
                >
                  {data?.account_status}
                </p>
              </div>
            </div>

            <div>
              <p className="text-text-subGrey font-gilroyMedium mt-1">
                {data?.email}
              </p>
            </div>
          </div>
        </div>
        <div className="hidden gap-4 md:flex">
          <ChangePasswordModal userId={data?.id} />
          <EditUserModal userId={data?.id} />
          <BlockUnblockUserModal user={data} />
        </div>
      </div>
      <div className="mt-6 flex w-full flex-col md:flex-row">
        <div className="w-full">
          <div className="flex gap-6 w-full flex-col md:flex-row">
            <div className="bg-modal-grey px-8 py-6 rounded-[12px] w-full">
              <div className="flex justify-between items-center">
                <p className="text-base font-gilroyMedium">Account balance</p>{" "}
                <AddCreditsModal user={data} />
              </div>

              <p className="mt-4 font-absoluteEmpire text-[30px]">
                ${numberWithCommas(accountBalance?.toFixed(2))}
              </p>
              <p className="mt-2 text-text-grey">Total balance from teams</p>
            </div>
            <div className="bg-modal-grey px-8 py-6 rounded-[12px] w-full">
              <div className="flex justify-between items-center">
                <p className="text-base font-gilroyMedium">Credits</p>
                <AddCreditsModal user={data} isCredits />
              </div>
              <p className="mt-4 font-absoluteEmpire text-[30px]">
                $
                {numberWithCommas(
                  Number(data?.balance?.credit_amount)?.toFixed(2)
                )}
              </p>
              <p className="mt-2 text-text-grey">
                Credits for free league registration
              </p>
            </div>
          </div>

          <div className="flex mt-6 gap-6 flex-col md:flex-row">
            <div className="border-[1px] border-modal-modalStroke rounded-[12px] flex justify-center items-center p-6 bg-modal-grey gap-4">
              <div className="relative p-4">
                <div className="absolute right-[30px] top-7 w-[24px] h-[24px] rounded-[12px] bg-gradient-to-br from-red-accent-1 to-red-accent-2 flex justify-center itemes-center">
                  <p className="font-gilroyBold mt-[2px]">
                    {data?.userStats?.leaguesWonFirstPlace}
                  </p>
                </div>
                <First />
                <p className="text-center font-gilroyBold mt-[10px]">
                  First Place
                </p>
              </div>
              <div className="relative p-4">
                <div className="absolute right-[30px] top-7 w-[24px] h-[24px] rounded-[12px] bg-gradient-to-br from-red-accent-1 to-red-accent-2 flex justify-center itemes-center">
                  <p className="font-gilroyBold mt-[2px]">
                    {data?.userStats?.leaguesWonSecondPlace}
                  </p>
                </div>
                <Second />
                <p className="text-center font-gilroyBold mt-[10px]">
                  Second Place
                </p>
              </div>
            </div>

            <div className=" border-[1px] border-modal-modalStroke rounded-[13px] w-full bg-modal-grey">
              <div className="relative px-6 py-4 bg-filterContainer rounded-t-[12px] flex flex-row justify-between items-center">
                <p className="font-gilroyBold">Participation in Leagues</p>
              </div>
              <div className="rounded-b-[12px] bg-modal-grey p-6 flex justify-around items-center">
                <div className="flex flex-col justify-center items-center">
                  <p className="text-text-grey font-gilroyMedium">
                    Total Leagues
                  </p>
                  <p className="mt-2 text-[20px] font-gilroyBold">
                    {data?.userStats?.numberOfLeaguesEntered}
                  </p>
                </div>
                <div className="w-[1px] h-[58px] bg-modal-modalStroke" />
                <div className="flex flex-col justify-center items-center">
                  <p className="text-text-grey font-gilroyMedium">
                    Won Leagues
                  </p>
                  <p className="mt-2 text-[20px] font-gilroyBold">
                    {data?.userStats?.leaguesWonFirstPlace +
                      data?.userStats?.leaguesWonSecondPlace}
                  </p>
                </div>
                <div className="w-[1px] h-[58px] bg-modal-modalStroke" />
                <div className="flex flex-col justify-center items-center">
                  <p className="text-text-grey font-gilroyMedium">Win Rate</p>
                  <p className="mt-2 text-[20px] font-gilroyBold">
                    {!isNaN(winRate) ? winRate?.toFixed(1) : 0}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-0 border-[1px] border-modal-modalStroke rounded-[13px] w-full max-h-[360px] bg-modal-grey mt-6 md:w-[45%] md:ml-6 md:mt-0">
          <div className="relative px-6 py-4 bg-filterContainer rounded-t-[12px] flex flex-row justify-between items-center">
            <p className="font-gilroyBold">Transactions</p>
          </div>{" "}
          <div className="rounded-b-[12px] p-6 flex flex-col justify-between h-[290px] overflow-y-auto scrollbar-hide">
            {data?.transaction?.length ? (
              Object.keys(groupMessagesByDay(data?.transaction))?.map(
                (date, i) => {
                  const group = groupMessagesByDay(data?.transaction)[date];
                  return (
                    <div key={i}>
                      <div className="flex gap-4 my-1">
                        <p className="text-text-grey font-gilroyMedium text-nowrap">
                          {formatAsFriendlyPastDate(
                            new Date(group[0].createdAt)
                          )?.toString()}
                        </p>
                      </div>
                      {group
                        ?.sort(
                          (a: any, b: any) =>
                            new Date(a?.createdAt)?.valueOf() -
                            new Date(b?.createdAt)?.valueOf()
                        )
                        ?.map((item: any, index: any) => {
                          return (
                            <div
                              className="flex justify-between items-center my-6"
                              key={index}
                            >
                              <div>
                                <p className="font-gilroyBold capitalize">
                                  {item?.type}
                                </p>
                                <p className="text-text-grey text-xs font-gilroyMedium">
                                  {moment(item?.createdAt).format("lll")}
                                </p>
                              </div>
                              <p
                                className={clsx(
                                  "text-text-red font-gilroyBold",
                                  (item?.type === "deposit" ||
                                    item?.type
                                      ?.toLocaleLowerCase()
                                      ?.includes("win") ||
                                    item?.type === "deposit from admin" ||
                                    item?.type === "transfer from admin" ||
                                    item?.type === "credit from admin" ||
                                    item?.type === "refund") &&
                                    "!text-text-green"
                                )}
                              >
                                {item?.type === "deposit" ||
                                item?.type
                                  ?.toLocaleLowerCase()
                                  ?.includes("win") ||
                                item?.type === "deposit from admin" ||
                                item?.type === "transfer from admin" ||
                                item?.type === "credit from admin" ||
                                item?.type === "refund"
                                  ? "+"
                                  : "-"}
                                ${item?.amount}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  );
                }
              )
            ) : (
              <p className="font-gilroyMedium text-text-grey">
                No transactions
              </p>
            )}
          </div>
        </div>
        <div className="flex gap-4 mt-6 md:hidden">
          <EditUserModal userId={data?.id} />
          <BlockUnblockUserModal user={data} />
        </div>
        <div className="flex gap-4 mt-4 md:hidden">
          <ChangePasswordModal userId={data?.id} />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
