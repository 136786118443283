import axios from "axios";
import { LeaguesType } from "./leagues.types";
import useAuthStore from "../../../stores/auth/auth";

export const LeaguesServices = {
  createLeague: async (payload: LeaguesType) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = "/api/admin/league-management/league";
    try {
      const newLeague = await axios.post(endPoint, payload, config);
      return newLeague;
    } catch (error) {
      throw error;
    }
  },
  getAllLeagues: async (params: any) => {
    const endPoint = "/api/game/league";
    try {
      const leagues = await axios.get(endPoint, { params });
      return leagues?.data;
    } catch (error: any) {
      throw error.error;
    }
  },
  getLeagueById: async (id: string, params?: any) => {
    const endPoint = `/api/game/league/${id}`;
    try {
      const league = await axios.get(endPoint, { params });
      return league?.data?.message;
    } catch (error: any) {
      throw error.error;
    }
  },
  joinLeague: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/game/league/join/${payload?.userId}`;
    try {
      delete payload?.userId;
      const joinLeague = await axios.post(
        endPoint,
        { leagueId: payload?.leagueId },
        config
      );
      return joinLeague?.data;
    } catch (error) {
      throw error;
    }
  },
  getAllLeagueUsers: async (leagueId: string) => {
    const endPoint = `/api/game/league/user/${leagueId}`;
    try {
      const leagueUsers = await axios.get(endPoint);
      return leagueUsers?.data;
    } catch (error) {
      throw error;
    }
  },
  getTeamDetails: async (teamId: string) => {
    const endPoint = `/api/game/team/${teamId}`;
    try {
      const team = await axios.get(endPoint);
      return team?.data;
    } catch (error) {
      throw error;
    }
  },
  deleteLeague: async (leagueId: string) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/league-management/league/${leagueId}`;
    try {
      const deleteLeague = await axios.delete(endPoint, config);
      return deleteLeague;
    } catch (error) {
      throw error;
    }
  },
  updateLeague: async (payload: LeaguesType) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/league-management/league/${payload?.leagueId}`;
    try {
      delete payload?.leagueId;
      const updateLeague = await axios.patch(endPoint, payload, config);
      return updateLeague;
    } catch (error) {
      throw error;
    }
  },
  getMyLeagues: async (params: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
      params: params,
    };
    const endPoint = `/api/game/league/user/my`;
    try {
      const myLeagues = await axios.get(endPoint, config);
      return myLeagues?.data;
    } catch (error) {
      throw error;
    }
  },
  postSelectedLeagues: async (data: {
    id: string;
    reqBody: {
      finalLeagueId: string;
      teamIds: string[];
    };
  }) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endpoint = `/api/admin/league-management/league/${data.id}/move-users`;
    try {
      const response = await axios.post(endpoint, data.reqBody, config);
      return response.data;
    } catch (error: any) {
      throw error.error;
    }
  },
  createLeaguePhoto: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/admin/league-management/league/logo/${payload.leagueId}`;
    try {
      const config = {
        headers: { Authorization: `Bearer ${loginData?.access_token}` },
      };
      const userPhoto = await axios.post(endPoint, payload.body, config);
      return userPhoto?.data;
    } catch (error) {
      throw error;
    }
  },
  getEliminationDate: async () => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/game/league/elimination/date`;
    try {
      const config = {
        headers: { Authorization: `Bearer ${loginData?.access_token}` },
      };
      const eliminationDate = await axios.get(endPoint, config);
      return eliminationDate?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getCurrentWeek: async () => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/game/league/week/current`;
    try {
      const config = {
        headers: { Authorization: `Bearer ${loginData?.access_token}` },
      };
      const currentWeek = await axios.get(endPoint, config);
      return currentWeek?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  quitLeague: async (leagueId: string) => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/game/league/quit/${leagueId}`;
    try {
      const config = {
        headers: { Authorization: `Bearer ${loginData?.access_token}` },
      };
      const quitLeague = await axios.post(endPoint, {}, config);
      return quitLeague?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getAllGames: async () => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/game/league/seasonal/games`;
    try {
      const config = {
        headers: { Authorization: `Bearer ${loginData?.access_token}` },
      };
      const allGames = await axios.get(endPoint, config);
      return allGames?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getSecondaryScores: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const endPoint = `/api/game/league/secondary-score/${payload?.leagueId}?week=${payload?.week}`;
    try {
      const config = {
        headers: { Authorization: `Bearer ${loginData?.access_token}` },
      };
      const secodaryScores = await axios.get(endPoint, config);
      return secodaryScores?.data?.message;
    } catch (error) {
      throw error;
    }
  },
};
