import { useNavigate } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../assets/icons/draft-arrow-right.svg";
import card1 from "../../../assets/images/guestHome/card1.png";
import card2 from "../../../assets/images/guestHome/card2.png";
import card3 from "../../../assets/images/guestHome/card3.png";
import card4 from "../../../assets/images/guestHome/card4.png";

const GuestHome = () => {
  const navigate = useNavigate();

  const cards = [
    {
      image: card1,
      title: "Join the League",
      description:
        "Sign up to participate in an exhilarating and ruthless competition.",
    },
    {
      image: card2,
      title: "Choose Your Team",
      description:
        "Choose your team carefully as you prepare to compete in the league",
    },
    {
      image: card3,
      title: "Weekly Eliminations",
      description:
        "Each week, the two lowest-scoring teams are eliminated, and their rosters go into free agency",
    },
    {
      image: card4,
      title: "Strategize to Win",
      description:
        "Draft wisely, manage your bankroll, stay sharp, and become the CutThroat league champion!",
    },
  ];
  const cardRender = (data: any) => {
    return (
      <div
        key={data?.title}
        onClick={() => navigate("/login")}
        className="p-6 rounded-[16px] bg-modal-grey flex flex-col w-[100%] cursor-pointer
        hover:bg-gradient-to-br hover:from-red-bgGradient-1 hover:to-red-bgGradient-2 md:w-[25%]"
      >
        <img
          width={170}
          height={170}
          src={data?.image}
          alt="card img"
          className="mt-[-70px] ml-auto"
        />
        <div className="flex justify-between items-center mt-[-15px]">
          <p className="text-[24px] font-gilroyBold">{data?.title}</p>
          <Arrow />
        </div>
        <p className="text-base text-text-lightGrey mt-2">
          {data?.description}
        </p>
      </div>
    );
  };

  return (
    <div className="px-10 pb-6 mt-6 w-full">
      <p className="font-absoluteEmpire text-[40px] text-center">
        What is CutThroat?
      </p>
      <div className="flex flex-col gap-4 justify-between mt-[40px] md:flex-row">
        {cards?.map((card) => cardRender(card))}
      </div>
      <div className="p-[40px] rounded-[16px] bg-modal-grey mt-[40px] bg-geust-home bg-right bg-no-repeat bg-contain">
        <p className="font-absoluteEmpire text-[24px]">have questions?</p>
        <p className="font-gilroyMedium text-text-grey text-base mt-2">
          Explore our{" "}
          <span
            onClick={() => navigate("/help")}
            className="underline cursor-pointer"
          >
            Help page
          </span>{" "}
          or feel free to reach out to us via
        </p>
        <a
          className="text-text-white text-base font-gilroyBold"
          href="mailto:cutthroatfantasysports@gmail.com"
        >
          cutthroatfantasysports@gmail.com
        </a>
      </div>
    </div>
  );
};
export default GuestHome;
