import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { USER_QUERY_KEY } from "./userQueriesKeys";
import { UserServices } from "../../services/apis/User/UserServices";
import useAuthStore from "../../stores/auth/auth";

export const useFetchUser = () => {
  const { loginData } = useAuthStore.getState();
  const queryResult = useQuery<any>({
    queryKey: [USER_QUERY_KEY.FETCH_USER],
    queryFn: () => UserServices.fetchUser(),
    refetchOnWindowFocus: false,
    retry: 0,
    initialData: {},
    enabled: !!loginData?.access_token,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useFetchUserDetails = (userId: string) => {
  const queryResult = useQuery<any>({
    queryKey: [USER_QUERY_KEY.FETCH_USER_DETAILS],
    queryFn: () => UserServices.getUserDetails(userId),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!userId,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useFetchTeamUserDetails = (userId: string) => {
  const queryResult = useQuery<any>({
    queryKey: [USER_QUERY_KEY.FETCH_TEAM_USER_DETAILS],
    queryFn: () => UserServices.getUserDetails(userId),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!userId,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: UserServices.updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USER_QUERY_KEY.FETCH_USER_DETAILS],
      });
    },
  });
};

export const useUpdateNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: UserServices.updateNotifications,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USER_QUERY_KEY.FETCH_USER_DETAILS],
      });
    },
  });
};

export const useUpdateUserPhoto = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: UserServices.updateUserPhoto,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USER_QUERY_KEY.FETCH_USER_DETAILS],
      });
    },
  });
};
