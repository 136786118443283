export const ADMIN_QUERY_KEY = {
  FETCH_ALL_USERS: "FETCH_ALL_USERS",
  FETCH_USER: "FETCH_USER",
  FETCH_USERS_COUNT: "FETCH_USERS_COUNT",
  FETCH_STATS: "FETCH_STATS",
  FETCH_LEAGUES_COUNT: "FETCH_LEAGUES_COUNT",
  FETCH_TRANSACTIONS: "FETCH_TRANSACTIONS",
  FETCH_TRANSACTIONS_COUNT: "FETCH_TRANSACTIONS_COUNT",
  FETCH_FAQ: "FETCH_FAQ",
  FETCH_PRIVACY_POLICY: "FETCH_PRIVACY_POLICY",
  FETCH_TERMS: "FETCH_TERMS",
};
