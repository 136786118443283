import { Button, Input, Modal, useModal } from "../../../../components";
import { ReactComponent as EditRed } from "../../../../assets/icons/edit-red-icon.svg";
import { ReactComponent as Plus } from "../../../../assets/icons/plus-white-icon.svg";
import { ReactComponent as Minus } from "../../../../assets/icons/minus-icon.svg";
import { ReactComponent as Down } from "../../../../assets/icons/arrow-down.svg";
import { ReactComponent as Dollar } from "../../../../assets/icons/dollar-icon.svg";
import demo_logo from "../../../../assets/images/demoLogo.png";
import { Fragment } from "react";
import { isNumeric } from "../../../../utils/helpers";
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import useUserProfileHook from "../hooks/useUserProfile";
import { Oval } from "react-loader-spinner";

export const AddCreditsModal = ({
  user,
  isCredits,
}: {
  user: any;
  isCredits?: boolean;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <AddCredits closeModal={closeModal} user={user} isCredits={isCredits} />
  );
  const { modalControl, openModal } = useModal({
    title: isCredits ? "Credits" : "Balance Management",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <EditRed className="cursor-pointer" onClick={openModal} />
    </>
  );
};

const AddCredits = ({
  closeModal,
  user,
  isCredits,
}: {
  closeModal?: () => void;
  user: any;
  isCredits?: boolean;
}) => {
  const {
    step,
    setStep,
    steps,
    filters,
    onAddCredits,
    credit,
    setCredit,
    onAddBalance,
    isPendingCredits,
    isPendingBalance,
  } = useUserProfileHook();

  return (
    <>
      <div className="flex flex-col items-center mt-8">
        <img
          src={user?.profileUrl || demo_logo}
          alt="Avatar"
          className="w-[100px] h-[100px] rounded-[50px]"
        />
        <div>
          <p className="font-absoluteEmpire text-[14px] leading-none mt-4">
            {user?.name}
          </p>
          <p className="text-text-subGrey text-xs font-gilroyMedium mt-1">
            {user?.email}
          </p>
        </div>
        <div className="flex items-center gap-2 mt-6">
          <Button
            leftIcon={<Minus />}
            mode="secondary"
            className="!h-[44px] !w-[44px]"
            onClick={() => {
              if (credit > Number(step) && typeof Number(credit) === "number") {
                setCredit(Number(credit) - Number(step));
              }
            }}
          >
            {""}
          </Button>
          <Input
            leftIcon={<Dollar className="mt-[-3px]" />}
            value={credit}
            containerClassName="!w-[120px] h-[48px] bg-modal-inputBg rounded-[8px] border-[2px] border-filterContainer py-3"
            inputClassName="placeholder-text-gray text-sm placeholder-font-gilroyMedium text-center"
            placeholder="Enter your bid"
            onChange={(e: any) => {
              if (isNumeric(e?.target?.value) || e?.target?.value === "") {
                setCredit(Number(e?.target?.value));
              }
            }}
          />
          <Button
            leftIcon={<Plus />}
            mode="primary"
            className="!h-[44px] !w-[44px]"
            onClick={() => {
              if (credit >= 0 && typeof Number(credit) === "number") {
                setCredit(Number(credit) + Number(step));
              }
            }}
          >
            {""}
          </Button>
        </div>
        <div className="mt-4 flex items-center">
          <p className="text-text-subGrey font-gilroyMedium mr-2">
            Select the cost of step
          </p>
          <Menu as="div" className="relative">
            {() => (
              <>
                <Menu.Button
                  aria-pressed={false}
                  aria-hidden
                  className="w-max inline-flex items-center relative"
                >
                  <p className="mr-2 text-sm font-gilroyMedium">${step}</p>
                  <Down />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    as="div"
                    className="bg-card-bgGreyDark rounded-[16px] left-[50%] translate-x-[-50%] absolute z-10 flex flex-col mt-4 shadow-2xl"
                  >
                    {steps?.length
                      ? steps?.map((item, index) => (
                          <Menu.Item key={index}>
                            <button
                              className={clsx(
                                "px-6 py-3 hover:bg-card-bgGreyMedium cursor-pointer text-base flex items-center",
                                index === 0 && "rounded-t-[16px]",
                                filters?.length - 1 === index &&
                                  "rounded-b-[16px]"
                              )}
                              onClick={() => {
                                setStep(item);
                              }}
                            >
                              <span className="text-left whitespace-nowrap">
                                ${item}
                              </span>
                            </button>
                          </Menu.Item>
                        ))
                      : null}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px] !h-[39px]"
        >
          Back
        </Button>
        <Button
          disabled={isPendingCredits || isPendingBalance}
          leftIcon={
            <Oval
              visible={isPendingCredits || isPendingBalance}
              height="20"
              width="20"
              color="#000000"
              secondaryColor="#ffffff"
            />
          }
          onClick={() =>
            isCredits
              ? onAddCredits(user?.id, closeModal)
              : onAddBalance(user?.id, closeModal)
          }
          size="md"
          className="!text-base w-full !rounded-[8px] !h-[39px]"
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default AddCredits;
