import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components";
import useAuthStore from "../../stores/auth/auth";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as NavIcon } from "../../assets/icons/nav-bottom.svg";
import MobileMenu from "../../assets/icons/menu.svg";
import MobileMenuClose from "../../assets/icons/mobileClose.svg";
import clsx from "clsx";
import MobileSidebar from "../../components/MobileSidebar/MobileSidebar.tsx";

const GuestHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  type Route = {
    name: string;
    path: string;
    isEnabled: boolean;
    selectedIcon?: JSX.Element;
  };

  const routes: Route[] = [
    {
      name: "HOME",
      path: "/",
      isEnabled: true,
      selectedIcon: NavIcon,
    },
    {
      name: "LEAGUES",
      path: "/allleagues",
      isEnabled: true,
      selectedIcon: NavIcon,
    },
    {
      name: "HELP",
      path: "/help",
      isEnabled: true,
      selectedIcon: NavIcon,
    },
  ];
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useAuthStore.getState();
  return (
    <>
      <div className="flex flex-row w-full py-4 justify-between items-center px-4 md:px-10">
        <div className="block w-[30px] h-[22px] md:hidden">
          {!isMobileMenuOpen ? (
            <img
              onClick={() => setIsMobileMenuOpen(true)}
              src={MobileMenu}
              alt=""
            />
          ) : (
            <img
              onClick={() => setIsMobileMenuOpen(false)}
              src={MobileMenuClose}
              alt=""
            />
          )}
        </div>

        <>
          <Logo
            className="cursor-pointer px-3"
            onClick={() => navigate("/")}
          />
          <div className="flex flex-row justify-center items-center hidden md:flex">
            {routes.map((route, _index) => {
              const isActive =
                location.pathname === route.path ||
                (route.path.length > 1 &&
                  location.pathname.includes(route.path));
              return (
                <Link
                  key={route.name}
                  to={route.path}
                  className={clsx(
                    "w-[160px] font-absoluteEmpire flex flex-col justify-start items-center",
                    route.isEnabled
                      ? "cursor-pointer"
                      : "cursor-not-allowed text-text-grey pointer-events-none"
                  )}
                  aria-disabled={!route.isEnabled}
                >
                  <span
                    className={clsx(
                      "px-4 py-2 font-absoluteEmpire mb-[19px]",
                      isActive && "text-text-red"
                    )}
                  >
                    {route.name}
                  </span>
                  {isActive && route.selectedIcon ? (
                    <NavIcon />
                  ) : (
                    <div className="h-2" />
                  )}
                </Link>
              );
            })}
          </div>
          <div className="mb-0 flex items-center gap-4 md:mb-[19px]">
            <Button
              mode="secondary"
              className="w-fit h-fit px-3 md:w-[90px] rounded-[8px]"
              onClick={() => navigate("/login")}
            >
              Log In
            </Button>
            <Button
              className="w-fit h-fit px-3 md:w-[155px] rounded-[8px]"
              onClick={() => navigate("/signup")}
            >
              Create Account
            </Button>
          </div>
        </>
      </div>
      {isMobileMenuOpen && <MobileSidebar />}
    </>
  );
};

export default GuestHeader;
