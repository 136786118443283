import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import { ReactComponent as Star } from "../../../../assets/icons/star.svg";
import clsx from "clsx";
import useAuthStore from "../../../../stores/auth/auth";

export const TeamCard = ({
  name,
  logo,
  userId,
}: {
  name?: string;
  logo?: string;
  userId: string;
}) => {
  const { userData } = useAuthStore.getState();
  const isMyTeam = userId === userData?.id;

  return (
    <div
      className={clsx(
        "bg-modal-inputBg p-3 rounded-[8px] border-[1px] border-stroke min-w-[215px] w-[215px] flex justify-between items-center",
        isMyTeam && "bg-red-bgDark !border-red-bgGradient-1"
      )}
    >
      <div className="flex items-center">
        <img
          src={logo || demo_team_logo}
          alt="CutThroat"
          className="w-[40px] h-[40px] rounded-[50px] mr-2"
        />
        <div>
          <div className="flex flex-row items-center">
            <p className="font-gilroyBold text-base mr-2">
              {name
                ? name?.length > 16
                  ? `${name?.slice(0, 16)}...`
                  : name
                : "-"}
            </p>
            {isMyTeam && <Star />}
          </div>
          <p className="font-gilroyMedium text-xs text-text-grey">
            {isMyTeam && "Your Team"}
          </p>
        </div>
      </div>
    </div>
  );
};
