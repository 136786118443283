import clsx from "clsx";
import parse from "html-react-parser";
import { ErrorMsgPropsType } from "./type";

export const ErrorMsg = (props: ErrorMsgPropsType) => {
  const { error, className = "" } = props;
  return (
    <div
      className={clsx(
        "text-xs text-left font-normal text-semantic-red-1",
        className
      )}
    >
      {error ? parse(error) : ""}
    </div>
  );
};
