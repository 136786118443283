import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { WAIVER_QUERY_KEY } from "./waiverQueriesKeys";
import { WaiverServices } from "../../services/apis/Waiver/WaiverServices";

export const useFetchPlayersOnWaiver = (teamId: string) => {
  const queryResult = useQuery<any>({
    queryKey: [WAIVER_QUERY_KEY.FETCH_PLAYERS_ON_WAIVER],
    queryFn: () => WaiverServices.getPlayersOnWaiver(teamId),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!teamId,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useFetchMyBids = (teamId: string) => {
  const queryResult = useQuery<any>({
    queryKey: [WAIVER_QUERY_KEY.FETCH_BIDS],
    queryFn: () => WaiverServices.getMyBids(teamId),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!teamId,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useMakeBid = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: WaiverServices.makeBid,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [WAIVER_QUERY_KEY.FETCH_BIDS],
      });
    },
  });
};

export const useEditBid = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: WaiverServices.editBid,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [WAIVER_QUERY_KEY.FETCH_BIDS],
      });
    },
  });
};

export const useDeleteBid = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: WaiverServices.deleteBid,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [WAIVER_QUERY_KEY.FETCH_BIDS],
      });
    },
  });
};

export const useIncreasePriority = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: WaiverServices.increasePriority,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [WAIVER_QUERY_KEY.FETCH_BIDS],
      });
    },
  });
};

export const usePriorityDecrease = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: WaiverServices.priorityDecrease,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [WAIVER_QUERY_KEY.FETCH_BIDS],
      });
    },
  });
};
export const useFetchAllPlayersOnWaiver = (leagueId: string) => {
  const queryResult = useQuery<any>({
    queryKey: [WAIVER_QUERY_KEY.FETCH_ALL_PLAYERS_ON_WAIVER],
    queryFn: () => WaiverServices.getAllWaiverPlayers(leagueId),
    refetchOnWindowFocus: false,
    retry: 0,
    initialData: [],
    enabled: !!leagueId,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useFetchPlayerBidsHistory = (payload: any) => {
  const queryResult = useQuery<any>({
    queryKey: [WAIVER_QUERY_KEY.FETCH_PLAYER_BIDS_HISTORY, payload?.playerId],
    queryFn: () => WaiverServices.getPlayerBidsHistory(payload),
    refetchOnWindowFocus: false,
    retry: 0,
    initialData: [],
    enabled: !!payload?.leagueId && !!payload?.playerId,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
