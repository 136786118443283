import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { ReactComponent as Back } from "../../../assets/icons/back.svg";
import forgotPassword_bg from "../../../assets/images/forgotPassword_bg.png";
import { Controller } from "react-hook-form";
import LabeledInput from "../../../components/LabeledInput/LabeledInput";
import { Button } from "../../../components/Button/Button";
import { emailValidation, validateEmail } from "../../../utils/helpers";
import useForgotPassword from "./hooks/useForgotPassword";

const ForgotPassword = () => {
  const { email, control, errors, activeTab, onSubmit, navigate } =
    useForgotPassword();

  return (
    <div className="flex flex-row p-0 h-svh md:p-2">
      <div className="p-10 w-full flex flex-col justify-center items-center bg-auth-mobile bg-cover bg-center md:bg-none">
        <div className="relative w-full flex justify-center items-center">
          <div
            onClick={() => navigate("/login")}
            className="absolute left-0 flex flex-row justify-center items-center cursor-pointer"
          >
            <Back />
            <p className="text-text-white ml-2 text-base font-gilroyMedium hidden md:block">
              Back to Login
            </p>
          </div>
          <Logo />
        </div>
        <div className="mt-[64px] h-full flex flex-col items-center">
          <h1 className="text-text-white font-absoluteEmpire text-[40px] text-center">
            {activeTab === 1 ? "RESET YOUR PASSWORD" : "CHECK YOUR EMAIL"}
          </h1>
          <div className="max-w-[397px]">
              {activeTab === 1 ? <p className="mt-2 text-text-subGrey text-center">
                      Enter the email address associated with your account and we will send you a link to reset your
                      password
                  </p> :
                  <p className="mt-2 text-text-subGrey text-center">
                      Thank you! Check your email for instructions to reset your password. We sent it to <span className="font-gilroyBold">
    {validateEmail(email) ? email : "your email"}
  </span>, the link will be available for one hour
                  </p>
              }
              <div>
                  {activeTab === 1 && (
                      <Controller
                          control={control}
                          name="email"
                          render={({field}) => (
                              <LabeledInput
                                  {...field}
                                  required
                      placeholder="Enter your email or username"
                      error={errors?.email?.message}
                      errorClassName="text-text-red"
                      label="Your Email or Username"
                      containerClassName="mt-[32px]"
                      style={{ caretColor: "red" }}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: emailValidation,
                      message:
                        "<p>The email address did not pass format validation, enter another email or username</p>",
                    },
                  }}
                />
              )}
              <Button onClick={onSubmit} className="w-full text-base mt-[32px]">
                {activeTab === 1 ? "Send Reset Link" : "Back To Login"}
              </Button>
              <p className="text-text-white text-base font-gilroyMedium text-center mt-4">
                Didn’t receive an email?{" "}
                <a
                  className="text-text-white underline cursor-pointer"
                  onClick={onSubmit}
                >
                  Resend
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <img
        src={forgotPassword_bg}
        alt="CutThroat"
        className="w-[50%] hidden sm:block"
      />
    </div>
  );
};

export default ForgotPassword;
