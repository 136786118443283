import clsx from "clsx";
import { ReactComponent as ChangeArrow } from "../../../../../assets/icons/arrowUp-icon.svg";
import { ReactComponent as Pencil } from "../../../../../assets/icons/pencil-icon.svg";
import { ReactComponent as Check } from "../../../../../assets/icons/check.svg";
import useHelp from "../hooks/useHelp";
import { useState } from "react";
import { Input } from "../../../../../components";
import { toast } from "react-toastify";
import { DeleteSectionModal } from "./DeleteSection";

const SectionItem = ({
  sectionName,
  setActiveSection,
  activeSectionName,
  isEdit,
  activeSectionId,
  setActiveSectionId,
  section,
  setIsNewQuestion,
}: {
  sectionName: string;
  setActiveSection: (data: string) => void;
  activeSectionName: string;
  isEdit: boolean;
  activeSectionId: number;
  setActiveSectionId: (data: number) => void;
  section: any;
  setIsNewQuestion: (data: boolean) => void;
}) => {
  const { minPriority, maxPriority, onChangePriority, updateFAQ } = useHelp();
  const [isEditSection, setIsEditSection] = useState(false);
  const [sectionData, setSectionData] = useState(sectionName);

  const onUpdateFAQ = async () => {
    try {
      if (sectionData?.length && sectionName !== sectionData) {
        await updateFAQ({
          sectionId: section?.id,
          newSectionData: { ...section, name: sectionData },
        });
        setActiveSection(sectionData);
        setIsEditSection(false);
        toast.success("Section updated successfully");
      }
    } catch (error) {
      toast.error("error");
    }
  };
  return (
    <div className="flex items-center mb-6">
      {isEditSection && activeSectionName === sectionName ? (
        <Input
          value={sectionData}
          onChange={(e) => {
            setSectionData(e.target.value);
          }}
          containerClassName={clsx("w-[100px] border-none rounded-[8px]")}
        />
      ) : (
        <p
          onClick={() => {
            setActiveSection(sectionName);
            setActiveSectionId(activeSectionId);
            setIsEditSection(false);
            setIsNewQuestion(false);
          }}
          className={clsx(
            "text-base font-gilroyMedium cursor-pointer",
            sectionName === activeSectionName &&
              "text-text-red !font-gilroyBold"
          )}
        >
          {sectionName}
        </p>
      )}
      {isEdit && sectionName === activeSectionName && (
        <div className="flex gap-2 ml-3">
          {isEditSection && activeSectionName === sectionName ? (
            <Check
              className={clsx(
                "cursor-pointer",
                sectionName === sectionData && "opacity-50"
              )}
              onClick={onUpdateFAQ}
            />
          ) : (
            <Pencil
              className="cursor-pointer"
              onClick={() => setIsEditSection(!isEditSection)}
            />
          )}
          <ChangeArrow
            className={clsx(
              "cursor-pointer",
              maxPriority?.priority === section?.priority && "opacity-50"
            )}
            onClick={() =>
              maxPriority?.priority === section?.priority
                ? null
                : onChangePriority(section?.id, "decrease")
            }
          />
          <ChangeArrow
            className={clsx(
              "rotate-180 cursor-pointer",
              minPriority?.priority === section?.priority && "opacity-50"
            )}
            onClick={() =>
              minPriority?.priority === section?.priority
                ? null
                : onChangePriority(section?.id, "increase")
            }
          />
          <DeleteSectionModal sectionId={section?.id} />
        </div>
      )}
    </div>
  );
};

export default SectionItem;
