import useFilter from "../../hooks/useFilter";
import "react-datepicker/dist/react-datepicker.css";
import { Input } from "../Input/Input.tsx";
import { ReactComponent as Search } from "../../assets/icons/search-icon.svg";
import { ReactComponent as MobileFilterIcon } from "../../assets/icons/mobileFilter.svg";
import { Button } from "../Button/Button.tsx";
import { Disclosure } from "@headlessui/react";
import down from "../../assets/icons/arrow-down.svg";
import { useState } from "react";

interface MobileFiltersProps {
  filters: {
    name: string;
    value: string;
    filters?: string[] | undefined;
  }[];
  onShowJustOpen?: any;
  onShowMyLeagues?: any;
  status?: string;
  numOfLeagues?: string;
  balance?: string;
  credits?: string;
  setStatus?: (status: string) => void;
  setNumOfLeagues?: (numOfLeagues: string) => void;
  setCredits?: (credits: string) => void;
  setBalance?: (balance: string) => void;
  setName?: (name: string) => void;
  page: string;
}

const MobileFilters = ({
  filters,
  onShowJustOpen,
  onShowMyLeagues,
  status,
  numOfLeagues,
  balance,
  credits,
  setStatus,
  setNumOfLeagues,
  setCredits,
  setBalance,
  setName,
  page,
}: MobileFiltersProps) => {
  const { onChangeFilter, filter } = useFilter({});
  const [openMenu, setOpenMenu] = useState(false);

  const filterValue = (name: string) => {
    switch (name) {
      case "status":
        return status;
      case "numberOfLeagues":
        return numOfLeagues;
      case "balance":
        return balance;
      case "credit":
        return credits;
      default:
        return "All";
    }
  };
  const setFilterValue = (name: string, value: string) => {
    switch (name) {
      case "status":
        setStatus && setStatus(value);
        break;
      case "numberOfLeagues":
        setNumOfLeagues && setNumOfLeagues(value);
        break;
      case "balance":
        setBalance && setBalance(value);
        break;
      case "credit":
        setCredits && setCredits(value);
        break;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="flex gap-2">
        <Input
          containerClassName="rounded-xl border-none"
          inputClassName="placeholder-text-white text-sm"
          placeholder="Search"
          leftIcon={<Search />}
          onChange={(e) => {
            page === "users" && setName
              ? setName(e.target.value)
              : onChangeFilter({
                  keywords: e.target.value,
                });
          }}
        />
        <Button
          mode={openMenu ? "secondary" : "primary"}
          className="!h-[52px] !max-w-[52px] w-full rounded-[8px]"
          onClick={() => setOpenMenu(!openMenu)}
        >
          <MobileFilterIcon />
        </Button>
      </div>
      {openMenu && (
        <div className="w-full flex justify-end relative">
          <div
            className="max-w-[320px] w-full rounded-[8px]  bg-card-bgGreyDark mt-2 absolute"
            style={{ zIndex: 1000 }}
          >
            {filters.map(
              (item, index) =>
                item.name !== "Search" &&
                item?.filters?.length && (
                  <Disclosure key={index}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="group flex w-full items-start justify-between p-4 bg-gray-800 rounded">
                          <div className="flex flex-col">
                            <span className="font-gilroyBold text-base text-start">
                              {item.name}
                            </span>
                            <span className="text-text-subGrey text-start">
                              {page === "users"
                                ? filterValue(item.value)
                                : `(${filter[item.value]})`}
                            </span>
                          </div>

                          <img
                            src={down}
                            alt="Down icon"
                            className={`size-5 fill-white/60 group-hover:fill-white/50 transition-transform duration-200 ${
                              open ? "rotate-180" : ""
                            }`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="text-text-subGrey">
                          {item?.filters?.map((teams) => (
                            <button
                              key={teams}
                              onClick={() => {
                                setOpenMenu(false);
                                page === "users"
                                  ? setFilterValue(item.value, teams)
                                  : onChangeFilter({ [item.value]: teams });
                              }}
                              className={`w-full text-left px-4 py-2 hover:bg-gray-600 ${
                                filter[item.value] === teams ? "text-white" : ""
                              }`}
                            >
                              {teams}
                            </button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
            )}
            {onShowJustOpen && (
              <button
                onClick={() => {
                  onShowJustOpen();
                }}
                className="w-full text-center text-text-red  px-4 py-2"
              >
                Show just open
              </button>
            )}
            {onShowMyLeagues && (
              <button
                onClick={() => {
                  onShowMyLeagues();
                }}
                className="w-full text-center text-text-red  px-4 py-2"
              >
                Show My Leagues
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileFilters;
