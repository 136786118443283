import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  // useFetchUserDetails,
  useUpdateNotifications,
  useUpdateUser,
  useUpdateUserPhoto,
} from "../../../../../queries/userQueries/userQueries";
import { AuthServices } from "../../../../../services/apis/Auth";
import useAuthStore from "../../../../../stores/auth/auth";
import { Buffer } from "buffer";

const useProfileHook = () => {
  const { setUserData } = useAuthStore.getState();
  const { userData } = useAuthStore(["userData"]);
  const [isTwoAuth, setIsTwoAuth] = useState(!!userData?.twoStepAuth);
  const [isEnableNotifications, setIsEnableNotifications] = useState(
    !!userData?.isNotification
  );
  const { mutateAsync } = useUpdateUser();
  const { mutateAsync: updateUserPhoto } = useUpdateUserPhoto();
  const { mutateAsync: updateNotificationsData } = useUpdateNotifications();
  const firstName = userData?.name?.split(" ")?.[0];
  const secondName = userData?.name
    ?.split(" ")
    ?.splice(1, userData?.name?.split(" ")?.length - 1)
    ?.join(" ");

  const formOptions = useForm({
    defaultValues: {
      name: firstName || "",
      surname: secondName || "",
      email: userData?.email || "",
    },
  });
  const formOptionsPass = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const formOptionsPhone = useForm({
    defaultValues: {
      phone: userData?.phone || "",
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formOptions;

  const {
    control: controlPhone,
    formState: { errors: phoneErrors },
    handleSubmit: submitAuthSettings,
  } = formOptionsPhone;

  const {
    control: controlPass,
    formState: { errors: err },
    handleSubmit: submitPassword,
    getValues,
    setValue,
  } = formOptionsPass;

  const checkEmail = async (data: { email: string }) => {
    try {
      await AuthServices.checkEmailUsername(data);
      return true;
    } catch (error) {
      return false;
    }
  };

  const updatePersonalInfo = handleSubmit(async (data) => {
    try {
      await mutateAsync({
        userId: userData?.id,
        name: data?.name + " " + data?.surname,
        email: data?.email,
      });
      toast.success("Personal info updated successfully!");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  });

  const updatePassword = submitPassword(async (data) => {
    try {
      await mutateAsync({
        userId: userData?.id,
        currentPassword: data?.oldPassword,
        newPassword: data?.newPassword,
      });
      setValue("oldPassword", "");
      setValue("newPassword", "");
      setValue("confirmNewPassword", "");
      toast.success("Password updated successfully!");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  });

  const updateTwoStepAuth = submitAuthSettings(async (data) => {
    try {
      await mutateAsync({
        userId: userData?.id,
        twoStepAuth: isTwoAuth,
        phone: data?.phone,
      });
      toast.success("Two step authentication updated successfully!");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  });

  const updateNotifications = async () => {
    try {
      await updateNotificationsData({
        userId: userData?.id,
        isNotification: isEnableNotifications,
      });
      await mutateAsync({
        userId: userData?.id,
        isNotification: isEnableNotifications,
      });
      toast.success("Notifications updated successfully!");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  const handleUserPhoto = async (e: any) => {
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(e.target.files[0]);
      reader.onload = async () => {
        // @ts-ignore
        const buffer = Buffer.from(reader.result);
        try {
          const photoUrl = await updateUserPhoto({
            file: buffer,
            fileName: e.target.files[0]?.name,
            fileType: e.target.files[0]?.type,
          });
          setUserData({ ...userData, profileUrl: photoUrl?.data });
          toast.success("Profile photo updated successfully!");
        } catch (error: any) {
          toast.error(error?.response?.data?.error);
        }
      };
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };
  return {
    isTwoAuth,
    setIsTwoAuth,
    control,
    errors,
    controlPass,
    err,
    getValues,
    checkEmail,
    updatePersonalInfo,
    updatePassword,
    userData,
    updateTwoStepAuth,
    isEnableNotifications,
    setIsEnableNotifications,
    updateNotifications,
    handleUserPhoto,
    phoneErrors,
    controlPhone,
  };
};

export default useProfileHook;
