import useAuthStore from "../../../stores/auth/auth";
import axios from "axios";

export const AdminServices = {
  getAllUsers: async (isTransactions?: boolean, params?: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
      params,
    };
    const transactionsQuesry = isTransactions
      ? "?limit=5&createdAtSort=asc"
      : "";
    const endPoint = `/api/admin/user-management/users${transactionsQuesry}`;
    try {
      const allUsers = await axios.get(endPoint, config);
      return allUsers?.data;
    } catch (error) {
      throw error;
    }
  },
  getUserByID: async (userId: string) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/user/${userId}`;
    try {
      const user = await axios.get(endPoint, config);
      return user?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  addCredits: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/funds/${payload?.userId}`;
    try {
      delete payload?.userId;
      const addCredits = await axios.post(endPoint, payload, config);
      return addCredits?.data;
    } catch (error) {
      throw error;
    }
  },
  banUser: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/ban`;
    try {
      const banUser = await axios.post(endPoint, payload, config);
      return banUser?.data;
    } catch (error) {
      throw error;
    }
  },
  unbanUser: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/unban`;
    try {
      const banUser = await axios.post(endPoint, payload, config);
      return banUser?.data;
    } catch (error) {
      throw error;
    }
  },
  updateUser: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/user/${payload?.userId}`;
    try {
      delete payload?.userId;
      const updateUser = await axios.patch(endPoint, payload, config);
      return updateUser?.data;
    } catch (error) {
      throw error;
    }
  },
  addBalance: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/user/balance/${payload?.userId}`;
    try {
      delete payload?.userId;
      const addBalance = await axios.patch(endPoint, payload, config);
      return addBalance?.data;
    } catch (error) {
      throw error;
    }
  },
  getUsersCount: async () => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/users/count`;
    try {
      const usersCount = await axios.get(endPoint, config);
      return usersCount?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getStats: async () => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/league-management/league/stats`;
    try {
      const stats = await axios.get(endPoint, config);
      return stats?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  getLeaguesCount: async () => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/game/league/active/count`;
    try {
      const leaguesCount = await axios.get(endPoint, config);
      return leaguesCount?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getLatestTransactions: async (params?: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
      params,
    };
    const endPoint = `/api/payment/transactions`;
    try {
      const latestTransactions = await axios.get(endPoint, config);
      return latestTransactions?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getTransactionsCount: async () => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endpoint = `/api/payment/transactions/count/all`;
    try {
      const transactionsCount = await axios.get(endpoint, config);
      return transactionsCount?.data;
    } catch (error) {
      throw error;
    }
  },
  deleteUser: async (userId: string) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/user/${userId}`;
    try {
      const deleteUser = await axios.delete(endPoint, config);
      return deleteUser?.data;
    } catch (error) {
      throw error;
    }
  },
  makeWithdrawal: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/withdrawal`;
    try {
      const withdrawal = await axios.post(endPoint, payload, config);
      return withdrawal?.data;
    } catch (error) {
      throw error;
    }
  },
  inviteUsers: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/email`;
    try {
      const invite = await axios.post(endPoint, payload, config);
      return invite?.data;
    } catch (error) {
      throw error;
    }
  },
  archiveLeague: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/league-management/league/${payload?.leagueId}/archive
    `;
    try {
      const archiveLeague = await axios.patch(endPoint, config);
      return archiveLeague?.data;
    } catch (error) {
      throw error;
    }
  },
  getFAQ: async () => {
    const endPoint = `/api/admin/policy/faq
    `;
    try {
      const faq = await axios.get(endPoint);
      return faq?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  addFAQSection: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/faq/section`;
    try {
      const faqSection = await axios.post(endPoint, payload, config);
      return faqSection?.data;
    } catch (error) {
      throw error;
    }
  },
  addFAQQuestion: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/faq/question`;
    try {
      const faqQuestion = await axios.post(endPoint, payload, config);
      return faqQuestion?.data;
    } catch (error) {
      throw error;
    }
  },
  changeFAQPriority: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/faq/section/priority`;
    try {
      const faqPriority = await axios.post(endPoint, payload, config);
      return faqPriority?.data;
    } catch (error) {
      throw error;
    }
  },
  editFAQ: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/faq
    `;
    try {
      const faq = await axios.patch(endPoint, payload, config);
      return faq?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  deleteFAQSection: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/faq/section`;
    try {
      const faqSection = await axios.delete(endPoint, {
        data: payload,
        headers: config.headers,
      });
      return faqSection?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  deleteFAQQuestion: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/faq/question`;
    try {
      const faqQuestion = await axios.delete(endPoint, {
        data: payload,
        headers: config.headers,
      });
      return faqQuestion?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  changeFAQQuestionPriority: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/faq/question/priority`;
    try {
      const faqPriority = await axios.post(endPoint, payload, config);
      return faqPriority?.data;
    } catch (error) {
      throw error;
    }
  },
  getPrivacyPolicy: async () => {
    const endPoint = `/api/admin/policy/privacy-policy`;
    try {
      const privacyPolicy = await axios.get(endPoint);
      return privacyPolicy?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  addPrivacyPolicy: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/privacy-policy/term`;
    try {
      const privacyPolicy = await axios.post(endPoint, payload, config);
      return privacyPolicy?.data;
    } catch (error) {
      throw error;
    }
  },
  changePrivacyPriority: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/privacy-policy/priority`;
    try {
      const privacyPolicy = await axios.post(endPoint, payload, config);
      return privacyPolicy?.data;
    } catch (error) {
      throw error;
    }
  },
  editPrivacyPolicy: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/privacy-policy
    `;
    try {
      const privacyPolicy = await axios.patch(endPoint, payload, config);
      return privacyPolicy?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  deletePrivacyPolicy: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/privacy-policy/term`;
    try {
      const privacyPolicy = await axios.delete(endPoint, {
        data: payload,
        headers: config.headers,
      });
      return privacyPolicy?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  getTermsAndConditions: async () => {
    const endPoint = `/api/admin/policy/conditions`;
    try {
      const termsAndConditions = await axios.get(endPoint);
      return termsAndConditions?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  addTermsAndConditions: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/conditions/content`;
    try {
      const termsAndConditions = await axios.post(endPoint, payload, config);
      return termsAndConditions?.data;
    } catch (error) {
      throw error;
    }
  },
  addTermsAndConditionsTitle: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/conditions/title`;
    try {
      const termsAndConditions = await axios.post(endPoint, payload, config);
      return termsAndConditions?.data;
    } catch (error) {
      throw error;
    }
  },
  changeTermsAndConditions: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/conditions/priority`;
    try {
      const termsAndConditions = await axios.post(endPoint, payload, config);
      return termsAndConditions?.data;
    } catch (error) {
      throw error;
    }
  },
  editTermsAndConditions: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/conditions`;
    try {
      const termsAndConditions = await axios.patch(endPoint, payload, config);
      return termsAndConditions?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  deleteTermsAndConditions: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/conditions/content`;
    try {
      const termsAndConditions = await axios.delete(endPoint, {
        data: payload,
        headers: config.headers,
      });
      return termsAndConditions?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  deleteTermsAndConditionsTitle: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/policy/conditions/title`;
    try {
      const termsAndConditions = await axios.delete(endPoint, {
        data: payload,
        headers: config.headers,
      });
      return termsAndConditions?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  deleteUserFromLeague: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/league-management/user/remove`;
    try {
      const deleteUser = await axios.delete(endPoint, {
        data: payload,
        headers: config.headers,
      });
      return deleteUser?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  updateUserPassword: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/user/password/change/${payload?.userId}`;
    try {
      delete payload?.userId;
      const updateUserPassword = await axios.patch(endPoint, payload, config);
      return updateUserPassword?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  removePLayerFromTeam: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
      data: payload,
    };
    const endPoint = `/api/admin/player-management/delete`;
    try {
      const removePlayer = await axios.delete(endPoint, config);
      return removePlayer?.data;
    } catch (error) {
      throw error;
    }
  },
  addPlayerToTeam: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/player-management/add`;
    try {
      const addPlayer = await axios.post(endPoint, payload, config);
      return addPlayer?.data;
    } catch (error) {
      throw error;
    }
  },
  updateTeamBalance: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/game/team/${payload?.teamId}`;
    try {
      delete payload?.teamId;
      const updateBalance = await axios.patch(endPoint, payload, config);
      return updateBalance?.data;
    } catch (error) {
      throw error;
    }
  },
};
