import { forwardRef, useEffect, useRef, useState } from "react";

import clsx from "clsx";

import { LabeledInputPropsType } from "./type";
import { Label } from "../Label/Label";
import { Input } from "../Input/Input";
import { DescriptionView } from "../DescriptionView/DescriptionView";
import { ErrorMsg } from "../ErrorMsg/ErrorMsg";

const LabeledCodeInput = forwardRef<HTMLInputElement, LabeledInputPropsType>(
  (
    {
      label,
      error,
      labelClassName,
      errorClassName,
      containerClassName,
      inputContainerClassName,
      description,
      ...rest
    }: Omit<LabeledInputPropsType, "ref">,
    _ref
  ) => {
    const [code1, setCode1] = useState("");
    const [code2, setCode2] = useState("");
    const [code3, setCode3] = useState("");

    useEffect(() => {
      rest?.onChange(code1 + code2 + code3);
    }, [code1, code2, code3]);

    // Refs to control each digit input element
    const inputRefs = [useRef(null), useRef(null), useRef(null)] as any;

    function handleInput(e: any, index: number) {
      const input = e.target;
      const previousInput = inputRefs[index - 1];
      const nextInput = inputRefs[index + 1];
      if (index === 0) {
        setCode1(input.value);
      } else if (index === 1) {
        setCode2(input.value);
      } else if (index === 2) {
        setCode3(input.value);
      }
      if (input.value === "") {
        if (previousInput) {
          previousInput?.current?.focus();
        }
      } else if (input?.value?.length === 2) {
        nextInput?.current?.focus();
      }
    }

    // Select the contents on focus
    function handleFocus(e: any) {
      e.target.select();
    }

    // Handle backspace key
    function handleKeyDown(e: any, index: number) {
      const input = e.target;
      const previousInput = inputRefs[index - 1];
      if ((e.keyCode === 8 || e.keyCode === 46) && input.value === "") {
        e.preventDefault();
        if (index === 0) {
          setCode1((prevCode) => prevCode.slice(0, -1));
        } else if (index === 1) {
          setCode2((prevCode) => prevCode.slice(0, -1));
        } else if (index === 2) {
          setCode3((prevCode) => prevCode.slice(0, -1));
        }
        if (previousInput) {
          previousInput?.current?.focus();
        }
      }
    }

    // Capture pasted characters
    const handlePaste = (e: any) => {
      const pastedCode = e.clipboardData.getData("text");
      if (pastedCode.length === 6) {
        inputRefs.forEach((inputRef: any, index: number) => {
          let code = "";
          if (index === 0) {
            setCode1(pastedCode[0] + pastedCode[1]);
            code = pastedCode[0] + pastedCode[1];
          } else if (index === 1) {
            setCode2(pastedCode[2] + pastedCode[3]);
            code = pastedCode[2] + pastedCode[3];
          } else if (index === 2) {
            setCode3(pastedCode[4] + pastedCode[5]);
            code = pastedCode[4] + pastedCode[5];
          }
          inputRef!.current!.value = code;
        });
      }
    };

    return (
      <div className={clsx("flex flex-col gap-2 w-full", containerClassName)}>
        {!!label && (
          <Label className={labelClassName} htmlFor={rest?.id ?? rest?.name}>
            {label}
          </Label>
        )}
        <div className="flex flex-row gap-2">
          {inputRefs?.map((_input: any, index: number) => (
            <Input
              key={index}
              ref={inputRefs[index]}
              containerClassName={inputContainerClassName}
              error={error}
              id={rest?.id ?? rest?.name}
              maxLength={2}
              onFocus={handleFocus}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onChange={(e) => handleInput(e, index)}
              onPaste={handlePaste}
              inputMode="numeric"
              placeholder={rest?.placeholder}
              inputClassName="placeholder:text-center text-center"
            />
          ))}
        </div>
        {description && <DescriptionView description={description} />}
        {error && <ErrorMsg className={errorClassName} error={error} />}
      </div>
    );
  }
);
export default LabeledCodeInput;
