import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthSessionServices } from "../../services/AuthSession";
import { Button } from "../../components";
import { ReactComponent as BackLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings-white-icon.svg";
import { ReactComponent as Bell } from "../../assets/icons/bell-white.svg";
import { ReactComponent as BellRed } from "../../assets/icons/bell-red.svg";
import useAuthStore from "../../stores/auth/auth";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as NavIcon } from "../../assets/icons/nav-bottom.svg";
import MobileMenu from "../../assets/icons/menu.svg";
import MobileMenuClose from "../../assets/icons/mobileClose.svg";
import clsx from "clsx";
import MobileSidebar from "../../components/MobileSidebar/MobileSidebar.tsx";
import { useContext, useEffect, useState } from "react";
import NotificationCenter from "../../components/NotificationCenter/NotificationCenter.tsx";
import { useCheckIndicator } from "../../queries/notificationsQueries/notificationsQueries.tsx";
import appContext from "../../context/appContext.ts";
import demo_team_logo from "../../assets/images/demoTeamLogo.png";
import { useFetchUserDetails } from "../../queries/userQueries/userQueries.tsx";
import { numberWithCommas } from "../../utils/helpers.ts";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useAuthStore.getState();
  const isAdmin = userData?.role === "admin";
  const [isOpenNotCenter, setIsOpenNotCenter] = useState(false);
  const { data, refetch } = useCheckIndicator(
    userData?.id,
    {
      status: "unread",
      limit: 1,
    },
    isAdmin
  );
  const { data: details } = useFetchUserDetails(userData?.id);
  const { activeDrafts } = useContext(appContext);
  const balance =
    Number(details?.balance?.amount) + Number(details?.balance?.credit_amount);

  useEffect(() => {
    refetch();
    setIsOpenNotCenter(false);
  }, [location?.pathname, activeDrafts]);

  type Route = {
    name: string;
    path: string;
    isEnabled: boolean;
    selectedIcon?: JSX.Element;
  };

  const routes: Route[] = [
    {
      name: "HOME",
      path: "/dashboard",
      isEnabled: true,
      selectedIcon: NavIcon,
    },
    {
      name: "LEAGUES",
      path: "/leagues",
      isEnabled: true,
      selectedIcon: NavIcon,
    },
    {
      name: "DRAFTS",
      path: "/drafts",
      isEnabled: true,
      selectedIcon: NavIcon,
    },
    {
      name: "FINANCES",
      path: "/finances",
      isEnabled: true,
      selectedIcon: NavIcon,
    },
    {
      name: "HELP",
      path: "/help",
      isEnabled: true,
      selectedIcon: NavIcon,
    },
  ];
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useAuthStore.getState();

  const titleComponent = () => {
    if (location.pathname === "/leagues") {
      return (
        <div>
          <h1 className="font-absoluteEmpire text-[40px] leading-none">
            List of leagues
          </h1>
          <p className="text-text-grey">
            There is the latest update for the last 7 days, check now
          </p>
        </div>
      );
    } else if (location.pathname === "/users") {
      return (
        <div>
          <h1 className="font-absoluteEmpire text-[40px] leading-none">
            View users
          </h1>
          <p className="text-text-grey">
            This is the latest update for the last 7 days.
          </p>
        </div>
      );
    } else if (location.pathname === "/dashboard") {
      return (
        <div>
          <h1 className="font-absoluteEmpire text-[40px] leading-none">
            Welcome back, {userData?.name} ✋
          </h1>
          <p className="text-text-grey">
            This is the latest update for the last 7 days.
          </p>
        </div>
      );
    } else if (location.pathname === "/transactions") {
      return (
        <div>
          <h1 className="font-absoluteEmpire text-[40px] leading-none">
            All transactions
          </h1>
          <p className="text-text-grey">
            You will be able to view all statistical data related to your
            finances
          </p>
        </div>
      );
    } else if (location.pathname?.includes("/draft")) {
      return <div></div>;
    } else {
      return (
        <div
          onClick={() => navigate(-1)}
          className="flex flex-row justify-center items-center cursor-pointer"
        >
          <BackLeft />
          <span className="font-gilroyMedium text-base ml-1">Back</span>
        </div>
      );
    }
  };
  return (
    <>
      <div className="flex flex-row w-full py-4 justify-between items-center px-4 md:px-10">
        <div className="block w-[30px] h-[22px]  md:hidden">
          {!isMobileMenuOpen ? (
            <img
              onClick={() => setIsMobileMenuOpen(true)}
              src={MobileMenu}
              alt=""
            />
          ) : (
            <img
              onClick={() => setIsMobileMenuOpen(false)}
              src={MobileMenuClose}
              alt=""
            />
          )}
        </div>
        {isAdmin ? (
          <>
            <div className="hidden md:flex w-full justify-between">
              {titleComponent()}
            </div>
            <Logo
              className="cursor-pointer px-3 md:hidden"
              onClick={() => navigate("/dashboard")}
            />
            <div className="mb-0 flex items-center gap-2">
              <div className="relativee">
                <Button
                  mode="secondary"
                  className="!w-fit !h-fit p-2"
                  leftIcon={data?.length ? <BellRed /> : <Bell />}
                  onClick={() => setIsOpenNotCenter(!isOpenNotCenter)}
                >
                  {""}
                </Button>
                {isOpenNotCenter && (
                  <NotificationCenter checkIndicator={refetch} />
                )}
              </div>

              <Button
                mode="secondary"
                className="!w-fit !h-fit p-2"
                leftIcon={<Settings />}
                onClick={() => navigate("/profile")}
              >
                {""}
              </Button>
              <div className="w-[1px] h-[40px] bg-button-bgSolid" />
              <Button
                className="w-fit h-fit px-3 md:w-[90px] rounded-full md:rounded-[8px]"
                onClick={() => AuthSessionServices.clearSession()}
              >
                Log Out
              </Button>
            </div>
          </>
        ) : (
          <>
            <Logo
              className="cursor-pointer px-3"
              onClick={() => navigate("/dashboard")}
            />
            <div className="flex flex-row justify-center items-center hidden md:flex">
              {routes.map((route, _index) => {
                const isActive =
                  location.pathname === route.path ||
                  location.pathname.includes(route.path);
                return (
                  <Link
                    key={route.name}
                    to={route.path}
                    className={clsx(
                      "w-[160px] font-absoluteEmpire flex flex-col justify-start items-center",
                      route.isEnabled
                        ? "cursor-pointer"
                        : "cursor-not-allowed text-text-grey pointer-events-none"
                    )}
                    aria-disabled={!route.isEnabled}
                  >
                    <span
                      className={clsx(
                        "px-4 py-2 font-absoluteEmpire mb-[19px]",
                        isActive && "text-text-red"
                      )}
                    >
                      {route.name}
                    </span>
                    {isActive && route.selectedIcon ? (
                      <NavIcon />
                    ) : (
                      <div className="h-2" />
                    )}
                  </Link>
                );
              })}
            </div>
            <div className="mb-0 flex items-center gap-2 md:gap-4 md:mb-[19px]">
              <div className="relativee">
                <Button
                  mode="secondary"
                  className="!w-fit !h-fit p-2"
                  leftIcon={data?.length ? <BellRed /> : <Bell />}
                  onClick={() => setIsOpenNotCenter(!isOpenNotCenter)}
                >
                  {""}
                </Button>
                {isOpenNotCenter && (
                  <NotificationCenter checkIndicator={refetch} />
                )}
              </div>

              <Button
                mode="secondary"
                className="!w-fit !h-fit p-2"
                leftIcon={<Settings />}
                onClick={() => navigate("/profile")}
              >
                {""}
              </Button>
              <div className="w-[1px] h-[40px] bg-button-bgSolid" />
              <div>
                <div className="flex gap-4 items-center">
                  <img
                    src={details?.profileUrl || demo_team_logo}
                    alt="CutThroat"
                    className="hidden w-[48px] h-[48px] rounded-[50px] md:block"
                  />
                  <div>
                    <Button
                      mode="secondary"
                      className="w-fit h-fit px-3 md:w-[70px] max-h-[30px] rounded-[6px] mb-1"
                      onClick={() => AuthSessionServices.clearSession()}
                    >
                      Log Out
                    </Button>
                    <p className="text-base font-gilroyBold">
                      @{userData?.username}
                    </p>
                    <p
                      onClick={() => navigate("/finances")}
                      className="text-text-grey text-xs font-gilroyMedium cursor-pointer"
                    >
                      ${numberWithCommas(balance?.toFixed(2))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {isMobileMenuOpen && <MobileSidebar />}
    </>
  );
};

export default Header;
