import React from "react";
import clsx from "clsx";
import { TablePropsType, TableSizes } from "./type";
import { LoadingSkeleton } from "../LoadingSkeleton/LoadingSkeleton";

const classNameSizes: Record<TableSizes, string> = {
  sm: "h-[31px] pr-6 pl-4 py-[6px]",
  lg: "h-[52px] p-2",
};

const tableClassNameSize: Record<TableSizes, string> = {
  sm: "py-[10px]",
  lg: "",
};

export const TeamPlayersTable: React.FC<TablePropsType> = ({
  columns = [],
  dataSource = [],
  onClickRow,
  emptyState,
  tableClassName,
  containerClass,
  footer = null,
  size = "lg",
  overrideTableContainerClass,
  loading,
}) => {
  return (
    <div className={clsx("overflow-hidden", containerClass)}>
      <div
        className={clsx(
          "bg-card-bgGreyDark rounded-b-[12px] px-6 overflow-hidden",
          tableClassNameSize[size],
          overrideTableContainerClass
        )}
      >
        <table className={clsx("min-w-full text-left", tableClassName)}>
          <thead className="text-base border-b-[1px] border-modal-modalStroke">
            <tr>
              {columns.map(({ title, thClassName = "", dataIndex }) => (
                <th
                  scope="col"
                  key={dataIndex}
                  className={clsx(
                    "text-base font-gilroyMedium text-text-subGrey leading-none",
                    classNameSizes[size],
                    thClassName
                  )}
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm font-medium divide-y divide-modal-modalStroke">
            {!dataSource?.length || loading ? (
              <tr className="text-text-gray h-52 relative w-full">
                <td
                  colSpan={100}
                  className="flex gap-2 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-lg w-full justify-center items-center"
                >
                  {loading ? (
                    <LoadingSkeleton className="w-full" />
                  ) : (
                    emptyState ?? <>No data available</>
                  )}
                </td>
              </tr>
            ) : (
              dataSource?.map((item, index) => (
                <tr
                  tabIndex={0}
                  key={index}
                  className={clsx(onClickRow && "cursor-pointer")}
                  onClick={(e) => {
                    if (onClickRow) {
                      onClickRow(index, item, e as any);
                    }
                  }}
                >
                  {columns.map(({ dataIndex, render, tdClassName }) => {
                    return (
                      <td
                        key={dataIndex}
                        style={{
                          wordBreak: "break-word",
                        }}
                        className={clsx(
                          "relative text-base max-w-xs leading-none",
                          classNameSizes[size],
                          tdClassName
                        )}
                      >
                        {render
                          ? render(item[dataIndex], item)
                          : item[dataIndex]}
                      </td>
                    );
                  })}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {footer ? (
        <div
          className={clsx(
            "relative text-base leading-none",
            classNameSizes[size]
          )}
        >
          {footer}
        </div>
      ) : null}
    </div>
  );
};
