import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
// import { ReactComponent as Lock } from "../../../assets/icons/lock-icon.svg";
import { Controller } from "react-hook-form";
import LabeledInput from "../../../components/LabeledInput/LabeledInput";
import LabeledPasswordInput from "../../../components/LabeledPasswordInput/LabeledPasswordInput";
import { Checkbox } from "../../../components/Checkbox/Checkbox";
import { Button } from "../../../components/Button/Button";
import { validateEmail } from "../../../utils/helpers";
import LabeledCodeInput from "../../../components/LabeledCodeInput/LabeledCodeInput";
import useLoginHook from "./hooks/useLogin";
import { useNavigate } from "react-router-dom";
// import useProfileHook from "../../private/UserPages/Profile/hooks/useProfile.tsx";

const Login = () => {
  const {
    email,
    randomImageSrc,
    control,
    errors,
    activeTab,
    isRemembered,
    setIsRemembered,
    onSubmit,
  } = useLoginHook();
  // const {isTwoAuth} = useProfileHook()
  const navigate = useNavigate();

  const privacyPoliceBlock = () => {
    return (
      <div className="flex justify-center">
        <p className="text-xs md:text-sm text-text-grey text-center">
          <span
            onClick={() => navigate("/privacy-policy")}
            className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer"
          >
            Privacy Policy,
          </span>{" "}
          <span
            onClick={() => navigate("/terms-conditions")}
            className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer"
          >
            Terms and Conditions,
          </span>{" "}
          <span
            onClick={() => navigate("/help")}
            className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer"
          >
            FAQ,
          </span>{" "}
          <a href="mailto:cutthroatfantasysports@gmail.com">
            Contact us:{" "}
            <span className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer">
              cutthroatfantasysports@gmail.com
            </span>
          </a>
        </p>
      </div>
    );
  };

  return (
    <div className="flex flex-row p-0 md:p-2 h-svh">
      <div className="p-10 w-full flex flex-col justify-between items-center bg-auth-mobile bg-cover bg-center md:bg-none">
        <div className="relative w-full max-w-[397px] flex justify-center">
          <Logo />
        </div>
        <div className="mt-[64px] max-w-[397px] h-full mb-[42px]">
          <h1 className="text-text-white font-absoluteEmpire text-[40px] text-center">
            {activeTab === 1 ? "LOGIN" : "CONFIRM EMAIL"}
          </h1>
          <p className="mt-2 text-text-subGrey text-center">
            {activeTab === 1
              ? "Whether you're a seasoned veteran or a newcomer to the game, login to access your team and join the action"
              : `We've sent a six-digit code to ${
                  validateEmail(email) ? email : "your email"
                }. Please check your inbox and enter the code below to verify your identity.`}
          </p>
          <div>
            {activeTab === 1 && (
              <div>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <LabeledInput
                      {...field}
                      required
                      placeholder="Enter your email or username"
                      error={errors?.email?.message}
                      errorClassName="text-text-red"
                      label="Your Email or Username"
                      containerClassName="mt-[32px]"
                      style={{ caretColor: "red" }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <LabeledPasswordInput
                      {...field}
                      required
                      placeholder="Enter your password"
                      error={errors?.password?.message}
                      errorClassName="text-text-red"
                      label="Your password"
                      containerClassName="mt-6"
                      style={{ caretColor: "red" }}
                    />
                  )}
                />
              </div>
            )}
            {activeTab === 2 && (
              <Controller
                control={control}
                name="code"
                render={({ field }) => (
                  <LabeledCodeInput
                    {...field}
                    placeholder="00"
                    error={errors?.code?.message}
                    errorClassName="text-text-red"
                    label="Enter Verification Code"
                    containerClassName="mt-6 mb-[32px]"
                    inputContainerClassName="text-center flex justify-center items-center"
                    style={{ caretColor: "red" }}
                  />
                )}
              />
            )}
            {activeTab === 1 && (
              <div className="flex flex-row justify-between items-center">
                <Checkbox
                  checked={isRemembered}
                  key={"remember"}
                  containerClassName="my-6"
                  onChange={() => setIsRemembered(!isRemembered)}
                >
                  <p className="text-text-white text-[12px] ml-1 cursor-pointer">
                    Remember this device for 30 days
                  </p>
                </Checkbox>
                <a
                  className="text-text-white underline cursor-pointer ml-3 text-right"
                  href="/forgot-password"
                >
                  Forgot Password?
                </a>
              </div>
            )}

            <Button onClick={onSubmit} className="w-full text-base">
              Continue
            </Button>
            <p className="text-text-white text-base font-gilroyMedium text-center mt-4">
              Don`t have an account?{" "}
              <a
                className="text-text-white underline cursor-pointer"
                href="/signup"
              >
                Sign up
              </a>
            </p>
            <div className="mt-2 md:hidden">{privacyPoliceBlock()}</div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-auto">
          {/*{isTwoAuth && <div className="flex flex-col justify-center items-center mt-auto">*/}
          {/*  <Lock className="mx-auto"/>*/}
          {/*  <p className="mt-1 text-center text-xs md:text-sm">*/}
          {/*    Your account is secured via Two-Factor Authentication.*/}
          {/*  </p>*/}
          {/*  <p className="text-center text-xs md:text-sm">*/}
          {/*    You can disable it in the profile settings.*/}
          {/*  </p>*/}
          {/*</div>}*/}
          <div className="mt-2 hidden md:block">{privacyPoliceBlock()}</div>
        </div>
      </div>
      <img
        src={randomImageSrc}
        alt="CutThroat"
        className="w-[50%] hidden sm:block"
      />
    </div>
  );
};

export default Login;
