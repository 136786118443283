import { Disclosure } from "@headlessui/react";
import useHelp from "./hooks/useHelp";
import SectionItem from "./components/SectionItem";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow-down-icon.svg";
import { ReactComponent as Edit } from "../../../../assets/icons/pencil-icon.svg";
import { ReactComponent as Plus } from "../../../../assets/icons/plus-white-icon.svg";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import clsx from "clsx";
import { Button } from "../../../../components";
import EditComponent from "./components/EditComponent";
import { AddSectionModal } from "./components/AddSection";

const Help = () => {
  const {
    data,
    activeSection,
    setActiveSection,
    isEdit,
    setIsEdit,
    allFAQ,
    activeSectionId,
    setActiveSectionId,
    isNewQuestion,
    setIsNewQuestion,
    isAdmin,
  } = useHelp();

  return (
    <>
      {/* Desktop View */}

      <div className="hidden px-10 pb-6 mt-6 w-full flex flex-col justify-center items-center md:flex relative">
        {isAdmin && (
          <Button
            leftIcon={isEdit ? <Check /> : <Edit />}
            onClick={() => setIsEdit(!isEdit)}
            mode={isEdit ? "primary" : "secondary"}
            className="absolute right-10 top-0 w-[90px] rounded-[8px]"
          >
            {isEdit ? "Save" : "Edit"}
          </Button>
        )}
        <div className="flex flex-col justify-center max-w-[505px]">
          <p className="font-absoluteEmpire text-[30px] text-center">
            Frequently asked questions
          </p>

          <p className="font-gilroyMedium text-text-subGrey text-center">
            If you have any questions, we have answers for you here. In case we
            don’t, please feel free to reach out to us via{" "}
            <a
              className="text-text-white"
              href="mailto:cutthroatfantasysports@gmail.com"
            >
              cutthroatfantasysports@gmail.com
            </a>
            .
          </p>
        </div>

        <div className="mt-[80px] flex w-full justify-center">
          <div className="mr-[100px]">
            {allFAQ
              ?.sort((a: any, b: any) => a?.priority - b?.priority)
              ?.map((section: any, index: number) => (
                <SectionItem
                  key={index}
                  isEdit={isEdit}
                  sectionName={section?.name}
                  activeSectionName={activeSection}
                  setActiveSection={setActiveSection}
                  activeSectionId={section?.id}
                  setActiveSectionId={setActiveSectionId}
                  section={section}
                  setIsNewQuestion={setIsNewQuestion}
                />
              ))}
            {isEdit && <AddSectionModal />}
          </div>
          <div className="flex flex-col">
            {!isEdit
              ? allFAQ
                  ?.filter((item: any) => item?.name === activeSection)?.[0]
                  ?.question?.sort(
                    (a: any, b: any) => a?.priority - b?.priority
                  )
                  ?.map((item: any, index: number) => (
                    <Disclosure key={index}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={clsx(
                              "group text-left text-base font-gilroyMedium w-[600px] flex justify-between items-center",
                              open && "!font-gilroyBold"
                            )}
                          >
                            {item?.question}
                            <Arrow className={clsx(open && "rotate-180")} />
                          </Disclosure.Button>
                          <Disclosure.Panel className="w-[600px] mt-6">
                            {item?.answer}
                          </Disclosure.Panel>
                          <div className="w-full h-[1px] bg-modal-modalStroke my-6" />
                        </>
                      )}
                    </Disclosure>
                  ))
              : allFAQ
                  ?.filter((item: any) => item?.name === activeSection)?.[0]
                  ?.question?.sort(
                    (a: any, b: any) => a?.priority - b?.priority
                  )
                  ?.map((item: any, index: number) => (
                    <EditComponent
                      item={item}
                      key={index}
                      sectionId={activeSectionId!}
                    />
                  ))}
            {isEdit && !isNewQuestion && (
              <div
                onClick={() => setIsNewQuestion(true)}
                className="h-[70px] rounded-[8px] border-dashed border-[1px] border-modal-modalStroke w-[500px] flex justify-center items-center cursor-pointer"
              >
                <Plus />
                <p className="font-gilroyBold text-center ml-2">
                  Add new Block
                </p>
              </div>
            )}
            {isEdit && isNewQuestion && (
              <EditComponent sectionId={activeSectionId} />
            )}
          </div>
        </div>
      </div>

      {/* Mobile View */}

      <div className="block px-[16px] pb-6 mt-6 w-full flex flex-col justify-center items-center md:hidden">
        <div className="w-full flex flex-col justify-center gap-5">
          <p className="font-absoluteEmpire text-[30px] text-center">
            Frequently asked questions
          </p>
          <p className="font-gilroyMedium text-text-subGrey text-center">
            If you have any questions, we have answers for you here. In case we
            don’t, please feel free to reach out to us via{" "}
            <a
              className="text-text-white"
              href="mailto:cutthroatfantasysports@gmail.com"
            >
              cutthroatfantasysports@gmail.com
            </a>
            .
          </p>
          <div className="mt-4 rounded-[8px] bg-card-bgGreyMedium px-6 pt-6">
            {data
              ?.sort((a: any, b: any) => a?.priority - b?.priority)
              ?.map((section: any, index: number) => (
                <SectionItem
                  key={index}
                  isEdit={isEdit}
                  sectionName={section?.name}
                  activeSectionName={activeSection}
                  setActiveSection={setActiveSection}
                  activeSectionId={section?.id}
                  setActiveSectionId={setActiveSectionId}
                  section={section}
                  setIsNewQuestion={setIsNewQuestion}
                />
              ))}
          </div>
          <div className="flex flex-col mt-4">
            {data
              ?.filter((item: any) => item?.name === activeSection)?.[0]
              ?.question?.sort((a: any, b: any) => a?.priority - b?.priority)
              ?.map((item: any, index: number) => (
                <Disclosure key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={clsx(
                          "group text-left text-base font-gilroyMedium w-[97%] flex justify-between items-center",
                          open && "!font-gilroyBold"
                        )}
                      >
                        {item?.question}
                        <div className="w-fit">
                          <Arrow className={clsx(open && "rotate-180")} />
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="w-full mt-6">
                        {item?.answer}
                      </Disclosure.Panel>
                      <div className="w-full h-[1px] bg-modal-modalStroke my-6" />
                    </>
                  )}
                </Disclosure>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Help;
