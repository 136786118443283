import useAuthStore from "./stores/auth/auth";
import { io } from "socket.io-client";

let socket: any;

const initializeSocket = () => {
  const userData = useAuthStore.getState()?.userData;

  if (userData && !socket) {
    const URL = `${process.env.REACT_APP_API_URL}?userId=${userData.id}`;
    socket = io(URL);
  }
};

useAuthStore.subscribe((newUserData: any) => {
  if (newUserData) {
    initializeSocket();
  }
});

export const getSocket = () => socket;
