import { forwardRef } from "react";

import clsx from "clsx";

import { LabeledInputPropsType } from "./type";
import { Label } from "../../components/Label/Label";
import { Input } from "../../components/Input/Input";
import { DescriptionView } from "../../components/DescriptionView/DescriptionView";
import { ErrorMsg } from "../../components/ErrorMsg/ErrorMsg";

const LabeledInput = forwardRef<HTMLInputElement, LabeledInputPropsType>(
  (
    {
      label,
      error,
      labelClassName,
      errorClassName,
      containerClassName,
      inputContainerClassName,
      description,
      ...rest
    }: Omit<LabeledInputPropsType, "ref">,
    ref
  ) => {
    return (
      <div className={clsx("flex flex-col gap-2 w-full", containerClassName)}>
        {!!label && (
          <Label className={labelClassName} htmlFor={rest?.id ?? rest?.name}>
            {label}
          </Label>
        )}
        <Input
          {...rest}
          ref={ref}
          containerClassName={inputContainerClassName}
          error={error}
          id={rest?.id ?? rest?.name}
        />
        {description && <DescriptionView description={description} />}
        {error && <ErrorMsg className={errorClassName} error={error} />}
      </div>
    );
  }
);
export default LabeledInput;
