import { isValidImg } from "../../../../utils/helpers";
import demo_logo from "../../../../assets/images/ball-logo.png";
import clsx from "clsx";

const LeagueCard = ({ league }: { league: any }) => {
  return (
    <div
      className="flex justify-between items-center border-[1px] border-stroke rounded-[8px]
    px-2 py-3 hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2 md:px-4"
    >
      <div className="w-[54%] gap-1 flex items-center">
        <img
          src={isValidImg(league?.logoUrl) || demo_logo}
          alt="CutThroat"
          className="w-[35px] h-[35px] rounded-[50px] md:w-[38px] md:h-[38px]"
        />
        <p className="w-full text-base font-gilroyBold">{league?.name}</p>
      </div>
      <div className="w-[45%] gap-2 flex items-center justify-between">
        <p className="w-fit text-base font-gilroyBold">
          {league?.team?.length}
          <span className="text-xs text-text-grey font-gilroyMedium">
            /{league?.numberOfTeams}
          </span>
        </p>
        <div
          className={clsx(
            "text-xs font-gilroyBold px-3 py-2 rounded-[16px] bg-gradient-to-br capitalize text-nowrap w-fit md:px-4",
            league?.status === "registration" &&
              "from-elements-chipGradient-1 to-elements-chipGradient-2",
            league?.status === "inProgress" &&
              "from-red-bgGradient2-1 to-red-bgGradient2-2",
            league?.status === "completed" && "bg-white text-screenBgGrey",
            league?.status === "archived" && "bg-white text-screenBgGrey"
          )}
        >
          {league?.status || ""}
        </div>
      </div>
    </div>
  );
};
export default LeagueCard;
