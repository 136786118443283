import { Popover, Transition } from "@headlessui/react";
import { ReactComponent as Info } from "../../assets/icons/info-icon.svg";
import { ReactComponent as Close } from "../../assets/icons/close-icon.svg";
import clsx from "clsx";

export const Popup = ({
  data,
  icon,
  lastItem,
  overrideContainerStyles,
  overrideButtonStyle,
  onButtonClick,
}: {
  data: any;
  icon?: any;
  lastItem?: boolean;
  overrideContainerStyles?: string;
  overrideButtonStyle?: string;
  onButtonClick?: () => void;
}) => {
  return (
    <Popover>
      <Popover.Button
        onClick={onButtonClick}
        className={clsx("flex items-center", overrideButtonStyle)}
      >
        {icon ? icon : <Info />}
      </Popover.Button>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Popover.Panel
          className={clsx(
            "absolute z-10 rounded-xl bg-card-bgGreyLight p-4 max-w-[420px]",
            lastItem && "!top-[-20px] left-[90px]",
            overrideContainerStyles
          )}
        >
          <Popover.Button className="absolute top-1 right-1">
            <Close />
          </Popover.Button>
          {data}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
