import { useUpdateTeamBalance } from "../../../../queries/adminQueries/adminQueries";
import { Button, Input, Modal, useModal } from "../../../../components";
import { ReactComponent as EditRed } from "../../../../assets/icons/edit2-icon.svg";
import { ReactComponent as Plus } from "../../../../assets/icons/plus-white-icon.svg";
import { ReactComponent as Minus } from "../../../../assets/icons/minus-icon.svg";
import { ReactComponent as Dollar } from "../../../../assets/icons/dollar-icon.svg";
import { toast } from "react-toastify";
import { useState } from "react";
import { isNumeric } from "../../../../utils/helpers";

export const UpdateTeamBalanceModal = ({
  teamId,
  faab,
}: {
  teamId: string;
  faab: number | string;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <UpdateTeamBalance closeModal={closeModal} teamId={teamId} faab={faab} />
  );
  const { modalControl, openModal } = useModal({
    title: "Update FAAB Balance",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <EditRed onClick={openModal} className="cursor-pointer ml-2 w-6 h-6" />
    </>
  );
};

const UpdateTeamBalance = ({
  closeModal,
  teamId,
  faab,
}: {
  closeModal?: () => void;
  teamId: string;
  faab: number | string;
}) => {
  const { mutateAsync: updateTeamBalance, isPending } = useUpdateTeamBalance();
  const [amount, setAmount] = useState(faab);
  const updateBalance = async () => {
    try {
      await updateTeamBalance({ teamId, faab_balance: amount });
      toast.success("FAAB balance updated successfully");
      closeModal?.();
    } catch (error) {
      toast.error("Failed to update FAAB balance");
    }
  };
  return (
    <>
      <p className="font-gilroyMedium text-text-subGrey mt-2">
        Update Free Agent Acquisition Budget for this team.
      </p>
      <div className="flex w-full justify-center items-center gap-2 mt-6">
        <Button
          leftIcon={<Minus />}
          mode="secondary"
          className="!h-[44px] !w-[44px]"
          onClick={() => {
            if (Number(amount) > 0 && typeof Number(amount) === "number") {
              setAmount(Number(amount) - 1);
            }
          }}
        >
          {""}
        </Button>
        <Input
          leftIcon={<Dollar className="mt-[-3px]" />}
          value={amount}
          containerClassName="!w-[120px] h-[48px] bg-modal-inputBg rounded-[8px] border-[2px] border-filterContainer py-3"
          inputClassName="placeholder-text-gray text-sm placeholder-font-gilroyMedium text-center"
          placeholder="Enter your bid"
          onChange={(e: any) => {
            if (isNumeric(e?.target?.value) || e?.target?.value === "") {
              setAmount(Number(e?.target?.value));
            }
          }}
        />
        <Button
          leftIcon={<Plus />}
          mode="primary"
          className="!h-[44px] !w-[44px]"
          onClick={() => {
            if (Number(amount) >= 0 && typeof Number(amount) === "number") {
              setAmount(Number(amount) + 1);
            }
          }}
        >
          {""}
        </Button>
      </div>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
        <Button
          onClick={updateBalance}
          size="md"
          className="!text-base w-full !rounded-[8px]"
          disabled={isPending}
        >
          Update
        </Button>
      </div>
    </>
  );
};

export default UpdateTeamBalanceModal;
