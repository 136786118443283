import { isValidImg } from "../../../../utils/helpers";
import { Button, Modal, useModal } from "../../../../components";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import { ReactComponent as Radio } from "../../../../assets/icons/radio-button.svg";
import { useState } from "react";
import { useReplacePlayer } from "../../../../queries/playerQueries/playerQueries";
import { toast } from "react-toastify";
import clsx from "clsx";
import { Oval } from "react-loader-spinner";

export const PickPlayerModal = ({
  players,
  currentPlayer,
  isBid = false,
  makeBid,
  refetchWaiver,
  isBidPending,
  refetchTeam,
}: {
  players: any;
  currentPlayer: any;
  isBid: boolean;
  makeBid?: () => void;
  refetchWaiver?: () => void;
  isBidPending?: boolean;
  refetchTeam?: () => void;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <PickPlayer
      closeModal={closeModal}
      players={players}
      currentPlayer={currentPlayer}
      isBid={isBid}
      makeBid={makeBid}
      refetchWaiver={refetchWaiver}
      isBidPending={isBidPending}
      refetchTeam={refetchTeam}
    />
  );
  const { modalControl, openModal } = useModal({
    title: "Replace a player",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      {isBid ? (
        <Button
          onClick={openModal}
          disabled={players?.find(
            (item: any) => item?.playerId === currentPlayer?.id
          )}
          mode="primary"
          className="!text-base w-full !rounded-[8px]"
        >
          Add Bid
        </Button>
      ) : (
        <Button
          onClick={openModal}
          disabled={players?.find(
            (item: any) => item?.playerId === currentPlayer?.id
          )}
          mode="primary"
          className="rounded-[8px] !h-[40px] !w-[208px] px-2"
        >
          Add for Free
        </Button>
      )}
    </>
  );
};

const PickPlayer = ({
  closeModal,
  players,
  currentPlayer,
  makeBid,
  isBid,
  refetchWaiver,
  isBidPending,
  refetchTeam,
}: {
  closeModal?: () => void;
  players: any;
  currentPlayer: any;
  makeBid?: (playerId: number) => void;
  isBid: boolean;
  refetchWaiver?: () => void;
  isBidPending?: boolean;
  refetchTeam?: () => void;
}) => {
  const { mutateAsync, isPending } = useReplacePlayer();
  const [selectedPlayer, setSelectedPlayer] = useState<any>(null);

  const positions = ["RB", "WR", "TE"];

  const filteredPlayers = positions?.reduce((acc: any, pos: any) => {
    acc[pos] = players?.filter((player: any) => player?.position === pos);
    return acc;
  }, {});

  const multiPlayerPositions =
    currentPlayer?.primaryPosition !== "QB"
      ? positions
          ?.filter((item) => item !== currentPlayer?.primaryPosition)
          ?.filter((pos) => filteredPlayers?.[pos]?.length > 1)
      : [];

  const playersForReplace = [
    ...players?.filter((item: any) => {
      if (currentPlayer?.primaryPosition === "QB") {
        return item?.position === "QB";
      } else {
        return item?.position === currentPlayer?.primaryPosition;
      }
    }),
    ...multiPlayerPositions?.flatMap((pos) => filteredPlayers?.[pos]),
  ];

  const onReplacePlayer = async () => {
    try {
      if (isBid) {
        await makeBid?.(selectedPlayer?.playerId);
      } else {
        await mutateAsync({
          teamId: selectedPlayer?.teamId,
          currentPlayerId: selectedPlayer?.playerId,
          finalPlayerId: currentPlayer?.id,
        });
        refetchTeam?.();
        refetchWaiver?.();
        toast.success("Player added successfully!");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      closeModal?.();
    }
  };

  return (
    <>
      <p className="text-[24px] font-gilroySemibold my-2">
        Pick a player you want to replace.
      </p>

      <div className="flex flex-col gap-2 ml-6">
        {playersForReplace?.map((player: any) => (
          <div
            key={player?.id}
            className={clsx("flex justify-between cursor-pointer")}
            onClick={() => setSelectedPlayer(player)}
          >
            <div className="flex items-center gap-3">
              {selectedPlayer?.id! === player?.id ? (
                <Radio />
              ) : (
                <div className="w-[18px] h-[18px] rounded-[15px] bg-stroke" />
              )}
              <div className="flex items-center gap-2">
                <img
                  src={isValidImg(player?.avatar) || demo_team_logo}
                  alt="CutThroat"
                  className="w-[30px] h-[30px] rounded-[50px] md:w-[40px] md:h-[40px]"
                />
                <div>
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-1">
                      <p className="font-gilroyBold text-base">
                        {player?.name}
                      </p>
                    </div>
                    <p className="text-text-grey text-left text-xs font-gilroyMedium">
                      {player?.position}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Close
        </Button>
        <Button
          onClick={onReplacePlayer}
          disabled={isPending || !selectedPlayer || isBidPending}
          leftIcon={
            <Oval
              visible={isPending || isBidPending}
              height="20"
              width="20"
              color="#000000"
              secondaryColor="#ffffff"
            />
          }
          size="md"
          mode="primary"
          className="!text-base w-full !rounded-[8px]"
        >
          Confirm
        </Button>
      </div>
    </>
  );
};

export default PickPlayerModal;
