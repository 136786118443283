import { useRemovePlayerFromTeam } from "../../../../queries/adminQueries/adminQueries";
import { Button, Modal, useModal } from "../../../../components";
import { toast } from "react-toastify";

export const RemovePlayerModal = ({
  teamId,
  playerId,
  week,
}: {
  teamId: string;
  playerId: string;
  week: number | null;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <RemovePlayer
      closeModal={closeModal}
      teamId={teamId}
      playerId={playerId}
      week={week}
    />
  );
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        className="h-[41px] w-[65px] rounded-[8px]"
        onClick={openModal}
        mode="secondary"
      >
        Drop
      </Button>
    </>
  );
};

const RemovePlayer = ({
  closeModal,
  teamId,
  playerId,
  week,
}: {
  closeModal?: () => void;
  teamId: string;
  playerId: string;
  week: number | null;
}) => {
  const { mutateAsync: removePlayer, isPending } = useRemovePlayerFromTeam();
  const dropPlayer = async () => {
    try {
      await removePlayer({ teamId, playerId, week });
      toast.success("Player removed from team");
    } catch (error) {
      toast.error("Failed to remove player from team");
    } finally {
      closeModal?.();
    }
  };
  return (
    <>
      <p className="text-[24px] font-gilroySemibold mb-2">
        Do you want to remove this player from the team?
      </p>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
        <Button
          onClick={dropPlayer}
          size="md"
          className="!text-base w-full !rounded-[8px]"
          disabled={isPending}
        >
          Confirm
        </Button>
      </div>
    </>
  );
};

export default RemovePlayerModal;
