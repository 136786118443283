import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Lock } from "../../../../assets/icons/lock-icon.svg";
import { Controller, useForm } from "react-hook-form";
import { useUpdateUserPassword } from "../../../../queries/adminQueries/adminQueries";
import { toast } from "react-toastify";
import { containsNumber, containsUppercase } from "../../../../utils/helpers";
import { Oval } from "react-loader-spinner";
import LabeledPasswordInput from "../../../../components/LabeledPasswordInput/LabeledPasswordInput";

export const ChangePasswordModal = ({ userId }: { userId: string }) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <ChangePassword closeModal={closeModal} userId={userId} />
  );
  const { modalControl, openModal } = useModal({
    title: "Update User Password",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        leftIcon={<Lock />}
        mode="secondary"
        onClick={openModal}
        className="rounded-[8px] w-full px-4 text-nowrap !h-10 block md:flex"
      >
        Update Password
      </Button>
    </>
  );
};

const ChangePassword = ({
  closeModal,
  userId,
}: {
  closeModal?: () => void;
  userId: string;
}) => {
  const { mutateAsync, isPending } = useUpdateUserPassword();

  const formOptions = useForm({
    values: {
      password: "",
    },
  });

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = formOptions;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await mutateAsync({
        userId: userId,
        password: data?.password,
      });
      toast.success("User password has been updated successfully");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      closeModal?.();
    }
  });
  return (
    <>
      <div className="flex gap-4 mt-6">
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <LabeledPasswordInput
              {...field}
              placeholder="Enter new password"
              error={errors?.password?.message}
              errorClassName="text-text-red"
              label="New password"
              labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
              containerClassName="relative"
              inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
            />
          )}
          rules={{
            validate: {
              value: (value) => {
                if (
                  value?.length < 8 ||
                  !containsUppercase(value) ||
                  !containsNumber(value)
                ) {
                  return `<div><p>The password must meet following requirements:</p>
                            ${
                              value?.length < 8
                                ? `<div className="flex flex-row items-center mt-2">
                            <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                         <p>Minimum 8 characters</p></div>`
                                : ""
                            }
                         ${
                           !containsNumber(value)
                             ? `<div className="flex flex-row items-center mt-2">
                            <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                         <p>At least one number</p></div>`
                             : ""
                         }
                         ${
                           !containsUppercase(value)
                             ? `<div className="flex flex-row items-center mt-2">
                            <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                         <p>One uppercase letter</p></div>`
                             : ""
                         }
                         </div>`;
                }
                return true;
              },
            },
          }}
        />
      </div>

      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px] !h-[39px]"
        >
          Back
        </Button>
        <Button
          leftIcon={
            <Oval
              visible={isPending}
              height="20"
              width="20"
              color="#000000"
              secondaryColor="#ffffff"
            />
          }
          onClick={onSubmit}
          disabled={!isDirty || isPending}
          size="md"
          className="!text-base w-full !rounded-[8px] !h-[39px]"
        >
          Update
        </Button>
      </div>
    </>
  );
};

export default ChangePassword;
