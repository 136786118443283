import { StoreApi, UseBoundStore } from 'zustand';

type SubSet<T, K extends keyof T> = {
  [P in K]: T[P];
};

export const constructStore = <T extends Record<string, unknown>>(
  state: UseBoundStore<StoreApi<T>>,
) => {
  const useStore = <K extends Array<keyof T>>(keys: K): SubSet<T, (typeof keys)[number]> => {
    const store = state();
    return keys.reduce(
      (acc, key) => ({ ...acc, [key]: store[key] }),
      {} as SubSet<T, (typeof keys)[number]>,
    );
  };

  useStore.setState = state.setState;
  useStore.getState = state.getState;
  useStore.subscribe = state.subscribe;

  return useStore;
};
