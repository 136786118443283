import { useState } from "react";
import { useForm } from "react-hook-form";
import { AuthServices } from "../../../../services/apis/Auth";
import { useNavigate } from "react-router";

const useForgotPasswordHook = () => {
  const navigate = useNavigate();
  const [activeTab, setSetActiveTab] = useState<number>(1);

  const formOptions = useForm({
    defaultValues: {
      password: "",
      email: "",
      code: "",
    },
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    setError,
  } = formOptions;

  const email = getValues("email");

  const onSubmit = handleSubmit(async (data) => {
    try {
      if(data.email.length === 0){
        setError("email", {
          type: "manual",
          message: "<p>Email is required</p>",
        });
      } else if (activeTab === 1 && data.email.length > 0 && isValid) {
        await AuthServices.forgotPassword({
          email: data.email,
        });
        setSetActiveTab(2);
      } else if (activeTab === 2) {
        navigate("/login");
      }
    } catch (error: any) {
      setError("email", {
        type: "manual",
        message: "<p>Email is invalid.</p>",
      });
    }
  });
  return {
    email,
    control,
    errors,
    activeTab,
    onSubmit,
    navigate,
  };
};

export default useForgotPasswordHook;
