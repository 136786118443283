import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as NewUser } from "../../../../assets/icons/new-user.svg";
import { ReactComponent as Close } from "../../../../assets/icons/close-icon.svg";
import { ReactComponent as Plus } from "../../../../assets/icons/plus-icon.svg";
import LabeledInput from "../../../../components/LabeledInput/LabeledInput";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { emailValidation } from "../../../../utils/helpers";
import { useInviteUsers } from "../../../../queries/adminQueries/adminQueries";
import { toast } from "react-toastify";

export const InviteUsersModal = ({
  leagueId,
}: {
  leagueId?: string | undefined;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <InviteUsers leagueId={leagueId} closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "Invite Users",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        leftIcon={<NewUser />}
        mode={leagueId ? "primary" : "secondary"}
        onClick={openModal}
        className="rounded-[8px] w-full md:w-[150px] px-4 text-nowrap !h-10 xl:auto"
      >
        Invite Users
      </Button>
    </>
  );
};

const InviteUsers = ({
  closeModal,
  leagueId,
}: {
  closeModal?: () => void;
  leagueId?: string | undefined;
}) => {
  const { mutateAsync } = useInviteUsers();
  const [showInput, setShowInput] = useState(1);
  const formOptions = useForm({
    defaultValues: {
      email: "",
      email2: "",
      email3: "",
    },
  });

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
  } = formOptions;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await mutateAsync({
        emails: [data.email, data.email2, data.email3],
        text: leagueId
          ? `You have been invited to join the league: ${window?.location?.origin}/leagues/league/${leagueId}`
          : `You have been invited to join the CutThroat | Fantasy Sports - ${window?.location?.origin}`,
      });
      toast.success("User has been invited successfully");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      closeModal?.();
    }
  });
  return (
    <>
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <div className="relative">
            <LabeledInput
              {...field}
              placeholder="Enter the email of the user you want to invite..."
              error={errors?.email?.message}
              errorClassName="text-text-red"
              label="Email"
              labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
              containerClassName="relative mt-[28px] relative"
              inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
            />
            {getValues("email") && (
              <Close
                className="absolute right-3 top-5 cursor-pointer"
                onClick={() => setValue("email", "")}
              />
            )}
          </div>
        )}
        rules={{
          pattern: {
            value: emailValidation,
            message:
              "<p>The email address did not pass format validation, enter another address</p>",
          },
        }}
      />
      {showInput > 1 && (
        <Controller
          control={control}
          name="email2"
          render={({ field }) => (
            <div className="relative">
              <LabeledInput
                {...field}
                placeholder="Enter the email of the user you want to invite..."
                error={errors?.email2?.message}
                errorClassName="text-text-red"
                label="Email"
                labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                containerClassName="relative mt-4 relative"
                inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
              />
              {getValues("email2") && (
                <Close
                  className="absolute right-3 top-5 cursor-pointer"
                  onClick={() => setValue("email2", "")}
                />
              )}
            </div>
          )}
          rules={{
            pattern: {
              value: emailValidation,
              message:
                "<p>The email address did not pass format validation, enter another address</p>",
            },
          }}
        />
      )}
      {showInput === 3 && (
        <Controller
          control={control}
          name="email3"
          render={({ field }) => (
            <div className="relative">
              <LabeledInput
                {...field}
                placeholder="Enter the email of the user you want to invite..."
                error={errors?.email3?.message}
                errorClassName="text-text-red"
                label="Email"
                labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                containerClassName="relative mt-4 relative"
                inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
              />
              {getValues("email3") && (
                <Close
                  className="absolute right-3 top-5 cursor-pointer"
                  onClick={() => setValue("email3", "")}
                />
              )}
            </div>
          )}
          rules={{
            pattern: {
              value: emailValidation,
              message:
                "<p>The email address did not pass format validation, enter another address</p>",
            },
          }}
        />
      )}
      {showInput !== 3 && (
        <Button
          mode="secondary"
          className="mt-4 rounded-[8px] w-full"
          leftIcon={<Plus />}
          onClick={() => showInput !== 3 && setShowInput(showInput + 1)}
        >
          Add Another User
        </Button>
      )}
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Invite
        </Button>
      </div>
    </>
  );
};

export default InviteUsers;
