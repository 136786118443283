import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AuthServices } from "../../../../services/apis/Auth";
import { useNavigate } from "react-router";

const useResetPasswordHook = (token: string) => {
  const navigate = useNavigate();

  const formOptions = useForm({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
  } = formOptions;

  const newPassword = getValues("newPassword");

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (isValid) {
        await AuthServices.resetPassword({
          newPassword: data.newPassword,
          token: token,
        });
      }
      navigate("/login");
      toast.success("Password has been reset successfully");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  });
  return {
    newPassword,
    control,
    errors,
    onSubmit,
    navigate,
  };
};

export default useResetPasswordHook;
