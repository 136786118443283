import { Switch as SwitchHeadless } from "@headlessui/react";
import clsx from "clsx";
import { SwitchPropsType } from "./type";

export const Switch = ({
  onChange,
  checked,
  containerClassName = "",
  children = "",
  ...restProps
}: SwitchPropsType) => {
  return (
    <label
      htmlFor={restProps?.id}
      className={`selector-container ${containerClassName ?? ""}`}
    >
      <SwitchHeadless
        checked={checked}
        onChange={onChange}
        className={clsx(
          checked
            ? "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
            : "bg-text-greyDark",
          "relative inline-flex p-[2px] h-[24px] w-[40px] shrink-0 cursor-pointer rounded-[20px] transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75"
        )}
        {...restProps}
      >
        <span
          aria-hidden="true"
          className={clsx(
            checked ? "translate-x-[16px]" : "translate-x-0",
            "pointer-events-none top-1/2 -translate-y-1/2 absolute  inline-block h-[20px] w-[20px] transform rounded-[20px] bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
          )}
        />
      </SwitchHeadless>
      {children}
    </label>
  );
};
