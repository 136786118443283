import clsx from "clsx";

import { ButtonModeType, ButtonPropsType } from "./type";

const sizeClasses = {
  lg: "!h-[52px]",
  md: "!h-[44px]",
  sm: "!h-[25px]",
};
const colorClasses: Record<ButtonModeType, string> = {
  primary:
    "bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2 text-text-white hover:from-red-light-1 hover:to-red-light-2 active:bg-red-bgSolid disabled:bg-gradient-to-br disabled:from-red-bgDark disabled:to-red-bgDark",
  secondary:
    "bg-button-bgSolid text-text-white hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2 active:bg-card-bgGreyDark disabled:bg-card-bgGreyDark",
};

export const Button = (props: ButtonPropsType) => {
  const {
    children,
    mode = "primary",
    size = "md",
    className = "",
    disabled = false,
    type = "button",
    leftIcon,
    leftIconSrc,
    rightIcon,
    rightIconSrc,
    ...restProps
  } = props;
  return (
    <button
      type={type}
      disabled={disabled}
      className={clsx([
        // basic
        "flex gap-[8px] items-center justify-center w-[200px] h-[50px] disabled:cursor-not-allowed disabled:text-text-greyDark font-gilroyBold text-sm rounded-[25px]",
        // height
        sizeClasses[size],
        // color
        colorClasses[mode],
        className,
      ])}
      {...restProps}
    >
      <>
        {leftIconSrc ? (
          <img src={leftIconSrc} alt="icon" className={clsx("w-6 h-6")} />
        ) : leftIcon ? (
          leftIcon
        ) : null}
        {children}
        {rightIconSrc ? (
          <img src={rightIconSrc} alt="icon" className={clsx("w-6 h-6")} />
        ) : rightIcon ? (
          rightIcon
        ) : null}
      </>
    </button>
  );
};
