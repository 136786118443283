import axios, { InternalAxiosRequestConfig } from "axios";
import useAuthStore from "../stores/auth/auth";
import { publicEndPoints } from "./axiosConfiguration";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = 180000;

// Add a request interceptor
axios.interceptors.request.use(
  (
    config: InternalAxiosRequestConfig<any>
  ): InternalAxiosRequestConfig<any> => {
    const { loginData, userData } = useAuthStore.getState();
    const isAdmin = userData?.role === "admin";
    const accessToken = loginData?.access_token;
    // Check if the end point is public (no need to header access_token)
    if (
      config.url &&
      config.headers &&
      publicEndPoints.find((endPoint) => `${config.url}`.includes(endPoint))
    ) {
      delete config.headers?.access_token;
    } else {
      if (config.headers && accessToken && !isAdmin) {
        config.headers.access_token = accessToken;
      }
    }
    return config;
  },
  (error) => {
    throw error;
  }
);
