import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { MESSAGES_QUERY_KEY } from "./messagesQueriesKeys";
import { MessagesServices } from "../../services/apis/Messages/MessagesServices";

export const useFetchAllMessages = (id: string) => {
  const queryResult = useQuery<any>({
    queryKey: [MESSAGES_QUERY_KEY.FETCH_MESSAGES, id],
    queryFn: () => MessagesServices.getMessages(id),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
