import TableCardsListing from "../../../../components/TableCardsListing/TableCardsListing.tsx";
import useUserLeaguesHook from "./hooks/useUserLeagues.tsx";
import Filters from "../../../../components/Filters/Filters.tsx";
import { Button } from "../../../../components/index.ts";
import MobileFilters from "../../../../components/MobileFilters/MobileFilters.tsx";
import MobileLeagues from "../../../../components/MobileLeagues/MobileLeagues.tsx";
import {Pagination} from "../../../../components/Pagination/Pagination.tsx";

const UserLeagues = () => {
  const {
    columns,
    isFetching,
    filters,
    leagues,
    onShowJustOpen,
    onChangeFilter,
    onShowMyLeagues,
    filter,
    isShowMyLeagues,
    showOpenLeagues,
  } = useUserLeaguesHook();

  return (
    <div className="px-4 pb-6 md:px-10">
      <div className="w-full flex justify-start gap-4 mt-7">
        <h2 className="font-absoluteEmpire text-[30px] leading-none">
          EXPLORE {isShowMyLeagues ? "MY" : "ALL"} LEAGUES
        </h2>
      </div>
      <div className="mt-6 hidden lg:block">
        <div className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
          <div className="flex flex-row justify-between items-center gap-[32px]">
            <p className="font-gilroyBold">
              {isShowMyLeagues ? "My Leagues" : "All Leagues"}
            </p>
            {!isShowMyLeagues
              ? filters?.map((item) => (
                  <Filters
                    key={item?.value}
                    name={item?.name}
                    value={item?.value}
                    filters={item?.filters}
                  />
                ))
              : null}
          </div>
          <div className="flex gap-3">
            <Button
              mode="primary"
              className="!max-h-[40px] rounded-[8px] max-w-[120px]"
              onClick={onShowJustOpen}
            >
              {!showOpenLeagues ? "Show Just Open" : "View All Leagues"}
            </Button>
            <Button
              mode="secondary"
              className="!max-h-[40px] rounded-[8px] max-w-[120px]"
              onClick={onShowMyLeagues}
            >
              {!isShowMyLeagues ? "Show My" : "Show All"} Leagues
            </Button>
          </div>
        </div>
        <TableCardsListing
          columns={columns}
          dataSource={leagues}
          loading={isFetching}
          paginationData={{
            page: filter?.page as number,
            limit: isShowMyLeagues
              ? leagues?.length + 1
              : (filter?.limit as number),
            count: leagues?.length ?? 0,
            onChange: (params) => {
              onChangeFilter(params);
            },
          }}
        />
      </div>
      <div className="flex gap-4 flex-col lg:hidden">
        <p className="font-gilroyBold mt-8 mb-3">
          {isShowMyLeagues ? "My Leagues" : "All Leagues"}
        </p>

        <MobileFilters
          page="leagues"
          onShowJustOpen={onShowJustOpen}
          onShowMyLeagues={onShowMyLeagues}
          filters={filters}
        />
        <MobileLeagues leagues={leagues} />
          <Pagination
              onChange={(params) => {
                  onChangeFilter(params);
              }}
              page={filter?.page as number}
              limit={filter?.limit as number}
              count={leagues?.length ?? 0}
          />
      </div>
    </div>
  );
};

export default UserLeagues;
