import { useState } from "react";
import {
  useAddBalance,
  useAddCredits,
  useUserById,
} from "../../../../queries/adminQueries/adminQueries";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const useUserProfileHook = () => {
  const { id } = useParams();
  const { data, isFetching } = useUserById(id!);
  const { mutateAsync: addCredits, isPending: isPendingCredits } =
    useAddCredits();
  const { mutateAsync: addBalance, isPending: isPendingBalance } =
    useAddBalance();
  const steps = ["5.00", "10.00", "20.00", "30.00", "40.00", "50.00"];
  const [step, setStep] = useState(steps[0]);
  const [credit, setCredit] = useState<number>(5);

  const winRate =
    ((data?.userStats?.leaguesWonFirstPlace +
      data?.userStats?.leaguesWonSecondPlace) /
      data?.userStats?.numberOfLeaguesEntered) *
    100;

  const accountBalance =
    Number(data?.balance?.amount) + Number(data?.balance?.credit_amount);

  const filters = [
    {
      name: `$${step}`,
      value: "step",
      filters: ["5.00", "10.00", "20.00", "30.00", "40.00", "50.00"],
    },
  ];

  const onAddCredits = async (userId: string, closeModal?: () => void) => {
    try {
      await addCredits({
        userId: userId,
        amount: credit,
      });
      toast.success("Credits added successfully");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      closeModal?.();
    }
  };

  const onAddBalance = async (userId: string, closeModal?: () => void) => {
    try {
      await addBalance({
        userId: userId,
        amount: credit,
      });
      toast.success("Balance added successfully");
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    } finally {
      closeModal?.();
    }
  };

  return {
    data,
    isFetching,
    winRate,
    accountBalance,
    step,
    setStep,
    steps,
    filters,
    onAddCredits,
    credit,
    setCredit,
    onAddBalance,
    isPendingCredits,
    isPendingBalance,
  };
};

export default useUserProfileHook;
