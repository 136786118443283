import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Close } from "../../../../assets/icons/close-icon.svg";
import useLeagueHook from "../hooks/useLeague";

export const CloseRegistrationModal = () => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <CloseRegistration closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        leftIcon={<Close />}
        mode="secondary"
        onClick={openModal}
        className="rounded-[8px] w-full px-4 text-nowrap !h-10 xl:auto"
      >
        Close Registration
      </Button>
    </>
  );
};

const CloseRegistration = ({ closeModal }: { closeModal?: () => void }) => {
  const { closeRegistration } = useLeagueHook();
  return (
    <>
      <p className="text-[24px] font-gilroySemibold mb-2">
        Are you sure you want to close the registration for the league?
      </p>
      <p className="font-gilroyMedium text-text-subGrey">
        Once closed, no new teams will be able to join. This action can’t be
        undone
      </p>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeRegistration}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Close Registration
        </Button>
        <Button
          onClick={closeModal}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default CloseRegistration;
