import { Controller } from "react-hook-form";
import demo_logo from "../../../../assets/images/demoLogo.png";
import { ReactComponent as Pencil } from "../../../../assets/icons/pencil.svg";
import LabeledInput from "../../../../components/LabeledInput/LabeledInput";
import {
  containsNumber,
  containsUppercase,
  emailValidation,
  phoneValidation,
} from "../../../../utils/helpers";
import LabeledPasswordInput from "../../../../components/LabeledPasswordInput/LabeledPasswordInput";
import { Switch } from "../../../../components/Switch/Switch";
import useProfileHook from "./hooks/useProfile";

const Profile = () => {
  const {
    isTwoAuth,
    setIsTwoAuth,
    control,
    errors,
    controlPass,
    err,
    getValues,
    checkEmail,
    updatePersonalInfo,
    updatePassword,
    userData,
    updateTwoStepAuth,
    isEnableNotifications,
    setIsEnableNotifications,
    updateNotifications,
    handleUserPhoto,
    phoneErrors,
    controlPhone,
  } = useProfileHook();

  return (
    <>
      <div className="px-4 pb-6 md:px-10">
        <div className="mt-6 flex w-full gap-6 flex-col md:flex-row">
          <div className="min-w-unset h-[324px] bg-card-bgGreyDark rounded-[16px] p-6 flex flex-col justify-center items-center md:min-w-[364px]">
            <div className="relative">
              <img
                src={userData?.profileUrl || demo_logo}
                alt="CutThroat"
                className="w-[214px] h-[214px] rounded-[50%]"
              />
              <div className="absolute bottom-4 right-4 cursor-pointer">
                <label className="cursor-pointer">
                  <input
                    type="file"
                    onChange={handleUserPhoto}
                    accept="image/*"
                  />
                  <Pencil />
                </label>
              </div>
            </div>
            <p className="text-text-subGrey font-gilroyMedium mt-4">
              We support PNGs, JPGs and GIFs under 10MB
            </p>
          </div>
          <div className="min-h-[324px] w-full bg-card-bgGreyDark rounded-[16px]">
            <div className="p-6 bg-filterContainer rounded-t-[16px] flex justify-between items-center">
              <p className="text-base font-gilroyBold mr-10">
                Personal Information
              </p>
              <p
                onClick={updatePersonalInfo}
                className="text-xs font-gilroyBold text-text-red ml-10 cursor-pointer"
              >
                Save changes
              </p>
            </div>
            <div className="p-6 gap-3 flex flex-col justify-center items-center h-[75%]">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Enter name"
                    error={errors?.name?.message}
                    errorClassName="text-text-red"
                    label="Name"
                    labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                    containerClassName="relative"
                    inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                  />
                )}
              />
              <Controller
                control={control}
                name="surname"
                render={({ field }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Enter surname"
                    error={errors?.surname?.message}
                    errorClassName="text-text-red"
                    label="Surname"
                    labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                    containerClassName="relative"
                    inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                  />
                )}
              />
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Enter your email"
                    error={errors?.email?.message}
                    errorClassName="text-text-red"
                    label="Email"
                    labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                    containerClassName="relative"
                    inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                  />
                )}
                rules={{
                  pattern: {
                    value: emailValidation,
                    message:
                      "<p>The email address did not pass format validation, enter another address</p>",
                  },
                  validate: {
                    value: async (value) => {
                      const check = await checkEmail({ email: value });
                      if (!check && value !== userData?.email) {
                        return "<p>This email address is already in use, please enter a different one</p>";
                      }
                      return true;
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="min-h-[324px] w-full bg-card-bgGreyDark rounded-[16px]">
            <div className="p-6 bg-filterContainer rounded-t-[16px] flex justify-between items-center">
              <p className="text-base font-gilroyBold mr-10">Password Reset</p>
              <p
                onClick={updatePassword}
                className="text-xs font-gilroyBold text-text-red ml-10 cursor-pointer"
              >
                Update
              </p>
            </div>
            <div className="p-6 gap-3 flex flex-col justify-center items-center h-[75%]">
              <Controller
                control={controlPass}
                name="oldPassword"
                render={({ field }) => (
                  <LabeledPasswordInput
                    {...field}
                    placeholder="Enter your current password"
                    error={err?.oldPassword?.message}
                    errorClassName="text-text-red"
                    label="Current Password"
                    labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                    containerClassName="relative"
                    required
                    inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "<p>Current password is required</p>",
                  },
                }}
              />
              <Controller
                control={controlPass}
                name="newPassword"
                render={({ field }) => (
                  <LabeledPasswordInput
                    {...field}
                    placeholder="Enter your new password"
                    error={err?.newPassword?.message}
                    errorClassName="text-text-red"
                    label="Create New Password"
                    labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                    containerClassName="relative"
                    inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                  />
                )}
                rules={{
                  validate: {
                    value: (value) => {
                      if (
                        value?.length < 8 ||
                        !containsUppercase(value) ||
                        !containsNumber(value)
                      ) {
                        return `<div><p>The password must meet following requirements:</p>
                            ${
                              value?.length < 8
                                ? `<div className="flex flex-row items-center mt-2">
                            <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                         <p>Minimum 8 characters</p></div>`
                                : ""
                            }
                         ${
                           !containsNumber(value)
                             ? `<div className="flex flex-row items-center mt-2">
                            <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                         <p>At least one number</p></div>`
                             : ""
                         }
                         ${
                           !containsUppercase(value)
                             ? `<div className="flex flex-row items-center mt-2">
                            <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                         <p>One uppercase letter</p></div>`
                             : ""
                         }
                         </div>`;
                      }
                      return true;
                    },
                  },
                }}
              />
              <Controller
                control={controlPass}
                name="confirmNewPassword"
                render={({ field }) => (
                  <LabeledPasswordInput
                    {...field}
                    placeholder="Confirm your password"
                    error={err?.confirmNewPassword?.message}
                    errorClassName="text-text-red"
                    label="Confirm password"
                    labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                    containerClassName="relative"
                    inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                  />
                )}
                rules={{
                  validate: {
                    value: (value) => {
                      const newPassword = getValues("newPassword");
                      if (newPassword !== value) {
                        return "<p>Passwords do not match</p>";
                      }
                      return true;
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="h-[1px] w-full bg-modal-modalStroke my-[40px]" />
        <div className="mt-6 flex w-full gap-6 flex-col md:flex-row">
          <div className="h-[324px] w-full bg-card-bgGreyDark rounded-[16px]">
            <div className="p-6 bg-filterContainer rounded-t-[16px] flex justify-between items-center">
              <p className="text-base font-gilroyBold mr-10">
                Notification Settings
              </p>
              <p
                onClick={updateNotifications}
                className="text-xs font-gilroyBold text-text-red ml-10 cursor-pointer"
              >
                Save changes
              </p>
            </div>
            <div className="p-6">
              <div className="flex justify-between items-center">
                <p className="text-base font-gilroyBold">
                  In-App Notifications
                </p>
                <Switch
                  onChange={setIsEnableNotifications}
                  checked={isEnableNotifications}
                />
              </div>
            </div>
          </div>
          <div className="h-[324px] w-full bg-card-bgGreyDark rounded-[16px]">
            <div className="p-6 bg-filterContainer rounded-t-[16px] flex justify-between items-center">
              <p className="text-base font-gilroyBold mr-10">
                Two-factor Authentication Settings
              </p>
              <p
                onClick={updateTwoStepAuth}
                className="text-xs font-gilroyBold text-text-red ml-10 cursor-pointer"
              >
                Save changes
              </p>
            </div>
            <div className="p-6">
              <div className="flex justify-between items-center">
                <p className="text-base font-gilroyBold">
                  Two-factor Authentication
                </p>
                <Switch onChange={setIsTwoAuth} checked={isTwoAuth} />
              </div>
              <Controller
                control={controlPhone}
                name="phone"
                render={({ field }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Enter phone number"
                    error={phoneErrors?.phone?.message}
                    errorClassName="text-text-red"
                    label="Phone Number"
                    labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                    containerClassName="relative mt-4"
                    inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                  />
                )}
                rules={{
                  required: {
                    value: false,
                    message: "<p>Mobile field is required</p>",
                  },
                  validate: {
                    value: (value) => {
                      if (value?.length && !phoneValidation(value)) {
                        return "<p>Invalid phone number</p>";
                      }
                      return true;
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
