import useAuthStore from "../../../stores/auth/auth";
import axios from "axios";

export const WaiverServices = {
  getPlayersOnWaiver: async (teamId: string) => {
    const endPoint = `api/game/waiver/player/${teamId}`;
    try {
      const players = await axios.get(endPoint);
      return players?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  getMyBids: async (teamId: string) => {
    const endPoint = `api/game/bid/${teamId}`;
    try {
      const bids = await axios.get(endPoint);
      return bids?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  makeBid: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `api/game/bid/place/${payload?.teamId}`;
    try {
      delete payload?.teamId;
      const bid = await axios.post(endPoint, payload, config);
      return bid?.data;
    } catch (error) {
      throw error;
    }
  },
  editBid: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `api/game/bid/edit`;
    try {
      const editBid = await axios.patch(endPoint, payload, config);
      return editBid?.data;
    } catch (error) {
      throw error;
    }
  },
  deleteBid: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `api/game/bid/delete`;
    try {
      const deleteBid = await axios.delete(endPoint, {
        data: payload,
        headers: config.headers,
      });
      return deleteBid?.data;
    } catch (error) {
      throw error;
    }
  },
  increasePriority: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `api/game/bid/priorityIncrease`;
    try {
      const increasePriority = await axios.post(endPoint, payload, config);
      return increasePriority?.data;
    } catch (error) {
      throw error;
    }
  },
  priorityDecrease: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `api/game/bid/priorityDecrease`;
    try {
      const priorityDecrease = await axios.post(endPoint, payload, config);
      return priorityDecrease?.data;
    } catch (error) {
      throw error;
    }
  },
  getAllWaiverPlayers: async (leagueId: string) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `api/game/player/all/waiver/${leagueId}`;
    try {
      const waiverPlayers = await axios.get(endPoint, config);
      return waiverPlayers?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getPlayerBidsHistory: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `api/game/bid/player`;
    try {
      const bidsHistory = await axios.post(endPoint, payload, config);
      return bidsHistory?.data?.data;
    } catch (error) {
      throw error;
    }
  },
};
