import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react/jsx-runtime";
import clsx from "clsx";
import { ReactComponent as Down } from "../../../../assets/icons/arrow-down.svg";

const SingleFilter = ({
  activeFilter,
  filters,
  setFilter,
}: {
  activeFilter: string;
  filters: any;
  setFilter: (data: any) => void;
}) => {
  return (
    <Menu as="div" className="relative">
      {() => (
        <>
          <Menu.Button
            aria-pressed={false}
            aria-hidden
            className="w-max inline-flex items-center relative"
          >
            <p className="mr-2 text-sm font-gilroyMedium text-text-white">
              {activeFilter}
            </p>
            <Down />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              as="div"
              className="bg-card-bgGreyDark rounded-[16px] left-[50%] translate-x-[-50%] absolute z-10 flex flex-col mt-4 shadow-2xl"
            >
              {filters?.length
                ? filters?.map((item: any, index: number) => (
                    <Menu.Item key={index}>
                      <button
                        className={clsx(
                          "px-6 py-3 hover:bg-card-bgGreyMedium cursor-pointer text-base flex items-center",
                          index === 0 && "rounded-t-[16px]",
                          filters?.length - 1 === index && "rounded-b-[16px]"
                        )}
                        onClick={() => {
                          setFilter(item);
                        }}
                      >
                        <span className="text-left whitespace-nowrap">
                          {item}
                        </span>
                      </button>
                    </Menu.Item>
                  ))
                : null}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
export default SingleFilter;
