import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react/jsx-runtime";
import { ReactComponent as Dots } from "../../../../assets/icons/dots-icon.svg";
import { ReactComponent as Delete } from "../../../../assets/icons/close-icon.svg";

const ButtonsMenu = ({ onRemove }: { onRemove: () => void }) => {
  return (
    <Menu as="div" className="relative">
      {() => (
        <>
          <Menu.Button
            aria-pressed={false}
            aria-hidden
            className="w-max inline-flex items-center relative h-[40px] ml-2 "
          >
            <Dots />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              as="div"
              className="bg-modal-inputBg rounded-[16px] left-[-40%] translate-x-[-50%] absolute z-10 flex flex-col mt-2 shadow-2xl"
            >
              <Menu.Item>
                <button
                  onClick={onRemove}
                  className="px-6 py-3 hover:bg-card-strokeGradient-1 cursor-pointer text-base flex items-center rounded-[16px]"
                >
                  <Delete />{" "}
                  <span className="text-left whitespace-nowrap ml-2">
                    Remove
                  </span>
                </button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default ButtonsMenu;
